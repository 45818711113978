import * as React from 'react';

type ARCBadgeType = {
    status: 'on' | 'standby' | 'off',
    label: string,
    type: 'status' | 'filter-number'
}

export const ARCBadge:React.FC<ARCBadgeType> = (props) => {

    return (
        <>
            {props.label &&
                <div className={`o-badge o-badge--${props.status}`} role={props.type}>
                    {props.label}
                </div>
            }
        </>
    )
}