import { Link } from '@material-ui/core';
import * as React from 'react';
import {deviceContextActions, deviceContextSelector} from "../deviceSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {useNavigate} from "react-router-dom";

type DeviceErrorProps = {
    err: string,
    linkError?: string,
    duplicateDeviceId: boolean
}

export const DeviceError:React.ComponentType<DeviceErrorProps> = ({err, linkError, duplicateDeviceId}) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const deviceContext = useAppSelector(deviceContextSelector);
    if (duplicateDeviceId) {
        let dev = deviceContext.existingDeviceDto?.deviceDto;
        if (dev) {
            return (
                <>
                    <Link target={'_self'} onClick={() => {
                        dispatch(deviceContextActions.resetSaveDevice());
                        navigate(`/devices/edit/locationId=${dev?.locationId}/deviceId=${dev?.deviceId}`)
                    }}>
                        {linkError}
                    </Link>
                    {err &&
                        <>{err}</>
                    }
                </>
            )
        }
    }
    return <>{err}</>;
    
}
