import * as React from "react";
import {appContextSelector} from "../app-context/appContextSlice";
import {useAppSelector} from "../../store/hooks";
import {useLocation} from "react-router-dom";
import {useAppViewContext} from "../app-context/AppViewContainer";
import NavigationMenu from "../ui-components/NavigationMenu";
import {
    KPI_DASHBOARD,
    KPI_DASHBOARD_2_6,
    KPI_DASHBOARD_7_PLUS,
    REPORT_OPTIONS,
    SCORECARD_DASHBOARD
} from "../../dtos/Report";
import { ReportOption } from "../reports/ReportTypes";
import {useCurrentUserContext} from "../app-context/CurrentUserContextContainer";
import {getLocalizedString} from "../../util/Localization";
import {isMDCDisabledBrand, SAMS_BRANDS} from "../../dtos/Brand";
import {ARCMenu} from "../ui-components/design-system/ARCMenu";

type SideBarProps = {
    links?: React.ReactNode | undefined,
    underlined?: boolean
};

export const SideBar:React.ComponentType<SideBarProps> = (props) => {

    const context = useAppSelector(appContextSelector);
    const appViewContext = useAppViewContext();
    const currentUserContext = useCurrentUserContext();
    const location = useLocation();
    const firstName = context.authorizationDto?.firstName;

    const reportsViewable = React.useMemo(() => {
        return true;
    }, []);

    const importViewable = React.useMemo(() => {
        return !currentUserContext.groups.isArcTechSupport && !currentUserContext.groups.isSSOHQUser;
    }, [currentUserContext.groups.isArcTechSupport, currentUserContext.groups.isSSOHQUser]);
    
    const isSams = React.useMemo(() => {
        if (SAMS_BRANDS.find(id => id === context.currentBrand?.brandId)) {
            return context.currentLocation?.serialIsDevice;
        }
        return false;
    }, [context.currentBrand?.brandId, context.currentLocation?.serialIsDevice])

    const REPORT_LIST = React.useMemo<ReportOption[]>(() => {
        let KPI_DASH: ReportOption;
        if(context.authorizationDto && context.currentBrand) {
            const brandLocationCount = context.authorizationDto.authorizedLocations.filter(l => l.brand.brandId === context.currentBrand?.brandId).length;
            const authorizedLocationCount = context.authorizationDto.authorizedLocations?.length;

            if(brandLocationCount === 1 || authorizedLocationCount === 1) {
                KPI_DASH = KPI_DASHBOARD
            } else if (brandLocationCount < 7 || authorizedLocationCount < 7) {
                KPI_DASH = KPI_DASHBOARD_2_6;
            } else if (brandLocationCount > 6 || authorizedLocationCount > 6) {
                KPI_DASH = KPI_DASHBOARD_7_PLUS;
            } else {
                KPI_DASH = KPI_DASHBOARD;
            }
        } else {
            KPI_DASH = KPI_DASHBOARD;
        }

        return [SCORECARD_DASHBOARD, KPI_DASH, ...REPORT_OPTIONS];
    }, [context.authorizationDto, context.currentBrand]);

    return (
            <nav className="o-site-nav">

                <div className="l-wrap l-container--lg">
                    <div className="o-site-nav__container u-display--flex u-justify-content--space-between u-align-items--center">
                    {firstName &&
                    <h1 className="o-heading--xs">
                        {getLocalizedString('navigation.welcome', 'Welcome, ') + firstName}
                    </h1>
                    }

                    {props.links ? props.links :
                        <section className="o-site-nav__list">
                            <div className="u-hide-before--m">
                                <div style={{fontSize: 'min(max(1.2vw, 0.5em), 1em)', display: 'flex'}}>

                                    {reportsViewable &&
                                    <NavigationMenu
                                        label={getLocalizedString('navigation.reports', 'Reports')}
                                        domain={'reports'}
                                        currentPage={location.pathname}
                                        navHomeOnClick={false}
                                        homePath={`/reports/view/locationId=${context.currentLocation?.locationId}`}
                                        options={REPORT_LIST.map(option => {
                                            return (
                                                {
                                                    name: getLocalizedString(option.displayName),
                                                    path: `/reports/view/locationId=${context.currentLocation?.locationId}/lookerId=${option.lookerId}`
                                                }
                                            );
                                        })}
                                    />
                                    }

                                    <NavigationMenu
                                        label={getLocalizedString('navigation.employees', 'Users')}
                                        domain={'employees'}
                                        currentPage={location.pathname}
                                        navHomeOnClick={true}
                                        restrictMenuToHomePath={true}
                                        homePath={`/employees/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`}
                                        options={
                                            [
                                                !context.currentBrand?.employeeAPIEnabled ? {name: getLocalizedString('navigation.importEmployees', 'Import Users'), path: '/employees/import', disabled: !importViewable} : undefined,
                                                {name: getLocalizedString('navigation.exportEmployees', 'Export Users'), path: '/employees/export', disabled: appViewContext.brandView},
                                                !isMDCDisabledBrand(context.currentBrand?.brandId) ? {name: getLocalizedString('navigation.devicePickupPermission', 'Device Permissions Groups'), path: `/employees/permissions/view/locationId=${context.currentLocation?.locationId}`, disabled: appViewContext.brandView} : undefined
                                            ]}
                                    />

                                    <NavigationMenu
                                        label={getLocalizedString('navigation.devices', 'Devices')}
                                        domain={'devices'}
                                        currentPage={location.pathname}
                                        navHomeOnClick={true}
                                        restrictMenuToHomePath={true}
                                        homePath={`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`}
                                        options={
                                            [
                                                (importViewable) ? {name: getLocalizedString('navigation.importDevices', 'Import Devices'), path: '/devices/import', disabled: !importViewable || isSams} : undefined,
                                                {name: getLocalizedString('navigation.exportDevices', 'Export Devices'), path: '/devices/export', disabled: appViewContext.brandView}
                                            ]}
                                    />

                                    <NavigationMenu
                                        label={getLocalizedString('navigation.faq', 'Help')}
                                        domain={'faq'}
                                        navHomeOnClick={true}
                                        restrictMenuToHomePath={true}
                                        currentPage={location.pathname}
                                        homePath={`/faq/view/locationId=${context.currentLocation?.locationId}`}
                                    />

                                </div>
                            </div>

                            <div className="u-hide-after--m">
                                <ARCMenu
                                    sections={[
                                        {
                                            title: {
                                                title: getLocalizedString('navigation.reports', 'Reports'),
                                                url: `/reports/view/locationId=${context.currentLocation?.locationId}`,
                                                domain: 'reports',
                                            },
                                            navLinks: REPORT_LIST.map((option, idx) => {
                                                return (
                                                    {title: getLocalizedString(option.displayName), url: `/reports/view/locationId=${context.currentLocation?.locationId}/lookerId=${option.lookerId}`, domain: 'none'}
                                                )
                                            }),
                                            hidden: !reportsViewable
                                        },
                                        {
                                            title: {
                                                title: getLocalizedString('navigation.employees', 'Users'),
                                                url: `/employees/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`,
                                                domain: 'employees'
                                            },
                                            navLinks: [
                                                {
                                                    title: getLocalizedString('navigation.importEmployees', 'Import Users'),
                                                    url: '/employees/import',
                                                    domain: 'employees/import',
                                                    hidden: appViewContext.brandView || context.currentBrand?.employeeAPIEnabled
                                                },
                                                {
                                                    title: getLocalizedString('navigation.exportEmployees', 'Export Users'),
                                                    url: '/employees/export',
                                                    domain: 'employees/export',
                                                    hidden: appViewContext.brandView
                                                },
                                                {
                                                    title: getLocalizedString('navigation.devicePickupPermission', 'Device Permissions Groups'),
                                                    url: `/employees/permissions/view/locationId=${context.currentLocation?.locationId}`,
                                                    domain: 'employees/permissions',
                                                    hidden: appViewContext.brandView || isMDCDisabledBrand(context.currentBrand?.brandId)
                                                }
                                            ]
                                        },
                                        {
                                            title: {
                                                title: getLocalizedString('navigation.devices', 'Devices'),
                                                url: `/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`,
                                                domain: 'devices'
                                            },
                                            navLinks: [
                                                {
                                                    title: getLocalizedString('navigation.importDevices', 'Import Devices'),
                                                    url: '/devices/import',
                                                    domain: 'devices/import',
                                                    hidden: appViewContext.brandView
                                                },
                                                {
                                                    title: getLocalizedString('navigation.exportDevices', 'Export Devices'),
                                                    url: '/devices/export',
                                                    domain: 'devices/export',
                                                    hidden: appViewContext.brandView
                                                }
                                            ]
                                        },
                                        {
                                            title: {
                                                title: getLocalizedString('navigation.faq', 'Help'),
                                                url: `/faq/view/locationId=${context.currentLocation?.locationId}`,
                                                domain: 'faq'
                                            },
                                            navLinks: []
                                        }
                                        ]
                                    }
                                />
                            </div>
                        </section>
                    }
                    </div>
            </div>
        </nav>
    )
}