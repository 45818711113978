import {Search} from '@material-ui/icons';
import * as React from 'react';
import { ChangeEvent } from 'react';
import {getLocalizedString} from "../../../util/Localization";
import {ARCIcon} from "./ARCIcon";

type ARCTextFilterInputProps = {
    id: string,
    label?: string,
    placeholder?: string,
    disabled?: boolean;
    value: string | undefined,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    onClear: () => void,
    onSearch: () => void,
    className?: string,
    style?: React.CSSProperties,
    showXForClear?: boolean
}

export const ARCTextFilterInput : React.FC<ARCTextFilterInputProps> = (props) => {

    const showClear = React.useMemo(() => {
        return props.value && !props.showXForClear && !props.disabled;
    }, [props.value, props.showXForClear, props.disabled])
    
    const showX = React.useMemo(() => {
        return props.value && props.showXForClear && !props.disabled;
    }, [props.value, props.showXForClear, props.disabled])

    return (
        <div className={`o-textinput--filter__container ${props.className ? props.className : ''}`} style={props.style}>
            <div className={'u-display--flex u-align-items--center u-justify-content--space-between'}>
                <label className={`o-textinput--filter__label`}>
                    {props.label}
                </label>

                {showClear &&
                <div className={'o-textinput--filter__clear'} onClick={props.onClear}>
                    {getLocalizedString('clear', 'Clear')}
                </div>
                }
            </div>

            <div style={{position: 'relative'}}>
                <input type={'text'}
                       disabled={props.disabled}
                       placeholder={props.placeholder ?? ''}
                       className={`o-textinput--filter`}
                       value={props.value}
                       id={props.id}
                       onChange={(event) => {
                           props.onChange(event);
                       }}
                       onKeyDown={(event) => {
                           if (event.key === 'Enter') {
                               props.onSearch();
                           }
                       }}
                />
                <div className={'o-textinput--filter__icon-container'}>
                    {showX &&
                        <ARCIcon icon={'close'} size={'sm'} className={'u-icon-close'} onClick={props.onClear}/>
                    }
                    <Search className={'u-icon-search' + (props.disabled ? ' disabled' : '')} onClick={() => props.onSearch()}/>
                </div>
            </div>


        </div>
    )
}