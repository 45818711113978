import * as React from 'react';
import ARCLOGO_WHITE from "../../assets/img/ARC_Logo_White.png";
import {HELP_EMAIL, HELP_PHONE} from "../../constants/StringConstants";
import {getLocalizedString} from "../../util/Localization";

export const UnauthorizedSSO = () => {

    return (
        <div className={'sso-unauthorized-container'}>
            <div className={'sso-unauthorized-body'}>
                <img className={'unauth-arc-logo'}
                     alt="ARC Logo"
                     src={ARCLOGO_WHITE}
                />

                <div className={'sso-unauthorized-title'}>
                    {getLocalizedString('loginsso.unauthorized', 'Access Denied')}
                </div>

                <div className={'sso-unauthorized-subtitle'}>
                    {getLocalizedString('loginsso.contact', 'If this is an error, contact')}

                    <b style={{marginLeft: '5px'}}>
                        {getLocalizedString('support.email', HELP_EMAIL)}
                    </b>
                </div>

                <div className={'sso-unauthorized-footer'}>
                    {getLocalizedString('support', 'ARC Support') + ' | '+ getLocalizedString('support.email', HELP_EMAIL) + ' | ' + getLocalizedString("support.phone", HELP_PHONE)}
                </div>

            </div>
        </div>
    )
}