export function compareTo(n1: number | undefined, n2: number | undefined): number {
    if (n1 === undefined && n2 === undefined) {
        return 0;
    } else if (n1 === undefined) {
        return -1;
    } else if (n2 === undefined) {
        return 1;
    } else if (n1 < n2) {
        return -1;
    } else if (n1 > n2) {
        return 1;
    } else {
        return 0;
    }
}