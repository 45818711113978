import { LookerEmbedSDK } from "@looker/embed-sdk"
import API, {env} from "./dtos/API_DOMAINS";
import {useAppSelector} from "./store/hooks";
import {appContextSelector} from "./features/app-context/appContextSlice";
import {reportContextSelector} from "./features/reports/reportSlice";

export const EmbedSDKInit = () => {
    const token = localStorage.getItem('token');
    const context = useAppSelector(appContextSelector);
    const reportContext = useAppSelector(reportContextSelector);

    LookerEmbedSDK.init(
        'looker.arc-kiosk.com',
        {
            // The location of the service which will privately create a signed URL
            url: API.API_DOMAIN + '/clientportal/looker/getembedurl',
            headers: [{'name': 'Authorization', 'value': `Bearer ${token}`}],
            params: [
                {'name': 'brandId', 'value': context.currentBrand?.brandId + '' ?? '0'},
                {'name': 'locationId', 'value': (context.currentLocation?.locationId) + '' ?? '0'},
                {'name': 'deviceId', 'value': reportContext.deviceId?.toString() ?? ''},
                {'name': 'employeeId', 'value': reportContext.employeeId?.toString() ?? ''},
                {'name': 'env', 'value': env},
                {'name': 'demoMode', 'value': context.demoMode ? 'true' : 'false'}
            ]
        }
    )
}
