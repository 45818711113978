import * as React from "react";
import {Navigate} from "react-router-dom";
import {useAppSelector} from "../../../store/hooks";
import {appContextSelector} from "../appContextSlice";

export const ProtectedRoute: React.ComponentType= ({ children }) => {

    const appContext = useAppSelector(appContextSelector);

    const {
        authorizationDto,
        tokenValid,
        unauthorizedAccess
    } = appContext;

    const hasValidAuth = authorizationDto && tokenValid;

    return (
        <>
            {
                (hasValidAuth) ? (unauthorizedAccess) ? <Navigate to="/unauthorized"/> : children : <Navigate to="/login"/>
            }
        </>
    );
};

