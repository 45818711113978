import * as React from 'react';

type TemporaryMessageProps = {
    id: string;
    style?: React.CSSProperties;
    message: string;
    delay: number;
    show: boolean;
    onHide: () => void;
}

export const TemporaryMessage:React.ComponentType<TemporaryMessageProps> = ({id, style, message, delay, show , onHide}) => {
    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        if(show) {
            setVisible(true);

            const timer = setTimeout(() => {
                setVisible(false);
                onHide();
            }, delay);

            return () => clearTimeout(timer);
        }
    }, [delay, onHide, show]);

    return visible ? <div style={style} id={id}>{message}</div> : null;
};