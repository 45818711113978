import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {get, post} from "../app-context/thunks/API_Thunks";
import {RootState} from "../../store/types";
import {DevicePickupPermissionDto} from "../../dtos/DevicePickupPermission";
import {appContextActions} from "../app-context/appContextSlice";

export const devicePickupPermissionSliceName = 'devicePickupPermissionSlice';

export interface DeviceTypeState {
    permissions: DevicePickupPermissionDto[],
    selectedDevicePickupPermissionId?: number,
    viewAllPermissionsLoading: boolean,
    setDefaultLoading: boolean,
    setDefaultSuccessful?: boolean,
    savePermissionLoading: boolean,
    savePermissionSuccessful?: boolean,
    permissionsErrorMessage?: string,
    savePermissionErrors?: string[]
}

const initialState: DeviceTypeState = {
    permissions: [],
    viewAllPermissionsLoading: false,
    savePermissionLoading: false,
    setDefaultLoading: false
}

export const getDevicePickupPermission = createAsyncThunk(
    `${devicePickupPermissionSliceName}/get`,
    async ({locationId, devicePickupPermissionId} : {locationId: number, devicePickupPermissionId: number}, {dispatch}) => {
        try {
            const getPermission = await dispatch(get({urlSuffix: '/clientportal/devices/permissions/get', params: {locationId: locationId, devicePickupPermissionId: devicePickupPermissionId}}));

            return getPermission.payload;
        }
        catch (err) {
            throw err;
        }
    }
);

export const viewAllDevicePickupPermissions = createAsyncThunk(
    `${devicePickupPermissionSliceName}/viewall`,
    async ({locationId} : {locationId: number}, {dispatch}) => {
        try {
            const viewAll = await dispatch(get({urlSuffix: '/clientportal/devices/permissions/viewall', params: {locationId: locationId}}));

            return viewAll.payload;
        }
        catch (err) {
            throw err;
        }
    }
);

export const saveDevicePickupPermission = createAsyncThunk(
    `${devicePickupPermissionSliceName}/save`,
    async ({locationId, devicePickupPermissionDto} : {locationId: number, devicePickupPermissionDto: DevicePickupPermissionDto}, {dispatch}) => {
        try {
            const save = await dispatch(post({urlSuffix: '/clientportal/devices/permissions/save', params: {locationId: locationId}, body: devicePickupPermissionDto}));

            return save.payload;
        }
        catch (err) {
            throw err;
        }
    }
);

export const setDefaultPermission = createAsyncThunk(
    `${devicePickupPermissionSliceName}/setdefault`,
    async ({locationId, devicePickupPermissionId} : {locationId: number, devicePickupPermissionId: number}, {dispatch}) => {
        try {
            const setDefault = await dispatch(get({urlSuffix: '/clientportal/devices/permissions/setdefault', params: {locationId: locationId, devicePickupPermissionId: devicePickupPermissionId}}));

            if(!setDefault.payload?.hasOwnProperty('errorMessage')) {
                const result : DevicePickupPermissionDto[] = setDefault.payload;
                dispatch(appContextActions.updateDefaultPermission({locationId: locationId, permission: result.find(perm => perm.default)}));
            }

            return setDefault.payload;
        }
        catch (err) {
            throw err;
        }
    }
);

export const devicePickupPermissionSlice = createSlice({
    name: devicePickupPermissionSliceName,
    initialState,
    reducers: {
        resetSaveDevicePickupPermission: (state) => {
            state.savePermissionSuccessful = undefined;
            state.selectedDevicePickupPermissionId = undefined;
            state.permissionsErrorMessage = undefined;
            state.savePermissionErrors = undefined;
        },
        resetPermissionsList: (state) => {
            state.permissions = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDevicePickupPermission.pending, (state) => {
                // console.log('getDevicePickupPermission.pending');
            })
            .addCase(getDevicePickupPermission.rejected, (state, action) => {
                // console.log('getDevicePickupPermission.rejected');
            })
            .addCase(getDevicePickupPermission.fulfilled, (state, action) => {
                // console.log('getDevicePickupPermission.fulfilled');

                if(action.payload?.hasOwnProperty('errorMessage')) {
                    state.permissionsErrorMessage = action.payload.errorMessage;
                } else {
                    if(action.payload?.hasOwnProperty('devicePickupPermissionId')) {
                        const permissionIdx = state.permissions.findIndex(perm => perm.devicePickupPermissionId === action.payload.devicePickupPermissionId);

                        if(permissionIdx !== -1) {
                            state.permissions[permissionIdx] = action.payload;
                            state.selectedDevicePickupPermissionId = action.payload.devicePickupPermissionId;
                        }
                    }
                }
            })
            .addCase(viewAllDevicePickupPermissions.pending, (state) => {
                // console.log('viewAllDevicePickupPermissions.pending');
                state.viewAllPermissionsLoading = true;
            })
            .addCase(viewAllDevicePickupPermissions.rejected, (state, action) => {
                // console.log('viewAllDevicePickupPermissions.rejected');
                state.viewAllPermissionsLoading = false;
            })
            .addCase(viewAllDevicePickupPermissions.fulfilled, (state, action) => {
                // console.log('viewAllDevicePickupPermissions.fulfilled');
                state.viewAllPermissionsLoading = false;

                if(action.payload?.hasOwnProperty('errorMessage')) {
                    state.permissionsErrorMessage = action.payload.errorMessage;
                }
                else {
                    if(action.payload) {
                        state.permissions = action.payload;
                    }
                }
            })
            .addCase(setDefaultPermission.pending, (state) => {
                // console.log('setDefaultPermission.pending');
                state.setDefaultLoading = true;
            })
            .addCase(setDefaultPermission.rejected, (state, action) => {
                // console.log('setDefaultPermission.rejected');
                state.setDefaultLoading = false;
            })
            .addCase(setDefaultPermission.fulfilled, (state, action) => {
                // console.log('setDefaultPermission.fulfilled');
                state.setDefaultLoading = false;

                if(action.payload?.hasOwnProperty('errorMessage')) {
                    state.permissionsErrorMessage = action.payload.errorMessage;
                }
                else {
                    state.setDefaultSuccessful = true;
                    state.permissions = action.payload;
                }
            })
            .addCase(saveDevicePickupPermission.pending, (state) => {
                // console.log('saveDevicePickupPermission.pending');
                state.savePermissionLoading = true;
            })
            .addCase(saveDevicePickupPermission.rejected, (state, action) => {
                // console.log('saveDevicePickupPermission.rejected');
                state.savePermissionLoading = false;
            })
            .addCase(saveDevicePickupPermission.fulfilled, (state, action) => {
                state.savePermissionLoading = false;

                if(action.payload?.hasOwnProperty('errors') && action.payload?.errors) {
                    state.savePermissionErrors = action.payload.errors;
                    state.permissionsErrorMessage = undefined;
                } else if(action.payload?.hasOwnProperty('errorMessage')) {
                    state.permissionsErrorMessage = action.payload.errorMessage;
                    state.savePermissionErrors = undefined;
                } else {
                    state.savePermissionSuccessful = true;
                }
            })
    }
});

export const devicePickupPermissionContextSelector = (state: RootState) => state[devicePickupPermissionSliceName];
export const devicePickupPermissionListSelector = (state: RootState) => devicePickupPermissionContextSelector(state).permissions;
export const selectedDevicePickupPermissionSelector = (state: RootState) => devicePickupPermissionContextSelector(state).selectedDevicePickupPermissionId;

export const currentDevicePermissionSelector = createSelector(devicePickupPermissionListSelector, selectedDevicePickupPermissionSelector, (permissions, selectedDevicePermissionId) => {
    return permissions.find(perm => perm.devicePickupPermissionId === selectedDevicePermissionId);
})
export const devicePickupPermissionActions = devicePickupPermissionSlice.actions;
export default devicePickupPermissionSlice.reducer;

