import {Container, styled } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { CloudUpload } from '@material-ui/icons';
import * as React from 'react';
import {DARK_GREY, LIGHTER_GREY} from "../../themes/colors";
import {calculatedFontSize, calculateResponsiveSizeWithMinMax} from "../../util/TextUtils";
import {getLocalizedString} from "../../util/Localization";

type FileDropzoneProps = {
    fileInputRef: React.RefObject<HTMLInputElement>,
    onFileChange: React.ChangeEventHandler<HTMLInputElement>
}

export const FileDropzone:React.ComponentType<FileDropzoneProps> = (props) => {

    return (
        <div>
            <DropzoneContainer>
                <DropzoneIcon onClick={() => {
                    props.fileInputRef.current?.click()
                }}/>
                <DropzoneText>
                    <span
                        style={{textDecoration: 'underline', cursor: 'pointer'}}
                        onClick={() => {
                            props.fileInputRef.current?.click()
                        }}
                    >
                        {getLocalizedString('filedropzone.click', 'Click to upload')}
                    </span>
                    {getLocalizedString('filedropzone.orDrag', ' or drag and drop')}
                </DropzoneText>
                <br/>
                <DropzoneTextSm>
                    {props.fileInputRef.current?.files?.item(0)?.name ?? getLocalizedString('filedropzone.format', 'File must be in a .csv format')}
                </DropzoneTextSm>
                <input
                    style={{display: 'flex', width: '100%', height: '100%', position: 'absolute', opacity: 0, cursor: 'pointer'}}
                    type="file" id="file-dropzone-input" ref={props.fileInputRef} onChange={props.onFileChange}
                    multiple={false}
                >
                </input>
            </DropzoneContainer>
        </div>
    )
}


const DropzoneIcon = styled(CloudUpload)(({theme}) => ({
    color: theme.palette.primary.main,
    width: '40%',
    height: '50%',
    cursor: 'pointer'
}));

const DropzoneText = styled(Typography) (({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: calculatedFontSize('.9em', '1.1em'),
    fontWeight: 500
}));

const DropzoneContainer = styled(Container)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: 0,
    width: '100%',
    color: DARK_GREY,
    fontSize: calculateResponsiveSizeWithMinMax('2', '18', '16'),
    backgroundColor: LIGHTER_GREY,
    height: '40vh',
    border: '1px dashed black',
    borderRadius: '5px'
});

const DropzoneTextSm = styled(Typography) (({theme}) => ({
    fontSize: calculateResponsiveSizeWithMinMax('2', '16', '14'),
    fontWeight: 500
}));
