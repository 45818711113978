import {
    DeviceDto, DeviceFieldToSave,
    DeviceFilterDto,
    DeviceStatDto, ExistingDeviceDto,
    isActive,
    isInactive,
    isPending,
} from "../../dtos/Device";
import {Action, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {get, getFile, post, postFormData} from "../app-context/thunks/API_Thunks";
import {createDeepEqualSelector, RootState} from "../../store/types";
import {ImportResponse} from "../../dtos/Response/ImportResponse";
import {PortalUploadStatusDto} from "../../dtos/PortalUploadStatus";
import {getLocalizedString} from "../../util/Localization";
import {BulkActionResponseDto} from "../../dtos/BulkAction";

export const deviceSliceName = `deviceContext`;

export interface DeviceState {
    selectedDevice?: DeviceDto,
    selectedDeviceStats?: DeviceStatDto,
    devicesList : DeviceDto[] | undefined,
    getDeviceForEditErrorMessage?: string,
    saveSuccessful?: boolean,
    saveDeviceLoading: boolean,
    createErrorMessage?: string,
    saveIdErrorMessage?: string,
    saveTagErrorMessage?: string,
    saveTypeErrorMessage?: string,
    existingDeviceDto?: ExistingDeviceDto,
    deviceFieldToSave?: DeviceFieldToSave,
    savedDeviceId?: number,
    getDevicesLoading: boolean,
    getDevicesErrorMessage?: string,
    totalLockers?: number,
    lockersInReserve?: number,
    deactivateSuccessful?: boolean,
    deactivateErrorMessage?: string,
    reactivateSuccessful?: boolean,
    reactivateErrorMessage?: string,
    importSuccessful?: boolean,
    importResponse?: ImportResponse,
    importErrorMessage?: string,
    importDevicesTemplateFile?: string,
    importStatuses?: PortalUploadStatusDto[],
    importStatusesLoading?: boolean,
    importStatusesSuccess?: boolean,
    importResult?: PortalUploadStatusDto,
    exportSuccessful?: boolean,
    exportSuccessMessage?: string,
    exportErrorMessage?: string,
    exportFile?: string,
    devicesListOffset: number,
    devicesListLimit: number,
    deviceFilters: DeviceFilterDto,
    totalDevices: number | undefined,
    totalActiveDevices: number | undefined,
    deviceUploadTemplateHeader?: string[],
    bulkActionSuccessful?: boolean,
    bulkActionLoading?: boolean,
    bulkActionResponse?: BulkActionResponseDto,
}

const initialState: DeviceState = {
    devicesList: undefined,
    saveDeviceLoading: false,
    getDevicesLoading: false,
    devicesListOffset: 0,
    devicesListLimit: 100,
    deviceFilters: {
        isAsc: true,
        deviceTag: undefined,
        deviceIdNumber: undefined,
        orderByString: undefined,
        status: undefined,
    },
    totalDevices: undefined,
    totalActiveDevices: undefined
}

export const getDeviceForEdit = createAsyncThunk(
    `${deviceSliceName}/edit`,
    async ({locationId, deviceId} : {locationId : number, deviceId: number}, {dispatch}) => {
        try {
            const editDevice =  await dispatch(get({urlSuffix: `/clientportal/devices/edit`, params: {locationId, deviceId}}));
            // console.log({editDevice});

            return editDevice.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
)

type GetDevicesBrandType = {
    locationId: number,
    brandId?: number,
};
export const getDevicesByBrand = createAsyncThunk(
    `${deviceSliceName}/findall`,
    async ({locationId, brandId} : GetDevicesBrandType, {dispatch, getState}) => {
        let {deviceFilters, devicesListLimit, devicesListOffset} = deviceContextSelector(getState());
        try {
            const getDevices = await dispatch(
                post({
                    urlSuffix: `/clientportal/devices/findall`, 
                    params: {locationId, brandId, limit: devicesListLimit, offset: devicesListOffset}, body: deviceFilters
                })
            );
            // console.log({getDevices});

            return getDevices.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
)

export const getDevicesByBrandRefresh = createAsyncThunk(
    `${deviceSliceName}/findallrefresh`,
    async ({locationId, brandId} : GetDevicesBrandType, {dispatch}) => {
        dispatch(deviceContextActions.resetGetDevices());
        dispatch(getDevicesByBrand({
            locationId,
            brandId
        }))
    }
)

export const getFirstOffsetDevicesByBrandWithoutFilter = createAsyncThunk(
    `${deviceSliceName}/findallwithoutfilter`,
    async ({locationId, brandId} : GetDevicesBrandType, {dispatch}) => {
        dispatch(deviceContextActions.clearDeviceFilter());
        dispatch(getDevicesByBrand({
            locationId,
            brandId
        })) 
    }
)

export const saveDevice = createAsyncThunk(
    `${deviceSliceName}/save`,
    async ({deviceDto, 
                         locationId, 
                         deviceFieldToSave} : {deviceDto: DeviceDto, 
                                                    locationId: number, deviceFieldToSave: DeviceFieldToSave}, {dispatch}) => {
        
        dispatch(deviceContextActions.setDeviceFieldToSave(deviceFieldToSave));
        try {
            const saveDevice = await dispatch(
                post({
                    urlSuffix: `/clientportal/devices/save`, 
                    params: {locationId}, body: deviceDto
                })
            );

            return saveDevice.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
)

export const saveExistingDevice = createAsyncThunk(
    `${deviceSliceName}/saveexistingdevice`,
    async ({deviceDto, 
                         locationId} : {
                            deviceDto: DeviceDto, 
                            locationId?: number}, {dispatch}) => {
        try {
            if (locationId) {
                const saveDevice = await dispatch(
                        post({
                            urlSuffix: `/clientportal/devices/save`,
                            params: {locationId}, body: deviceDto
                        })
                );

                return saveDevice.payload;
            } else {
                throw new Error("No Location For Existing Device");
            }
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
)

export const createDevice = createAsyncThunk(
    `${deviceSliceName}/create`,
    async ({deviceDto, locationId} : {deviceDto: DeviceDto, locationId: number}, {dispatch}) => {
        try {
            const saveDevice = await dispatch(
                post({
                    urlSuffix: `/clientportal/devices/create`, 
                    params: {locationId}, body: deviceDto
                })
            );

            return saveDevice.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
)

export const transferDevice = createAsyncThunk(
    `${deviceSliceName}/transfer`,
    async ({deviceDto, locationId, reactivate} : 
                            {deviceDto: DeviceDto, 
                            locationId: number, 
                            reactivate?: boolean}, {dispatch}) => {
        try {
            const saveDevice = await dispatch(
                post({
                    urlSuffix: `/clientportal/devices/transfer`,
                    params: {
                        locationId,
                        reactivate: reactivate ?? false
                    }, body: deviceDto
                })
            );

            return saveDevice.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
)

// TODO: can probably do this without making another thunk/action that just doesn't set the saveSuccessful flag
export const transferDeviceStayOnPage = createAsyncThunk(
    `${deviceSliceName}/transferstayonpage`,
    async ({deviceDto, locationId, reactivate} : 
                            {deviceDto: DeviceDto, 
                            locationId: number, 
                            reactivate?: boolean}, {dispatch}) => {
        try {
            const saveDevice = await dispatch(
                post({
                    urlSuffix: `/clientportal/devices/transfer`,
                    params: {
                        locationId,
                        reactivate: reactivate ?? false
                    }, body: deviceDto
                })
            );
            
            return saveDevice.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
)

export const reactivateDevice = createAsyncThunk(
    `${deviceSliceName}/reactivate`,
    async ({deviceId, locationId} : {deviceId : number, locationId: number}, {dispatch}) => {
        try {
            const reactivateDevice = await dispatch(post({urlSuffix: `/clientportal/devices/reactivate`, params:{locationId, deviceId}, body: undefined}));
            // console.log({reactivateDevice});

            return reactivateDevice.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
)

export const deactivateDevice = createAsyncThunk(
    `${deviceSliceName}/deactivate`,
    async ({deviceDto, locationId} : {deviceDto : DeviceDto, locationId: number}, {dispatch}) => {
        try {
            const deactivateDevice = await dispatch(post({urlSuffix: `/clientportal/devices/deactivate`, params: {locationId}, body: deviceDto}));
            // console.log({deactivateDevice});

            return deactivateDevice.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
)

export const getDeviceStats = createAsyncThunk(
    `${deviceSliceName}/stats`,
    async ({deviceId, locationId} : {deviceId: number, locationId: number}, {dispatch}) => {
        try {
            const getDeviceStats = await dispatch(get({urlSuffix: `/clientportal/devices/stats`, params: {locationId, deviceId}}));

            return getDeviceStats.payload;
        }
        catch (err) {
            //console.log(err);
            throw err;
        }
    }
)

export const downloadDeviceImportTemplate = createAsyncThunk(
    `${deviceSliceName}/downloadimporttemplate`,
    async ({locationId} : {locationId: number}, {dispatch}) => {
        try {
            const downloadDeviceImportTemplate = await dispatch(get({urlSuffix: `/clientportal/devices/generateuploadtemplate`, params: {locationId}}));
            // console.log(downloadDeviceImportTemplate);

            return downloadDeviceImportTemplate.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
)

export const importDevices = createAsyncThunk(
        `${deviceSliceName}/import`,
        async ({file, locationId}: {file: File ,locationId : number}, {dispatch}) => {
            try {
                const formData = new FormData();
                if(file) {
                    // Update the formData object
                    formData.append(
                        "file",
                        file,
                        file.name
                    );

                    const importDevices = await dispatch(postFormData({
                        urlSuffix: `/clientportal/devices/import`,
                        params: {locationId},
                        body: formData,
                        data: formData
                    }));
                    // console.log({importDevices});

                    return importDevices.payload;
                }
            }
            catch(err) {
                // console.log(err);
                throw err;
            }
        }
)

export const getDeviceUploadTemplateHeader = createAsyncThunk(
    `${deviceSliceName}/getuploadtemplateheader`,
    async ({locationId} : {locationId: number}, {dispatch}) => {
        try {
            const headerResponse = await dispatch(get({urlSuffix: `/clientportal/devices/getuploadtemplateheader`, params: {locationId}}));

            return headerResponse.payload;
        }
        catch (err) {
            //console.log(err);
            throw err;
        }
    }
);


export const getDeviceImportStatuses = createAsyncThunk(
    `${deviceSliceName}/import/status`,
    async ({locationId} : {locationId: number}, {dispatch}) => {
        try {
            const getImportStatus = await dispatch(get({urlSuffix: `/clientportal/devices/import/status`, params: {locationId}}));

            return getImportStatus.payload;
        }
        catch (err) {
            //console.log(err);
            throw err;
        }
    }
);

export const getDeviceImportResult = createAsyncThunk(
    `${deviceSliceName}/import/result`,
    async ({locationId, portalUploadStatusId} : {locationId: number, portalUploadStatusId: number}, {dispatch}) => {
        try {
            const getImportStatus = await dispatch(get({urlSuffix: `/clientportal/devices/import/result`, params: {locationId, portalUploadStatusId}}));

            return getImportStatus.payload;
        }
        catch (err) {
            //console.log(err);
            throw err;
        }
    }
);

export const updateDuplicateList = createAsyncThunk(
    `${deviceSliceName}/import/updateduplicatelist`,
    async ({portalUploadStatusId, duplicateList} : {portalUploadStatusId: number, duplicateList: string}, {dispatch}) => {
        try {
            const result = await dispatch(post({urlSuffix: `/clientportal/devices/import/updateduplicatelist`, params: {portalUploadStatusId, duplicateList}, body: undefined}));
            return result.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
)

export const exportDevices = createAsyncThunk(
        `${deviceSliceName}/export`,
        async ({locationId, activeOnly} : {locationId : number, activeOnly: boolean}, {dispatch}) => {
            try {
                const exportDevices = await dispatch(getFile({urlSuffix: `/clientportal/devices/export`, params: {locationId, activeOnly}}));
                // console.log({exportDevices});

                return exportDevices.payload;
            }
            catch (err) {
                // console.log(err);
                throw err;
            }
        }
)

export const bulkUpdateDeviceType = createAsyncThunk(
    `${deviceSliceName}/bulk/updatedevicetype`,
    async ({locationId, deviceList, deviceTypeId} : {locationId: number, deviceList: number[], deviceTypeId: number}, {dispatch}) => {
        try {
            const bulkDeactivate = await dispatch(post({urlSuffix: `/clientportal/device/bulk/updatedevicetype`, params: {locationId, deviceTypeId}, body: {deviceList: deviceList}}));

            return bulkDeactivate.payload;
        }
        catch(err) {
            throw err;
        }
    }
);

export const deviceSlice = createSlice({
    name: deviceSliceName,
    initialState,
    reducers: {
        resetGetDevices: (state) => {
            state.getDevicesErrorMessage = undefined;
            state.devicesListOffset = 0;
            state.devicesList = undefined;
            state.selectedDevice = undefined;
            state.existingDeviceDto = undefined;
        },
        resetSaveDevice: (state) => {
            state.saveSuccessful = undefined;
            state.createErrorMessage = undefined;
            state.selectedDevice = undefined;
            state.savedDeviceId = undefined;
            state.existingDeviceDto = undefined;
        },
        resetExistingDevice: (state) => {
            state.existingDeviceDto = undefined;
        },
        resetSaveErrorMessages: (state) => {
            state.saveIdErrorMessage = undefined;
            state.saveTypeErrorMessage = undefined;
            state.saveTagErrorMessage = undefined;
        },
        resetReactivateStatus: (state) => {
            state.reactivateSuccessful = undefined;
        },
        resetDeactivateStatus: (state) => {
            state.deactivateSuccessful = undefined;
            state.deactivateErrorMessage = undefined;
        },
        resetImportDevice: (state) => {
            state.importResponse = undefined;
            state.importErrorMessage = undefined;
            state.importSuccessful = undefined;
            state.importDevicesTemplateFile = undefined;
            state.importResult = undefined;
        },
        resetExportDevice: (state) => {
            state.exportErrorMessage = undefined;
            state.exportFile = undefined;
        },
        setSelectedDevice: (state, action: PayloadAction<DeviceDto | undefined>) => {
            state.selectedDevice = action.payload;
            state.existingDeviceDto = undefined;
        },
        setDeviceFilter: (state, action: PayloadAction<DeviceFilterDto>) => {
            state.devicesList = undefined;
            state.devicesListOffset = 0;
            state.deviceFilters = action.payload;
            state.existingDeviceDto = undefined;
        },
        clearDeviceFilter: (state, action: Action) => {
            state.devicesList = undefined;
            state.devicesListOffset = 0;
            state.deviceFilters = initialState.deviceFilters;
            state.existingDeviceDto = undefined;
        },
        setDeviceFieldToSave: (state, action: PayloadAction<DeviceFieldToSave>) => {
            state.saveIdErrorMessage = undefined;
            state.saveTagErrorMessage = undefined;
            state.saveTypeErrorMessage = undefined;
            state.deviceFieldToSave = action.payload;
            state.existingDeviceDto = undefined;
        }
    },
    extraReducers: (builder => {
        builder
            .addCase(getDeviceForEdit.pending, (state, action) => {
                // console.log('getDeviceForEdit.pending');
            })
            .addCase(getDeviceForEdit.rejected, (state, action) => {
                // console.log('getDeviceForEdit.rejected');
            })
            .addCase(getDeviceForEdit.fulfilled, (state, action) => {
                if(action.payload?.hasOwnProperty("errorMessage")){
                    state.getDeviceForEditErrorMessage = action.payload.errorMessage;
                    state.selectedDevice = undefined;
                }
                else if(action.payload?.hasOwnProperty("deviceId")) {
                    // console.log(action.payload);
                    state.selectedDevice = action.payload;
                    state.getDeviceForEditErrorMessage = undefined;
                }
                else {
                    state.getDeviceForEditErrorMessage = getLocalizedString('editDevice.loadData', 'Unable to load device data');
                }
            })
            .addCase(getDevicesByBrand.pending, (state, action) => {
                // console.log('getDevicesByBrand.pending');
                state.getDevicesLoading = true;
                // state.devicesList = undefined;
            })
            .addCase(getDevicesByBrand.rejected, (state, action) => {
                // console.log('getDevicesByBrand.rejected');
                state.getDevicesLoading = false;
                state.devicesList = undefined;
            })
            .addCase(getDevicesByBrand.fulfilled, (state, action) => {
                // console.log('getDevicesByBrand.pending');
                state.getDevicesLoading = false;
                if(action.payload?.hasOwnProperty("errorMessage")){
                    state.getDevicesErrorMessage = action.payload.errorMessage;
                }
                else if(action.payload?.hasOwnProperty("devices")) {
                    let deviceList = action.payload.devices.map((item : DeviceDto) => {
                        return {
                            deviceId: item.deviceId,
                            locationId: item.locationId,
                            deviceIdNumber: item.deviceIdNumber,
                            deviceTag: item.deviceTag,
                            status: item.status,
                            deviceType: {
                                deviceTypeId: item.deviceType?.deviceTypeId,
                                brandId: item.deviceType?.brandId,
                                deviceTypeName: item.deviceType?.deviceTypeName,
                                serialIsDevice: item.deviceType?.serialIsDevice
                            },
                            deviceHistoryList: item.deviceHistoryList
                        }
                    });
                    let offsetHasData = deviceList?.length > 0;
                    if (offsetHasData) {
                        state.devicesListOffset += state.devicesListLimit; // increment offset
                        if (state.devicesList && state.devicesList?.length > 0) {
                            state.devicesList = state.devicesList.concat(deviceList);
                        } else {
                            state.devicesList = deviceList;
                        }
                    } else {
                        state.devicesListOffset = -state.devicesListLimit;
                    }
                    
                    state.lockersInReserve = action.payload.lockersInReserve;
                    state.totalLockers = action.payload.totalLockers;
                    state.getDevicesErrorMessage = undefined;
                    state.totalDevices = action.payload.totalDevices;
                    state.totalActiveDevices = action.payload.totalActiveCount;
                }
            })
            .addCase(createDevice.fulfilled, (state, action) => {
                if(action.payload?.hasOwnProperty("deviceId")) {
                    state.saveSuccessful = true;
                    state.createErrorMessage = undefined;
                    state.savedDeviceId = parseInt(action.payload.deviceId);
                }
                else if(action.payload?.hasOwnProperty("existingDeviceDto")) {
                    state.saveSuccessful = false;
                    state.createErrorMessage = ((action.payload.saveIdErrorMessage + '\n') ?? '') +
                                               ((action.payload.saveTagErrorMessage + '\n') ?? '') +
                                               ((action.payload.saveTypeErrorMessage + '\n') ?? '');
                    state.existingDeviceDto = action.payload.existingDeviceDto;
                }
                else {
                    state.saveSuccessful = false;
                    state.createErrorMessage = getLocalizedString('createDevice.save.error', 'Unable to save device');
                }
            })
            .addCase(transferDevice.fulfilled, (state, action) => {
                state.saveDeviceLoading = false;
                if(action.payload?.hasOwnProperty("deviceDto")) {
                    state.saveSuccessful = true;
                    state.saveIdErrorMessage = undefined;
                    state.saveTagErrorMessage = undefined;
                    state.saveTypeErrorMessage = undefined;
                    state.existingDeviceDto = undefined;
                    state.savedDeviceId = parseInt(action.payload.deviceDto.deviceId);
                    state.selectedDevice = action.payload.deviceDto;
                }
                else if(action.payload?.hasOwnProperty("saveIdErrorMessage") || 
                        action.payload?.hasOwnProperty("saveTagErrorMessage") || 
                        action.payload?.hasOwnProperty("saveTypeErrorMessage")) {
                    state.saveSuccessful = false;
                    state.createErrorMessage = ((action.payload.saveIdErrorMessage + '\n') ?? '') +
                                               ((action.payload.saveTagErrorMessage + '\n') ?? '') +
                                               ((action.payload.saveTypeErrorMessage + '\n') ?? '');
                }
                else {
                    state.saveSuccessful = false;
                    state.saveIdErrorMessage = getLocalizedString('createDevice.save.error', 'Unable to save device');
                }
            })
            .addCase(transferDeviceStayOnPage.fulfilled, (state, action) => {
                state.saveDeviceLoading = false;
                if(action.payload?.hasOwnProperty("deviceDto")) {
                    state.saveIdErrorMessage = undefined;
                    state.saveTagErrorMessage = undefined;
                    state.saveTypeErrorMessage = undefined;
                    state.existingDeviceDto = undefined;
                    state.savedDeviceId = parseInt(action.payload.deviceDto.deviceId);
                    state.selectedDevice = action.payload.deviceDto;
                }
                else if(action.payload?.hasOwnProperty("saveIdErrorMessage") || 
                        action.payload?.hasOwnProperty("saveTagErrorMessage") || 
                        action.payload?.hasOwnProperty("saveTypeErrorMessage")) {
                    state.saveSuccessful = false;
                    state.createErrorMessage = ((action.payload.saveIdErrorMessage + '\n') ?? '') +
                                               ((action.payload.saveTagErrorMessage + '\n') ?? '') +
                                               ((action.payload.saveTypeErrorMessage + '\n') ?? '');
                }
                else {
                    state.saveSuccessful = false;
                    state.saveIdErrorMessage = getLocalizedString('createDevice.save.error', 'Unable to save device');
                }
            })
            .addCase(saveExistingDevice.pending, (state, action) => {
                state.saveDeviceLoading = true;
                state.saveSuccessful = false;
            })
            .addCase(saveExistingDevice.fulfilled, (state, action) => {
                state.saveDeviceLoading = false;
                if(action.payload?.hasOwnProperty("deviceDto")) {
                    state.saveIdErrorMessage = undefined;
                    state.saveTagErrorMessage = undefined;
                    state.saveTypeErrorMessage = undefined;
                    state.savedDeviceId = parseInt(action.payload.deviceDto.deviceId);
                }
                else if(action.payload?.hasOwnProperty("saveIdErrorMessage") || 
                        action.payload?.hasOwnProperty("saveTagErrorMessage") || 
                        action.payload?.hasOwnProperty("saveTypeErrorMessage")) {
                    state.saveSuccessful = false;
                    state.createErrorMessage = ((action.payload.saveIdErrorMessage + '\n') ?? '') +
                                               ((action.payload.saveTagErrorMessage + '\n') ?? '') +
                                               ((action.payload.saveTypeErrorMessage + '\n') ?? '');
                }
                else {
                    state.saveSuccessful = false;
                    state.createErrorMessage = getLocalizedString('createDevice.save.error', 'Unable to save device');
                }
            })
            .addCase(saveDevice.pending, (state, action) => {
                state.saveDeviceLoading = true;
                state.saveSuccessful = false;
            })
            .addCase(saveDevice.fulfilled, (state, action) => {
                state.saveDeviceLoading = false;
                if(action.payload?.hasOwnProperty("deviceDto")) {
                    state.saveSuccessful = true;
                    state.saveIdErrorMessage = undefined;
                    state.saveTagErrorMessage = undefined;
                    state.saveTypeErrorMessage = undefined;
                    state.savedDeviceId = parseInt(action.payload.deviceId);
                    state.selectedDevice = action.payload.deviceDto;
                }
                else if(action.payload?.hasOwnProperty("existingDeviceDto")) {
                    state.saveSuccessful = false;
                    state.saveIdErrorMessage = action.payload?.saveIdErrorMessage;
                    state.saveTagErrorMessage = action.payload?.saveTagErrorMessage;
                    state.saveTypeErrorMessage = action.payload?.saveTypeErrorMessage;
                    state.existingDeviceDto = action.payload?.existingDeviceDto;
                }
                else {
                    state.saveSuccessful = false;
                    state.saveIdErrorMessage = getLocalizedString('createDevice.save.error', 'Unable to save device');
                }
            })
            .addCase(reactivateDevice.fulfilled, (state, action) => {
                // console.log('reactivateDevice.fulfilled');
                if(action.payload?.hasOwnProperty("deviceDto")) {
                    state.reactivateSuccessful = true;
                    state.reactivateErrorMessage = undefined;
                    state.selectedDevice = action.payload;
                }
                else if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.reactivateSuccessful = false;
                    state.reactivateErrorMessage = action.payload.errorMessage;
                }
                else {
                    state.reactivateSuccessful = false;
                    state.reactivateErrorMessage = getLocalizedString('reactivateDevice.error', 'Unable to reactivate device');
                }
            })
            .addCase(deactivateDevice.fulfilled, (state, action) => {
                // console.log('deactivateDevice.fulfilled');
                if(action.payload?.hasOwnProperty("deviceDto")) {
                    state.deactivateSuccessful = true;
                    state.deactivateErrorMessage = undefined;
                    state.selectedDevice = action.payload;
                }
                else if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.deactivateSuccessful = false;
                    state.deactivateErrorMessage = action.payload.errorMessage;
                }
                else {
                    state.deactivateSuccessful = false;
                    state.deactivateErrorMessage = getLocalizedString('deactivateDevice.error', 'Unable to deactivate device');
                }
            })
            .addCase(getDeviceStats.fulfilled, (state, action) => {
                // console.log('getDeviceStats.fulfilled');
                if(action.payload?.hasOwnProperty("lastPickup")) {
                    state.selectedDeviceStats = action.payload;
                }
                else if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.selectedDeviceStats = undefined;
                }
                else {
                    state.selectedDeviceStats = undefined;
                }
            })
            .addCase(downloadDeviceImportTemplate.fulfilled, (state, action) => {
                // console.log('downloadDeviceImportTemplate.fulfilled');

                if(action.payload?.hasOwnProperty('errorMessage')) {
                    state.importDevicesTemplateFile = undefined;
                }
                else if (action.payload) {
                    state.importDevicesTemplateFile = window.URL.createObjectURL(new Blob([action.payload]));
                }
            })
            .addCase(importDevices.rejected, (state, action) => {
                // console.log('importDevices.rejected');

                state.importSuccessful = false;
                state.importErrorMessage = getLocalizedString('import.device.error', 'Error importing devices, please check document formatting');
            })
            .addCase(importDevices.fulfilled, (state, action) => {
                // console.log('importDevices.fulfilled');

                if(action.payload?.hasOwnProperty('errorMessage')){
                    state.importSuccessful = false;
                    state.importErrorMessage = action.payload.errorMessage;
                }
                else if(action.payload) {
                    state.importSuccessful = true;
                    state.importResponse = {
                        successCount: action.payload.successCount,
                        successMessage: action.payload.successMessage,
                        updatedCount: action.payload.updatedCount,
                        updatedMessage: action.payload.updatedMessage,
                        duplicateCount: action.payload.duplicateCount,
                        duplicateMessage: action.payload.duplicateMessage,
                        existingIdCount: action.payload.existingIdCount,
                        existingIdMessage: action.payload.existingIdMessage,
                        badFormatCount: action.payload.badFormatCount,
                        badFormatMessage: action.payload.badFormatMessage
                    };
                    state.importErrorMessage = undefined;
                }
            })
            .addCase(getDeviceUploadTemplateHeader.fulfilled, (state, action) => {
                if(!action.payload.hasOwnProperty("errorMessage")) {
                    state.deviceUploadTemplateHeader = action.payload
                }
            })
            .addCase(getDeviceImportStatuses.pending, (state, action) => {
                state.importStatusesLoading = true;
                state.importStatusesSuccess = false;
            })
            .addCase(getDeviceImportStatuses.fulfilled, (state, action) => {
                state.importStatusesLoading = false;
                if(action.payload.hasOwnProperty("errorMessage")) {
                    state.importStatusesSuccess = false;
                }
                else if(action.payload) {
                    state.importStatusesSuccess = true;
                    state.importStatuses = action.payload.map((status : PortalUploadStatusDto) => {
                        return {
                            portalUploadStatusId: status.portalUploadStatusId,
                            status: status.status,
                            createDateTime: status.createDateTime,
                            fileName: status.fileName,
                            uploadType: status.uploadType,
                            totalRows: status.totalRows,
                            processedRows: status.processedRows,
                            uploadedRows: status.uploadedRows,
                            uploadedList: status.uploadedList,
                            badFormatRows: status.badFormatRows,
                            badFormatList: status.badFormatList,
                            duplicateRows: status.duplicateRows,
                            duplicateList: status.duplicateList,
                            duplicateCredentialRows: status.duplicateCredentialRows,
                            duplicateCredentialList: status.duplicateCredentialList
                        }
                    })
                }
            })
            .addCase(getDeviceImportResult.fulfilled, (state, action) => {
                if (action.payload.hasOwnProperty("errorMessage")) {

                } else if (action.payload) {
                    state.importResult = action.payload as PortalUploadStatusDto
                }
            })
            .addCase(updateDuplicateList.fulfilled, (state, action) => {
                if (action.payload.hasOwnProperty("errorMessage")) {
                    
                } else if (action.payload) {
                    state.importResult = undefined;
                }
            })
            .addCase(exportDevices.rejected, (state, action) => {
                // console.log('exportDevices.rejected');

                state.exportSuccessful = false;
                state.exportErrorMessage = getLocalizedString('exportDevices.error', 'Unable to export devices');
                state.exportFile = undefined;
            })
            .addCase(exportDevices.fulfilled, (state, action) => {
                // console.log('exportDevices.fulfilled');

                if(action.payload?.hasOwnProperty('errorMessage')) {
                    state.exportSuccessful = false;
                    state.exportErrorMessage = action.payload.errorMessage;
                    state.exportFile = undefined;
                }
                else if (action.payload) {
                    state.exportSuccessful = true;
                    state.exportSuccessMessage = action.payload.successMessage;
                    state.exportErrorMessage = undefined;

                    state.exportFile = window.URL.createObjectURL(new Blob([action.payload]));
                }

            })
            .addCase(bulkUpdateDeviceType.pending, (state, action) => {
                state.bulkActionLoading = true;
            })
            .addCase(bulkUpdateDeviceType.fulfilled, (state, action) => {
                state.bulkActionLoading = false;

                if(action.payload?.hasOwnProperty("message")) {
                    state.bulkActionResponse = action.payload;
                    state.bulkActionSuccessful = true;
                } else if (action.payload?.hasOwnProperty("errorMessage")) {
                    state.bulkActionResponse = action.payload;
                    state.bulkActionSuccessful = false;
                } else {
                    state.getDevicesErrorMessage = getLocalizedString('bulkTask.error', 'Error Running Bulk Task');
                    state.bulkActionSuccessful = false;
                }
            })


    })
})

export const deviceContextSelector = (state: RootState) => state[deviceSliceName];
export const deviceListSelector = (state: RootState) => deviceContextSelector(state).devicesList;
export const deviceListOffsetSelector = (state: RootState) => state[deviceSliceName].devicesListOffset;

export const sortedDevicesSelector = createDeepEqualSelector(
    deviceListSelector,
    (state: RootState, sortBy: 'deviceTag' | 'deviceIdNumber' | 'deviceType', order: 'asc' | 'desc') => ({sortBy, order}),
    (deviceList, {sortBy, order}) => {
        return (deviceList?.slice() ?? []).sort((d1, d2) => {
            if(isActive(d1) && (isInactive(d2) || isPending(d2))) {
                return -1;
            }
            if(isPending(d1) && isInactive(d2)) {
                return -1;
            }
            if(isPending(d1) && isActive(d2)) {
                return 1;
            }
            if(isInactive(d1) && (isActive(d2) || isPending(d2))) {
                return 1;
            }

            if(sortBy === 'deviceTag') {
                    return order === 'asc' ? d1.deviceTag.localeCompare(d2.deviceTag) : d2.deviceTag.localeCompare(d1.deviceTag);
            }
            if(sortBy === 'deviceIdNumber') {
                if(d1.deviceIdNumber && d2.deviceIdNumber) {
                    return order === 'asc' ? d1.deviceIdNumber.localeCompare(d2.deviceIdNumber) : d2.deviceIdNumber.localeCompare(d1.deviceIdNumber);
                }
                else {
                    if(d1.deviceIdNumber) {
                        return order === 'asc' ? 1 : -1;
                    }
                    if(d2.deviceIdNumber) {
                        return order === 'asc' ? -1 : 1;
                    }

                    return 0;
                }
            }
            if(sortBy === 'deviceType') {
                    if(d1.deviceType?.deviceTypeName && d2.deviceType?.deviceTypeName) {
                        return order === 'asc' ? d1.deviceType.deviceTypeName.localeCompare(d2.deviceType.deviceTypeName) : d2.deviceType.deviceTypeName.localeCompare(d1.deviceType.deviceTypeName);
                    }
                    else {
                        if(d1.deviceType?.deviceTypeName) {
                            return order === 'asc' ? 1 : -1;
                        }
                        if(d2.deviceType?.deviceTypeName) {
                            return order === 'asc' ? -1 : 1;
                        }

                        return 0;
                    }
            }

            return 0;
        });


});
export const deviceContextActions = deviceSlice.actions;
export default deviceSlice.reducer;
