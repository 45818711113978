import {ARCIcon, ARCIcons, ARCIconSizes} from "./ARCIcon";
import * as React from "react";
import {Link} from "react-router-dom";

export type ARCTextLinkSizeType = 'sm' | 'md';
export type ARCTextLinkVariantType = 'primary' | 'secondary';

type ARCTextLinkType = {
    size: ARCTextLinkSizeType,
    variant: ARCTextLinkVariantType,
    label: string,
    disabled? : boolean,
    icon?: ARCIcons,
    iconSize?: ARCIconSizes
    onClick: () => void,
    id?: string,
    href: string,
}

export const ARCTextLink : React.FC<ARCTextLinkType> = (props) => {

    const textSizeClass = React.useMemo(() =>{
        if (props.size === 'sm') {
            return 'o-textlink--s';
        } else {
            return 'o-textlink--m';
        }
    }, [props.size]);

    return (
        <Link
            id={props.id}
            className={`o-textlink ${textSizeClass} o-textlink--${props.variant} ${props.disabled ? 'o-textlink--disabled' : ''}` }
            to={props.href}
            onClick={(event) => {
                if (props.href === '') {
                    event.preventDefault();
                }
                props.onClick();
            }}
        >
            {props.icon && <ARCIcon icon={props.icon} size={props.iconSize ?? props.size}/>}
            {props.label}
        </Link>
    )
}

export type ARCTextLinkComponentType = React.FC<ARCTextLinkType>;
