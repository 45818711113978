import { PriorityHigh } from '@material-ui/icons';
import React from 'react';

interface HoverErrorMessageProps {
    message: string;
    onClose: () => void;
}

const HoverErrorMessage: React.FC<HoverErrorMessageProps> = ({ message, onClose }) => {
    const errorMessageRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (errorMessageRef.current) {
            errorMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, []);

    const handleClick = React.useCallback((e: MouseEvent) => {
        if (errorMessageRef.current && errorMessageRef.current.contains(e.target as Node)) {
            onClose();
        }
    }, [onClose]);

    React.useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [handleClick]);

    return (
        <div
            ref={errorMessageRef}
            style={{
                position: 'absolute',
                top: '10px',
                width: '30vw',
                minWidth: '200px',
                maxWidth: '400px',
                backgroundColor: 'white',
                color: 'black',
                border: '1px solid grey',
                padding: '10px',
                borderRadius: '5px',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                zIndex: 1000,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: '-10px',
                    left: '10px',
                    width: '0',
                    height: '0',
                    borderLeft: '10px solid transparent',
                    borderRight: '10px solid transparent',
                    borderBottom: '10px solid grey',
                    zIndex: 1,
                }}
            />
            <div
                style={{
                    position: 'absolute',
                    top: '-8px',
                    left: '10px',
                    width: '0',
                    height: '0',
                    borderLeft: '10px solid transparent',
                    borderRight: '10px solid transparent',
                    borderBottom: '10px solid white',
                    zIndex: 2,
                }}
            />
            <div style={{width: '20px', height: '20px', backgroundColor: 'orange', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px'}}>
                <PriorityHigh style={{color: 'white', width: '15px', height: '20px'}}/>
            </div>

            {message}
        </div>
    );
};

export default HoverErrorMessage;