import React from "react";
import {calculateResponsiveSizeWithMinMax} from "../../util/TextUtils";

interface LoadingProgressBarProps {
    progress: number,
    error?: boolean,
    message?: string | React.ReactNode | undefined,
}

export const LoadingProgressBar: React.FC<LoadingProgressBarProps> = ({ progress, error, message }) => {

    return (
        <div
            style={{
                marginTop: '20px',
                width: '100%',
                height: '40px',
                border: '2px solid black',
                borderRadius: '5px',
                position: 'relative'
            }}
        >
            <div
                style={{
                    width: `${progress}%`,
                    height: '100%',
                    backgroundColor: !error ? '#2E7E9F' : 'red',
                    position: 'absolute',
                    top: 0,
                    left: 0
                }}
            >
            </div>
            {!message ?
                <div
                    style={{
                        position: 'absolute',
                        top: '150%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: calculateResponsiveSizeWithMinMax('3', '24', '18')
                    }}
                >
                    {`${Math.round(progress)}%`}
                </div>
                :
                <div
                    style={{
                        position: 'absolute',
                        top: '170%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        textAlign: 'center',
                        fontSize: calculateResponsiveSizeWithMinMax('2', '16', '14')
                    }}
                >
                    {message}
                </div>
            }
        </div>
    )
}
