import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {get, post} from "../app-context/thunks/API_Thunks";
import {LabelRequestDto} from "../../dtos/LabelRequestDto";
import {RootState} from "../../store/types";
import {getLocalizedString} from "../../util/Localization";

export const labelRequestSliceName = 'labelRequestSlice';

export interface LabelRequestState {
    saveLabelRequestSuccessful?: boolean,
    saveLabelRequestErrorMessage?: string[],
    savedLabelRequest?: LabelRequestDto,
    viewLabelRequestSuccessful?: boolean,
    viewLabelRequestErrorMessage?: string,
    viewedLabelRequest?: LabelRequestDto;
    viewAllLabelRequestsSuccessful?: boolean,
    viewAllLabelRequestsErrorMessage?: string,
    viewAllLabelRequestList?: LabelRequestDto[]
    labelRequestLoading: boolean;
}

const initialState : LabelRequestState = {
    saveLabelRequestSuccessful: undefined,
    saveLabelRequestErrorMessage: undefined,
    savedLabelRequest: undefined,
    viewLabelRequestSuccessful: undefined,
    viewLabelRequestErrorMessage: undefined,
    viewedLabelRequest: undefined,
    viewAllLabelRequestsSuccessful: undefined,
    viewAllLabelRequestsErrorMessage: undefined,
    viewAllLabelRequestList: undefined,
    labelRequestLoading: false
};

export const viewLabelRequest = createAsyncThunk(
    `${labelRequestSliceName}/view`,
    async ({labelRequestId} : {labelRequestId: number}, {dispatch}) => {
        try {
            const viewLabelRequest = await dispatch(get({urlSuffix: `/clientportal/labelrequest/view`, params: {labelRequestId}}));

            return  viewLabelRequest.payload;
        }
        catch(err) {
            throw err;
        }
    }
);

export const viewAllLabelRequest = createAsyncThunk(
    `${labelRequestSliceName}/viewall`,
    async ({locationId, brandId} : {locationId: number, brandId: number}, {dispatch}) => {
        try {
            const viewAllLabelRequest = await dispatch(get({urlSuffix: `/clientportal/labelrequest/viewall`, params: {locationId, brandId}}));

            return  viewAllLabelRequest.payload;
        }
        catch(err) {
            throw err;
        }
    }
);

export const saveLabelRequest = createAsyncThunk(
    `${labelRequestSliceName}/save`,
    async ({locationId, labelRequest} : {locationId: number, labelRequest: LabelRequestDto}, {dispatch}) => {
        try {
            const saveLabelRequest = await dispatch(post({urlSuffix: `/clientportal/labelrequest/save`, params: {locationId}, body: labelRequest}));

            return saveLabelRequest.payload;
        }
        catch(err) {
            throw err;
        }
    }
);

export const labelRequestSlice = createSlice({
    name: labelRequestSliceName,
    initialState,
    reducers: {
        resetState: (state) => {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(viewLabelRequest.pending, (state) => {

            })
            .addCase(viewLabelRequest.fulfilled, (state, action) => {
                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.viewLabelRequestSuccessful = false;
                    state.viewLabelRequestErrorMessage = action.payload.errorMessage;
                } else {
                    state.viewedLabelRequest = action.payload as LabelRequestDto;
                }
            })
            .addCase(viewAllLabelRequest.pending, (state) => {

            })
            .addCase(viewAllLabelRequest.fulfilled, (state, action) => {
                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.viewAllLabelRequestsSuccessful = false;
                    state.viewAllLabelRequestsErrorMessage = action.payload.errorMessage;
                } else {
                    state.viewAllLabelRequestList = action.payload as LabelRequestDto[];
                }
            })
            .addCase(saveLabelRequest.pending, (state) => {

            })
            .addCase(saveLabelRequest.fulfilled, (state, action) => {
                if(action.payload.hasOwnProperty("labelRequestId")) {
                    state.saveLabelRequestSuccessful = true;
                    state.savedLabelRequest = action.payload as LabelRequestDto;
                } else if(action.payload?.hasOwnProperty("errors")) {
                    state.saveLabelRequestSuccessful = false;
                    state.saveLabelRequestErrorMessage = action.payload.errors;
                } else {
                    state.saveLabelRequestSuccessful = false;
                    state.saveLabelRequestErrorMessage = [getLocalizedString('labelRequest.error', 'Error Submitting QR Code Request')];
                }
            })
    }
});

export const labelRequestContextSelector = (state: RootState) => state[labelRequestSliceName];
export const labelRequestContextActions = labelRequestSlice.actions;

