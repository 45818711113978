export const MIN_PASSWORD_STRENGTH = 3;
export const MIN_PASSWORD_LENGTH = 8;

export const passwordsMatch = (newPassword: string, confirmNewPassword: string) => {
    return (newPassword && confirmNewPassword) && newPassword === confirmNewPassword;
}

export const getPasswordStrength = (password: string) => {
    let passStrengthResult = 0;

    // Minimum password length
    if (passwordMeetsLength(password)) {
        ++passStrengthResult;
    }

    // Contains at least one uppercase character
    if (passwordHasLetter(password)) {
        ++passStrengthResult;
    }

    // Contains at least one number
    if (passwordHasNumber(password)) {
        ++passStrengthResult;
    }

    return passStrengthResult;
}

export const passwordMeetsLength = (password: string) => {
    return MIN_PASSWORD_LENGTH <= password.length;
}

export const passwordHasNumber = (password: string) => {
    return /.*[0-9].*/.test(password);
}

export const passwordHasLetter = (password: string) => {
    return /.*[A-Z].*/.test(password);
}

export const isPasswordStrong = (password: string) => {
    return getPasswordStrength(password) >= MIN_PASSWORD_STRENGTH;
}

