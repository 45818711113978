import {
    KIOSK_EMPLOYEE,
    KIOSK_MANAGER, KIOSK_NONE, KIOSK_TEAM_LEAD, PORTAL_ADMIN, PORTAL_SUPER_ADMIN
} from "../features/app-context/containers/UserPermissions";

export const PORTAL_GROUPS:string[] = [
    PORTAL_ADMIN,
    PORTAL_SUPER_ADMIN
]

export const KIOSK_GROUPS:string[] = [
    KIOSK_NONE,
    KIOSK_EMPLOYEE,
    KIOSK_TEAM_LEAD,
    KIOSK_MANAGER
]

export interface EmployeeGroupDto {
    employeeGroupId: number;
    groupName: typeof PORTAL_ADMIN | typeof PORTAL_SUPER_ADMIN | typeof KIOSK_NONE | typeof KIOSK_EMPLOYEE | typeof KIOSK_TEAM_LEAD | typeof KIOSK_MANAGER;
    groupDescription?: string;
    kioskGroup: boolean;
    accessLevel: number;
}

export const getSortOrder = (groupName: string) => {
    switch (groupName) {
        case KIOSK_EMPLOYEE:
            return 1;
        case KIOSK_TEAM_LEAD:
            return 2;
        case KIOSK_MANAGER:
            return 3;
        case KIOSK_NONE:
            return 4;
        default:
            return 0;
    }
}