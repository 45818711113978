import React from 'react';
import './css/ProgressBar.css';
import {GREEN, RED, YELLOW} from "../../themes/colors";
import {Tooltip} from "@material-ui/core";

interface ProgressBarProps {
    currentValue: number;
    maxValue: number;
    warningValue: number;
    label: string;
    toolTip?: {
        title?: string,
        text: string | string[]
    }
}

const ProgressBar: React.FC<ProgressBarProps> = ({
                                                     currentValue,
                                                     maxValue,
                                                     warningValue,
                                                     label,
                                                     toolTip
                                                 }) => {
    const percentage = React.useMemo(() => {
        return (currentValue / maxValue) * 100;
    }, [currentValue, maxValue]);

    const calculatedPercentage = React.useMemo(() => {
        return percentage < 100 ? percentage : 100;
    }, [percentage]);

    const ProgressBar = React.useMemo(() => {
        const color = currentValue < warningValue ? GREEN : currentValue < maxValue ? YELLOW : RED;

        const triangleStyle = {
            left: `${calculatedPercentage > 0 ? calculatedPercentage - 2 : 0}%`,
            borderBottomColor: color
        };

        const valueStyle = {
            left: `${calculatedPercentage > 0 ? calculatedPercentage - 4.5 : -2.5}%`,
            borderBottomColor: color
        };

        return (
            <div className="progress-bar-container">
                <div className="label">{label}</div>

                <div style={{width: '100%', display: 'flex'}}>
                    <div className="progress-bar">
                        <div className="bar" style={{ width: `${calculatedPercentage}%`, backgroundColor: color }} />
                        <div className="triangle" style={triangleStyle} />
                        <span className="value" style={valueStyle}>{currentValue}</span>
                    </div>

                    <span className="maxValue">{maxValue}</span>
                </div>

            </div>
        )
    }, [calculatedPercentage, currentValue, label, maxValue, warningValue]);

    return (
        <>
        {
            toolTip ?
                <Tooltip placement="top" title={
                    <span style={{display: 'flex', flexDirection: 'column'}}>
                        {toolTip.title &&
                        <span style={{fontWeight: 'bold', marginBottom: '10px'}}>
                            {toolTip.title}
                        </span>
                        }

                        {typeof toolTip.text === 'string' ?
                            <span>
                                {toolTip.text}
                            </span>
                            :
                            <>
                                {toolTip.text.map((text, idx) => {
                                    return (
                                        <span style={{marginBottom: '5px'}} key={idx}>
                                            {text}
                                        </span>
                                    )
                                })}
                            </>
                        }
                    </span>
                }>
                    <span>
                        {ProgressBar}
                    </span>
                </Tooltip>
                :
                {ProgressBar}
        }
        </>
    );
};

export default ProgressBar;