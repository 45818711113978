import * as React from 'react';
import {FormEvent} from 'react';
import AppBar from "../../../appbar/AppBar";
import {SideBar} from "../../../sidebar/SideBar";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {appContextSelector} from "../../../app-context/appContextSlice";
import {deviceContextActions} from "../../deviceSlice";
import {AppWithFooter} from "../../../../App";
import {
    ErrorMessageSectionItem,
    FormTitle
} from "../../../ui-components/StyledComponents";
import {deviceTypeSelector, getDeviceTypes} from "../../deviceTypeSlice";
import {selectLocationThunk} from "../../../app-context/thunks/SelectLocationThunk";
import DevicePickupPermissionInputContainer from "../../components/DevicePickupPermissionInputContainer";
import '../../../../css/DevicePickupPermissions.css';
import {DevicePickupPermissionDto} from "../../../../dtos/DevicePickupPermission";
import {
    currentDevicePermissionSelector,
    devicePickupPermissionActions,
    devicePickupPermissionContextSelector, getDevicePickupPermission,
    saveDevicePickupPermission
} from "../../devicePickupPermissionSlice";
import {ServerMessage} from "../../../ui-components/ServerMessage";
import {getLocalizedString} from "../../../../util/Localization";
import {ARCButton} from "../../../ui-components/design-system/ARCButton";
import "./EditDevicePickupPermission.scss";

type EditDevicePickupPermissionProps = {
    edit: boolean
}

const useAutoSelectLocationByUrl = () => {
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    const {locationId, devicePickupPermissionId} = useParams();

    React.useEffect(() => {
        if(locationId && context.currentLocation?.locationId !== parseInt(locationId)) {
            dispatch(selectLocationThunk(parseInt(locationId)));

            if(devicePickupPermissionId) {
                dispatch(getDevicePickupPermission({locationId: parseInt(locationId), devicePickupPermissionId: parseInt(devicePickupPermissionId)}));
                dispatch(getDeviceTypes({locationId: parseInt(locationId), brandId: context.currentBrand?.brandId}));
            }
        } else if(context.currentLocation?.locationId && devicePickupPermissionId) {
            dispatch(getDevicePickupPermission({locationId: context.currentLocation.locationId, devicePickupPermissionId: parseInt(devicePickupPermissionId)}));
            dispatch(getDeviceTypes({locationId: context.currentLocation?.locationId, brandId: context.currentBrand?.brandId}));
        }
    }, [context.currentBrand?.brandId, context.currentLocation?.locationId, devicePickupPermissionId, dispatch, locationId]);
}

export const EditDevicePickupPermission : React.ComponentType<EditDevicePickupPermissionProps> = (props) => {
    useAutoSelectLocationByUrl();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);
    const devicePickupPermissionContext = useAppSelector(devicePickupPermissionContextSelector);
    const deviceTypeContext = useAppSelector(deviceTypeSelector);

    const statePermission = useAppSelector(currentDevicePermissionSelector);

    const [formDevicePickupPermission, setFormDevicePickupPermission] = React.useState<DevicePickupPermissionDto>({
        devicePickupPermissionId: undefined,
        permissionName: '',
        allowMultipleCheckouts: false,
        default: false,
        custom: false,
        deviceTypes: deviceTypeContext.deviceTypes.length > 0 ? [{devicePickupPermissionDeviceTypeId: undefined, deviceTypeId: deviceTypeContext.deviceTypes.length === 1 ? deviceTypeContext.deviceTypes[0].deviceTypeId : undefined, quantity: 1}] : []
    });

    React.useEffect(() => {
        if(statePermission) {
            setFormDevicePickupPermission(statePermission);
        }
    }, [statePermission]);

    React.useEffect(() => {
        return () => {dispatch(devicePickupPermissionActions.resetSaveDevicePickupPermission())}
    }, [dispatch]);

    React.useEffect(() => {
        if(context.currentLocation?.locationId) {
            dispatch(getDeviceTypes({locationId: context.currentLocation.locationId, brandId: context.currentBrand?.brandId}));
        }
    }, [context.currentBrand?.brandId, context.currentLocation?.locationId, dispatch]);

    const submitForm = React.useCallback((event : FormEvent) => {
        event.preventDefault();
        window.scrollTo(0, 0);

        if(context.currentLocation?.locationId) {
            dispatch(saveDevicePickupPermission({locationId: context.currentLocation.locationId, devicePickupPermissionDto: formDevicePickupPermission}));
        }

    }, [context.currentLocation?.locationId, dispatch, formDevicePickupPermission]);

    React.useEffect(() => {
        if(devicePickupPermissionContext.savePermissionSuccessful) {
            navigate(`/employees/permissions/view/locationId=${context.currentLocation?.locationId}`, {state: {savedPermission: formDevicePickupPermission, editPermissionFrom: 'create'}});
            dispatch(deviceContextActions.resetSaveDevice());
        }
    }, [context.currentBrand?.brandId, context.currentLocation?.locationId, devicePickupPermissionContext.savePermissionSuccessful, dispatch, formDevicePickupPermission, navigate])

    return  (
        <AppWithFooter>
            <AppBar/>
            <SideBar/>
            <main className={'o-main'}>
                <div className={'l-wrap l-container--lg'}>

                    {devicePickupPermissionContext.permissionsErrorMessage &&
                        <ServerMessage variant={'error'}>
                            <ErrorMessageSectionItem>
                                {devicePickupPermissionContext.permissionsErrorMessage}
                            </ErrorMessageSectionItem>
                        </ServerMessage>
                    }
    
    
                    {devicePickupPermissionContext.savePermissionErrors &&
                    <div style={{width: '100%'}}>
                        {devicePickupPermissionContext.savePermissionErrors.map((error, idx) => {
                            return (
                                error && error !== '' &&
                                <ServerMessage variant={'error'}>
                                    <ErrorMessageSectionItem key={idx}>
                                        {error}
                                    </ErrorMessageSectionItem>
                                </ServerMessage>
                            )
                        })
                        }
                    </div>
                    }
    
                    <FormTitle variant='h5' className={'o-heading--xl'}>{(props.edit ? getLocalizedString('edit', 'Edit') : getLocalizedString('create', 'Create')) + ' ' + getLocalizedString('devicePickupPermission.edit.title', 'Device Permissions Group')}</FormTitle>
    
                    <form onSubmit={submitForm} style={{width: '100%'}}>
                        <DevicePickupPermissionInputContainer 
                            permission={formDevicePickupPermission}
                            configHideable={props.edit}
                            deviceTypes={deviceTypeContext.deviceTypes}
                            allowMultipleCheckoutsToggle={context.currentBrand ? context.currentBrand.allowMultipleCheckouts : false}
                            onChange={setFormDevicePickupPermission}
                        />

                        <div className={'device-pickup-permission__button-div'}>
                            <ARCButton
                                fill={'filled'}
                                variant={'primary'}
                                size={'md'} 
                                id="add-pickup-permission-save-button"
                                disabled={devicePickupPermissionContext.savePermissionLoading} 
                                submit={true}
                                onClick={() => {}}
                                label={getLocalizedString('save', 'Save')}
                            >
                            </ARCButton>
                            <ARCButton
                                fill={'outlined'}
                                variant={'secondary'}
                                size={'md'} 
                                id="add-pickup-permission-cancel-button"
                                onClick={() => {navigate(`/employees/permissions/view/locationId=${context.currentLocation?.locationId}`)}}
                                label={getLocalizedString('back', 'Back')}
                            >
                            </ARCButton>
                        </div>
                    </form>
                </div>
            </main>
        </AppWithFooter>
    )
}
