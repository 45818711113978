import * as React from 'react';
import {CircularProgress, Container, styled, Typography} from "@material-ui/core";
import {calculatedFontSize} from "../../util/TextUtils";
import {BLACK} from "../../themes/colors";
import {LogoDiv} from "../ui-components/StyledComponents";
import {env} from "../../dtos/API_DOMAINS";
import ARCLOGO_WHITE from "../../assets/img/ARC_Logo_White.png";
import ARCLOGO_BLACK from "../../assets/img/ARC_Logo_Black.png";
import LOGIN_BG_YELLOW from "../../assets/img/Login_BG_Yellow.png";
import {useAppDispatch} from "../../store/hooks";
import {useNavigate, useParams} from "react-router-dom";
import {generateAuth} from "../app-context/appContextSlice";
import {getLocalizedString} from "../../util/Localization";

export const ExternalLogin = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {token} = useParams();

    const authThroughToken = React.useCallback(async (token: string) => {
        await dispatch(generateAuth({token: token}));
    }, [dispatch]);

    const auth = React.useCallback(async (token: string) => {
        await authThroughToken(token).then(() => navigate('/home'))
    }, [authThroughToken, navigate])

    React.useEffect(() => {
        if(token && token !== 'null') {
            auth(token);
        } else {
            navigate('/sso/unauthorized');
        }
    }, [auth, navigate, token]);

    return (
        <LoginContainer>
            <LogoDiv>
                <img style={{width: '40%', maxWidth: '175px', height: '100%'}} src={(env && env === 'qa') ? ARCLOGO_WHITE : ARCLOGO_BLACK} alt="ARC Logo"/>
                <LogoTypography>{getLocalizedString('title', 'CLIENT PORTAL')}</LogoTypography>
            </LogoDiv>

            <Title
                variant="subtitle1"
            >
                {getLocalizedString('loginsso.title', 'Logging in...')}
            </Title>

            <CircularProgress style={{marginTop: '10vh'}}/>

        </LoginContainer>
    )
}

const LoginContainer = styled(Container)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: `url(${LOGIN_BG_YELLOW})`,
    minHeight: '100vh',
    minWidth: '100vw',
    maxWidth: '40vw',
    backgroundColor: theme.palette.secondary.main,
    backgroundSize: 'contain',
    backgroundRepeat: 'round',
    [`${theme.breakpoints.up('xs')}`]: {
        marginBottom: '-15vh',
        justifyContent: 'center'
    }
}));

const Title = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.light,
    fontSize: calculatedFontSize('2.7em', '3.2em'),
    position: 'relative',
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '10px',
}));

const LogoTypography = styled(Typography)(({theme}) => ({
    fontSize: calculatedFontSize('3.25em', '4.5em'),
    minWidth: '300px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: 'Proxima Nova',
    color: BLACK,
    [`${theme.breakpoints.down('xs')}`]: {
        fontSize: calculatedFontSize('2.25em', '3.25em'),
    },
}));