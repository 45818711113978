import React from 'react';
import './css/App.css';
import {BrowserRouter as Router, Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {LoginPage} from "./features/login/LoginPage";
import {ChangePassword} from "./features/login/change-password/ChangePassword";
import {NotFoundPage} from "./pages/NotFoundPage/NotFoundPage";
import {ProtectedRoute} from "./features/app-context/containers/ProtectedRoute";
import {BrandSelect} from "./features/login/brandselection/BrandSelect";
import {EditEmployee} from "./features/employees/pages/EditEmployee";
import {ViewEmployees} from "./features/employees/pages/view-employees/ViewEmployees";
import {ImportEmployees} from "./features/employees/pages/ImportEmployees";
import {ExportEmployees} from "./features/employees/pages/ExportEmployees";
import {ViewDevices} from "./features/devices/pages/view-devices/ViewDevices";
import {CreateDevice} from "./features/devices/pages/CreateDevice";
import {EditDevice} from "./features/devices/pages/EditDevice";
import {ImportDevices} from "./features/devices/pages/ImportDevices";
import {ExportDevices} from "./features/devices/pages/ExportDevices";
import {useAppDispatch, useAppSelector} from "./store/hooks";
import {
    appContextActions,
    appContextSelector,
    brandLogin,
    getLocation
} from "./features/app-context/appContextSlice";
import {ReactivateDevice} from "./features/devices/pages/ReactivateDevice";
import {DeactivateDevice} from "./features/devices/pages/deactivate-device/DeactivateDevice";

import {NewPortalAccount} from "./features/login/NewPortalAccount";
import {ResetPassword} from "./features/login/ResetPassword";
import {Snackbar, useTheme} from '@material-ui/core';
import {ErrorOutlined} from '@material-ui/icons';
import {RED, WHITE} from "./themes/colors";
import {UpdatePassword} from "./features/login/UpdatePassword";
import {UnauthorizedPage} from "./features/login/UnauthorizedPage";
import {Faq} from "./features/faq/Faq";
import {UnauthenticatedPage} from "./features/login/UnauthenticatedPage";
import {InactiveDialog} from "./features/ui-components/InactiveDialog";
import {post} from "./features/app-context/thunks/API_Thunks";
import {useAppViewContext} from "./features/app-context/AppViewContainer";
import { ReportsMenu } from './features/reports/ReportsMenu';
import { EmbedSDKInit } from './LookerInit';
import {ExternalLogin} from "./features/login/ExternalLogin";
import {CreateLabelRequest} from "./features/label-request/CreateLabelRequest";
import {ViewLabelRequestList} from "./features/label-request/ViewLabelRequestList";
import {ViewLabelRequest} from "./features/label-request/ViewLabelRequest";
import {EditEmployeeViewOnly} from "./features/employees/pages/EditEmployeeViewOnly";
import {ViewDevicePickupPermissions} from "./features/devices/pages/device-pickup-permissions/ViewDevicePickupPermissions";
import {EditDevicePickupPermission} from "./features/devices/pages/device-pickup-permissions/EditDevicePickupPermission";
import {emergencyPortalActions} from "./features/emergency-portal/emergencyPortalSlice";
import {AllLocationsDto} from "./dtos/Location";
import {UnauthorizedSSO} from "./features/login/UnauthorizedSSO";
import { EmergencyPortalViewKeypadCodes } from './features/emergency-portal/pages/EmergencyPortalViewKeypadCodes';
import {DesignSystemDemo} from "./pages/DesignSystemDemo";
import {ARCFooter} from "./features/ui-components/design-system/ARCFooter";
import {PrivacyTermsPage} from "./features/terms-privacy/PrivacyTermsPage";

type AppBarFooterProps = {
  backgroundColor?: string,
}

export const AppWithFooter:React.ComponentType<AppBarFooterProps> = (props) => {
  const context = useAppSelector(appContextSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {tokenExpiring, tokenExpired, emergencyTokenExpired} = useAppViewContext();
  const [showInactiveDialog, setShowInactiveDialog] = React.useState<boolean>(tokenExpiring);

  const isInEmergencyPortal = React.useMemo(() => {
      return location.pathname.includes("/emergency");
  }, [location.pathname]);

  React.useEffect(() => {
      if(emergencyTokenExpired) {
          dispatch(emergencyPortalActions.unauth());

          if(isInEmergencyPortal) {
              navigate('/emergency/login');
          }
      }

      if (tokenExpired) {
          navigate('/unauthenticated');
          dispatch(appContextActions.unauth());
      } else {
          setShowInactiveDialog(tokenExpiring);
      }
  }, [tokenExpiring, tokenExpired, dispatch, navigate, isInEmergencyPortal, emergencyTokenExpired]);

  const refreshToken = React.useCallback(async () => {
      await dispatch(post({urlSuffix: `/clientportal/refresh`, params:undefined, body: undefined})).then(() => setShowInactiveDialog(false));
  }, [dispatch]);

  return (
      <div className={'app-container'} style={{backgroundColor: props.backgroundColor ? props.backgroundColor : 'transparent'}}>
        {context.networkError &&
        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} open={!!context.networkErrorMessage} onClose={() => dispatch(appContextActions.resetNetworkIssue())} autoHideDuration={8000} style={{backgroundColor: RED, color: WHITE, padding: '5px', borderRadius: '5px'}}>
          <>
            <ErrorOutlined/>
            <div>
              {context.networkErrorMessage ? context.networkErrorMessage : 'The server has encountered an error'}
            </div>
          </>
        </Snackbar>
        }

        <InactiveDialog showDialog={showInactiveDialog}
                        onClose={() => {
                            navigate('/unauthenticated');
                            dispatch(appContextActions.unauth())
                        }}
                        onConfirm={() => refreshToken()}/>

        <div className={'app-content__container'}>
          {props.children}
        </div>

        <ARCFooter/>
      </div>
  )
}

const ScrollToTop = () => {
    const { pathname } = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    EmbedSDKInit();

    const theme = useTheme();
    document.documentElement.style.setProperty('--primary-color', theme.palette.primary.main);
    document.documentElement.style.setProperty('--secondary-color', theme.palette.secondary.main);
    document.documentElement.style.setProperty('--info-color', theme.palette.info.main);
    document.documentElement.style.setProperty('--error-color', RED);

    document.documentElement.style.setProperty('--disabled-primary', `${theme.palette.primary.main}3d`);
    document.documentElement.style.setProperty('--disabled-font-primary', `${theme.palette.primary.main}a8`);
    document.documentElement.style.setProperty('--disabled-secondary', `${theme.palette.secondary.main}3d`);
    document.documentElement.style.setProperty('--disabled-font-secondary', `${theme.palette.secondary.main}a8`);

    document.documentElement.style.setProperty('--emergency-primary-color', theme.palette.primary.main);
    document.documentElement.style.setProperty('--emergency-secondary-color', theme.palette.secondary.main);
    document.documentElement.style.setProperty('--emergency-info-color', theme.palette.info.main);

    React.useEffect(() => {
        if(context.currentLocation?.locationId && context.currentLocation.locationId !== AllLocationsDto.locationId) {
            dispatch(getLocation({locationId: context.currentLocation.locationId}));
        }
    }, [context.currentLocation?.locationId, dispatch]);

    React.useEffect(() => {
        if(context.currentBrand?.brandId && context.currentBrand.brandId > 0 && context.currentBrand.brandId !== context.lastFetchedBrandId) {
            dispatch(brandLogin({brandId: context.currentBrand?.brandId ?? 0}))
            dispatch(appContextActions.setLastFetchedBrandId(context.currentBrand.brandId));
        }
    }, [context.authorizationDto?.authorizedLocations, context.currentBrand?.brandId, context.lastFetchedBrandId, dispatch]);

  return (
      <>

          <Router>
            <ScrollToTop/>
            <Routes>
              <Route
                  path="/"
                  element={<Navigate to="/login" />}
              />

              <Route
                  path="/login/external/token=:token"
                  element={<ExternalLogin/>}
              />

              <Route
                   path="/login" element={<LoginPage />}
              />

               <Route
                   path="/forgotpassword" element={<LoginPage navToForgotPassword={true}/>}
               />

              <Route
                   path="/unauthenticated" element={<UnauthenticatedPage/>}
              />

              <Route
                  path="/sso/unauthorized" element={<UnauthorizedSSO/>}
              />

              <Route path="/changepassword" element={
                <ProtectedRoute>
                  {<ChangePassword/>}
                </ProtectedRoute>
              }/>

              <Route path="/updatepassword/token=:token" element={<UpdatePassword/>}/>

              <Route path="/resetpassword/:token" element={<ResetPassword/>}/>

            <Route path="/newportalaccount/:token" element={<NewPortalAccount/>}/>
            <Route path="/newportalaccount/:token/tokenUsed=:tokenUsed" element={<NewPortalAccount/>}/>
            <Route path="/newportalaccount/:token/tokenExpired=:tokenExpired" element={<NewPortalAccount/>}/>
            <Route path="/newportalaccount/:token/tokenInvalid=:tokenInvalid" element={<NewPortalAccount/>}/>

              <Route path="/privacy/emergency" element={<PrivacyTermsPage isTerms={false}/>}/>
              <Route path="/privacy/clientportal" element={<PrivacyTermsPage isTerms={false}/>}/>
              
              <Route path="/terms/emergency" element={<PrivacyTermsPage isTerms={true}/>}/>
              <Route path="/terms/clientportal" element={<PrivacyTermsPage isTerms={true}/>}/>

              <Route path="/home" element={
                <ProtectedRoute>
                  {
                    context.authorizationDto?.authorizedBrands && context.authorizationDto.authorizedBrands.length > 1 ? <BrandSelect/> : <ReportsMenu/>}
                </ProtectedRoute>
              }/>

              <Route path="/changebrand" element={
                  <ProtectedRoute>
                      {<BrandSelect/>}
                  </ProtectedRoute>
              }/>

              <Route path="/employees/*" element={
                <ProtectedRoute>
                  <Routes>
                    <Route path="/view/locationId=:locationId" element={<ViewEmployees/>}/>
                    <Route path="/view/locationId=:locationId/brandId=:brandId" element={<ViewEmployees/>}/>
                    <Route path="/edit/locationId=:locationId" element={<EditEmployee/>}/>
                    <Route path="/edit/locationId=:locationId/employeeId=:employeeId" element={<EditEmployee/>}/>
                    <Route path="/edit/locationId=:locationId/brandId=:brandId" element={<EditEmployee/>}/>
                    <Route path="/edit/locationId=:locationId/brandId=:brandId/employeeId=:employeeId" element={<EditEmployee/>}/>
                    <Route path="/edit/viewonly/locationId=:locationId/brandId=:brandId/employeeId=:employeeId" element={<EditEmployeeViewOnly/>}/>
                    {/*This page currently has been hidden and it's functionality moved to EditEmployee, just commenting it out for now*/}
                    {/*<Route path="/updatebadgeid" element={<EditEmployeeBadge/>}/>*/}
                    <Route path="/import" element={<ImportEmployees/>}/>
                    {/*<Route path="/import/status/locationId=:locationId" element={<ImportEmployeesResults/>}/>*/}
                    {/*<Route path="/import/result/locationId=:locationId/portalUploadStatusId=:portalUploadStatusId" element={<EmployeeImportResult/>}/>*/}
                    <Route path="/export" element={<ExportEmployees/>}/>
                    <Route path="/permissions/view/locationId=:locationId" element={<ViewDevicePickupPermissions/>}/>
                    <Route path="/permissions/create/locationId=:locationId" element={<EditDevicePickupPermission edit={false}/>}/>
                    <Route path="/permissions/edit/devicePickupPermissionId=:devicePickupPermissionId" element={<EditDevicePickupPermission edit={true}/>}/>
                  </Routes>
               </ProtectedRoute>
              }
              />

              <Route path="/devices/*" element={
                <ProtectedRoute>
                  <Routes>
                    <Route path="/view/locationId=:locationId" element={<ViewDevices/>}/>
                    <Route path="/view/locationId=:locationId/brandId=:brandId" element={<ViewDevices/>}/>
                    <Route path="/create/locationId=:locationId" element={<CreateDevice/>}/>
                    <Route path="/create" element={<CreateDevice/>}/>
                    <Route path="/edit/locationId=:locationId/deviceId=:deviceId" element={<EditDevice/>}/>
                    <Route path="/reactivate/locationId=:locationId/deviceId=:deviceId" element={<ReactivateDevice/>}/>
                    <Route path="/deactivate/locationId=:locationId/deviceId=:deviceId" element={<DeactivateDevice/>}/>
                    <Route path="/import" element={<ImportDevices/>}/>
                    {/*<Route path="/import/status/locationId=:locationId" element={<ImportDevicesResults/>}/>*/}
                    {/*<Route path="/import/result/locationId=:locationId/portalUploadStatusId=:portalUploadStatusId" element={<DeviceImportResult/>}/>*/}
                    <Route path="/export" element={<ExportDevices/>}/>
                  </Routes>
                </ProtectedRoute>
              }
              />

              <Route path="/faq/*" element={
                <ProtectedRoute>
                    <Routes>
                        <Route path="/view/locationId=:locationId" element={<Faq/>}/>
                        <Route path="/view/locationId=:locationId/qrRequestId=:qrRequestId" element={<Faq/>}/>
                        <Route path="/view/locationId=:locationId/openKioskRole=:openKioskRole" element={<Faq/>}/>
                        <Route path="/view/locationId=:locationId/openClientPortalRole=:openClientPortalRole" element={<Faq/>}/>
                        <Route path="/emergency/keypads/view" element={<EmergencyPortalViewKeypadCodes/>}/>
                    </Routes>
                </ProtectedRoute>
              } />

              <Route path="/qr/*" element={
                  <ProtectedRoute>
                      <Routes>
                          <Route path="/create/locationId=:locationId" element={<CreateLabelRequest/>}/>
                          <Route path="/viewall/locationId=:locationId" element={<ViewLabelRequestList/>}/>
                          <Route path="/view/locationId=:locationId/qrRequestId=:qrRequestId" element={<ViewLabelRequest/>}/>
                      </Routes>
                  </ProtectedRoute>
              } />

              <Route path={"/reports/*"} element={
                  <ProtectedRoute>
                      <Routes>
                          <Route path="/view/locationId=:locationId" element={<ReportsMenu/>}/>
                          <Route path="/view/locationId=:locationId/lookerId=:lookerId" element={<ReportsMenu/>}/>
                          <Route path="/view/locationId=:locationId/lookerId=:lookerId/employeeId=:employeeId" element={<ReportsMenu/>}/>
                          <Route path="/view/locationId=:locationId/lookerId=:lookerId/deviceId=:deviceId" element={<ReportsMenu/>}/>
                      </Routes>
                  </ProtectedRoute>
              }>

              </Route>

              <Route path="/unauthorized" element={<UnauthorizedPage/>}/>

              <Route path="*" element={<NotFoundPage />}/>

              <Route path="/emergency/login" element={<LoginPage navToEmergency={true}/>}/>

              <Route path="/design/demo" element={<DesignSystemDemo/>}/>

            </Routes>
          </Router>
      </>
  );
}

export default App;
