import * as React from 'react';
import {styled} from "@material-ui/core/styles";
import {Box, Link, Menu, MenuItem} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {appContextActions, appContextSelector, logout} from "../../../app-context/appContextSlice";
import {useCurrentUserContext} from "../../../app-context/CurrentUserContextContainer";
import {getLocalizedString} from "../../../../util/Localization";
import "./UserProfile.scss"

type UserProfilePropsType = {
    firstname: string | undefined;
    lastname: string | undefined;
}

export const UserProfile:React.ComponentType<UserProfilePropsType> = (props) => {
    const navigate = useNavigate();
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    const currentUserContext = useCurrentUserContext();

    const [anchorUser, setAnchorUser] = React.useState<null | HTMLElement>(null);

    const initials = React.useMemo(() => {
        if(currentUserContext.groups.isSSOHQUser) {
            return 'HQ';
        } else if (props.firstname && props.lastname) {
            return props.firstname.charAt(0) + props.lastname.charAt(0);
        } else {
            return 'acc';
        }
    }, [currentUserContext.groups.isSSOHQUser, props.firstname, props.lastname]);

    const name = React.useMemo(() => {
        if(currentUserContext.groups.isSSOHQUser) {
            return props.lastname;
        } else if (props.firstname && props.lastname) {
            return props.firstname + ' ' + props.lastname;
        } else {
            return 'acc';
        }
    }, [currentUserContext.groups.isSSOHQUser, props.firstname, props.lastname]);


    const handleOpenUserMenu = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorUser(event.currentTarget);
    }, []);

    const handleCloseUserMenu = React.useCallback(() => {
        setAnchorUser(null);
    }, []);

    const unauth = React.useCallback(async () => {
        await dispatch(appContextActions.unauth());
        dispatch(logout());
        navigate('/login');
    }, [dispatch, navigate]);

    const showChangePassword = React.useMemo(() => {
        return !(context.authorizationDto?.employee && context.currentBrand?.employeeAPIEnabled);
    }, [context.authorizationDto?.employee, context.currentBrand?.employeeAPIEnabled]);

    return (
            <Box style={{ flexGrow: 0 }}>
                <Link onClick={() => {}} style={{textDecoration: 'none', cursor: 'pointer', display: 'inline-table'}}>
                    <div className={"o-header__circle o-heading--l"} onClick={handleOpenUserMenu}>
                        {initials}
                    </div>
                </Link>
                <Menu
                    style={{ marginTop: '45px', width:'45%' }}
                    id="menu-appbar"
                    anchorEl={anchorUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorUser)}
                    onClose={handleCloseUserMenu}
                >
                    <div className={'user-full-name'}>{name}</div>

                    {context.authorizationDto?.authorizedBrands && context.authorizationDto.authorizedBrands.length > 1 &&
                    <ProfileMenuItem onClick={() => {
                        navigate('/changebrand');
                    }}>
                        <div className={'o-body-text'}>{getLocalizedString('profile.changeBrand', 'Change Brand')}</div>
                    </ProfileMenuItem>
                    }

                    {showChangePassword &&
                    <ProfileMenuItem onClick={() => {
                        navigate('/changepassword')
                    }}>
                        <div className={'o-body-text'}>{getLocalizedString('profile.changePassword', 'Change Password')}</div>
                    </ProfileMenuItem>
                    }

                    <ProfileMenuItem onClick={unauth}>
                        <div className={'o-body-text'}>{getLocalizedString('profile.logout', 'Logout')}</div>
                    </ProfileMenuItem>
                </Menu>
            </Box>
    )
}

const ProfileMenuItem = styled(MenuItem)({
    height: '20vh',
    minHeight: '20px',
    maxHeight: '30px'
})
