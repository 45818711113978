import React from "react";
import {InfiniteScrollList, InfiniteScrollProps} from "./InfiniteScroll";
import {VariableSizeList as List} from "react-window";

interface SectionalDropdownProps {
    headerColor: string | undefined,
    dropdownTitle: string | undefined,
    sectionDtos: SectionDto[] | undefined
}

export type SectionDto = {
    header: string | undefined,
    headerDescription: string | undefined,
    bulletPoints?: string[] | undefined
}

export const SectionalDropdown: React.FC<SectionalDropdownProps> = ({
    headerColor,
    dropdownTitle,
    sectionDtos
}) => {

    const [isOpen, setIsOpen] = React.useState(false);
    
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    
    const RenderListItem: InfiniteScrollProps<string>['renderItem'] = React.useCallback((bullet, index) => {

        return (
            bullet &&
            <ul className="bullet-list">
                <li key={index}>{bullet}</li>
            </ul>
        )
    }, [])
    
    const listRef = React.useRef<List | null>(null);
    
    const handleItemsRendered = () => {
    }
    
    const bulletDivDynamicHeight = (bulletPoints: string[]) => {
        if (bulletPoints) {
            return bulletPoints.length * 20 + 'px'
        } else {
            return '0px'
        }
    }
    
    return (
        <div className={`sectional-dropdown ${isOpen ? 'open' : ''}`}>
            <div className="dropdown-header" onClick={toggleDropdown} style={{backgroundColor: headerColor ?? '#C5F6E2'}}>
                {dropdownTitle}
                <span className={`arrow ${isOpen ? 'up' : 'down'}`}>&#9660;</span>
            </div>
            <div className="dropdown-content">
                {sectionDtos?.map((section, index) => (
                section.bulletPoints && section.bulletPoints.length > 0 && 
                <div className="dropdown-section" key={index}>
                    <div className="section-title">{section.header}</div>
                    <div className="section-description">{section.headerDescription}</div>
                    <div className={"bullet-div"} style={{height: bulletDivDynamicHeight(section.bulletPoints)}}>
                        <InfiniteScrollList<string>
                            initialHeight={20}
                            data={section.bulletPoints}
                            renderItem={RenderListItem}
                            padding={0}
                            onItemsRendered={handleItemsRendered}
                            listRef={listRef}
                        />
                    </div>
                </div>
                ))}
            </div>
        </div>
    );
}
