import React, { useState } from 'react';
import './css/TabMenu.css';

interface TabData {
    id: string;
    label: string;
    labelContent?: React.ReactNode;
    content: React.ReactNode;
}

interface TabMenuProps {
    tabs: TabData[];
}

const TabMenu: React.FC<TabMenuProps> = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <div>
            <div className="tab-list">
                {tabs.map((tab, index) => (
                    <div
                        id={tab.id}
                        key={index}
                        className={`tab ${activeTab === index ? 'active' : ''}`}
                        onClick={() => setActiveTab(index)}
                    >
                        {tab.label}
                        {tab.labelContent}
                    </div>
                ))}
            </div>
            <div className="tab-content">
                {tabs[activeTab] &&
                <div>
                    {tabs[activeTab].content}
                </div>
                }
            </div>
        </div>
    );
}

export default TabMenu;
