import * as React from 'react';
import {CURRENT_VERSION} from "../../../@types/App_Versioning";
import {useLocation, useNavigate} from "react-router-dom";
import "../../../css/Footer.css"
import {getLocalizedString} from "../../../util/Localization";

export const ARCFooter = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const isCurrentPath = React.useCallback((linkPath: string) => {
        return location.pathname.includes(linkPath);
    }, [location.pathname]);

    return (
        <footer className={'o-footer'}>
            <section className={'o-footer__header'}>
                <div className={'o-footer__header__container l-wrap l-container--lg'}>
                    <p className={'o-footer__header-text'}>
                        {getLocalizedString('footer.copyright', '© ChargeItSpot') + ' ' + new Date().getFullYear()}
                    </p>

                    <p className={'o-footer__header-text'}>
                        {CURRENT_VERSION}
                    </p>
                </div>
            </section>

            <section className={'o-footer__content u-padding--md--top u-padding--md--bottom'}>
                <div className={'o-footer__content__container'}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="" className={`o-footer__nav-text ${isCurrentPath('/privacy') ? 'active' : ''}`}
                         onClick={() => navigate(isCurrentPath('emergency') ? '/privacy/emergency' : '/privacy/clientportal')}>
                        {getLocalizedString('privacy.title', 'Privacy Policy')}
                    </a>

                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="" className={`o-footer__nav-text ${isCurrentPath('/terms') ? 'active' : ''}`}
                         onClick={() => navigate(isCurrentPath('emergency') ? '/terms/emergency' : '/terms/clientportal')}>
                        {getLocalizedString('terms.title', 'Terms & Conditions')}
                    </a>
                </div>
            </section>
        </footer>
    )
};
