import {ARCIcon, ARCIconComponentType, ARCIcons, isArcIcon} from "../ARCIcon";
import * as React from "react";
import {Option} from "../../ColorSelect";
import {SvgIconComponent} from "@material-ui/icons";
import "./ARCDropdownLink.css"

type ARCDropdownLinkType = {
    label: string,
    disabled? : boolean,
    icon?: ARCIcons,
    options: Option[],
    optionsHeader?: string, 
    onChange: (option: Option) => void,
    id?: string
}

export const ARCDropdownLink : React.FC<ARCDropdownLinkType> = (props) => {
    const [isOpen, setIsOpen] = React.useState(false);
    
    const optionIcon = React.useCallback((option: Option) => {
        const icon = option.icon;

        if(icon) {
            if (isArcIcon(icon)) {
                const arcIcon = icon as ARCIconComponentType;

                if (arcIcon.defaultProps?.icon) {
                    return <ARCIcon icon={arcIcon.defaultProps?.icon} size={'sm'}/>
                }
            } else {
                return icon as SvgIconComponent;
            }
        }
    }, []);
    const handleOptionClick = (option : Option) => {
        setIsOpen(false);
        props.onChange(option);
    };
    
    const selectRef = React.useRef<HTMLDivElement>(null);
    const handleClickOutside = (event : MouseEvent) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    React.useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);
    
    return (
        <div 
            className={`o-dropdownlink ${props.disabled ? 'o-dropdownlink--disabled' : ''}` } 
            id={props.id} 
            onClick={() => {
                if(!props.disabled) {
                    setIsOpen(!isOpen);
                }
            }}
            ref={selectRef}
        >
            {props.icon && <ARCIcon icon={props.icon} size={'sm'}/>}
            {props.label}
            
            <div className={`o-dropdown__menu ${isOpen ? 'active' : ''}`}>
                {props.optionsHeader && 
                    <div className={'o-dropdown__option__container header'}>
                        <div className="o-dropdown__option header">
                            {props.optionsHeader}
                        </div>
                    </div>
                }
                {!!props.options &&
                    <div className={'o-dropdown__option__container'}>
                        {props.options.map((option, idx) => (
                            <div
                                key={idx}
                                className="o-dropdown__option"
                                onClick={() => handleOptionClick(option)}
                            >
                                {optionIcon(option) && (
                                    optionIcon(option)
                                )}
                                {option.label}
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}

export type ARCDropdownLinkComponentType = React.FC<ARCDropdownLinkType>;
