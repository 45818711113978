import {combineReducers, configureStore } from '@reduxjs/toolkit';
/* REDUCERS */
import {appContextSlice} from '../features/app-context/appContextSlice';
import {env} from "../dtos/API_DOMAINS";
/* CUSTOM MIDDLEWARES */
import {logger} from "./middlewares/logger";

/* PERSISTENCE | MIGRATIONS */
import {persistReducer} from 'redux-persist';
import {persistConfig, skipPersistenceSerializableChecks} from "./persistence/config";
import {employeeSlice} from "../features/employees/employeeSlice";
import {employeeGroupSlice} from "../features/employees/employeeGroupSlice";
import {deviceSlice} from "../features/devices/deviceSlice";
import {deviceTypeSlice} from "../features/devices/deviceTypeSlice";
import { reportSlice } from '../features/reports/reportSlice';
import {labelRequestSlice} from "../features/label-request/labelRequestSlice";
import {devicePickupPermissionSlice} from "../features/devices/devicePickupPermissionSlice";
import {emergencyPortalSlice} from "../features/emergency-portal/emergencyPortalSlice";

/* REDUCERS */
const combinedReducers = combineReducers({
    [appContextSlice.name]: appContextSlice.reducer,
    [employeeSlice.name]: employeeSlice.reducer,
    [employeeGroupSlice.name]: employeeGroupSlice.reducer,
    [deviceSlice.name]: deviceSlice.reducer,
    [deviceTypeSlice.name]: deviceTypeSlice.reducer,
    [devicePickupPermissionSlice.name]: devicePickupPermissionSlice.reducer,
    [reportSlice.name]: reportSlice.reducer,
    [labelRequestSlice.name]: labelRequestSlice.reducer,
    [emergencyPortalSlice.name] : emergencyPortalSlice.reducer
});
const reducersWithPersistence = persistReducer(persistConfig, combinedReducers);

/* BUILD STORE */
export const store = configureStore({
    reducer: reducersWithPersistence,
    middleware: (getDefaultMiddleware => getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [ ...skipPersistenceSerializableChecks ],
            },
            thunk: {
                extraArgument: {
                    // api: 'test' // clean up testing
                }
            }
        })
        .concat(logger)
    ),
    devTools: (env !== 'production')
});

