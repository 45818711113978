import * as React from 'react';
import {passwordHasLetter, passwordHasNumber, passwordMeetsLength} from "../../util/PasswordUtil";
import {CheckList} from "./CheckList";
import {getLocalizedString} from "../../util/Localization";

type PasswordChecklistProps = {
    password: string
}

export const PasswordChecklist:React.ComponentType<PasswordChecklistProps> = (props) => {
    const passwordRequirementsChecklist = React.useMemo(() => {
        return [
            {
                label: getLocalizedString('password.number', 'Have at least 1 number'),
                matchesCriteria: passwordHasNumber(props.password)
            },
            {
                label: getLocalizedString('password.capitalLetter', 'Have at least 1 capital letter'),
                matchesCriteria: passwordHasLetter(props.password)
            },
            {
                label: getLocalizedString('password.length', 'Be at least 8 characters'),
                matchesCriteria: passwordMeetsLength(props.password)
            }
        ]
    }, [props.password]);

    return (
        <>
            <div className={'reset-help-text'} style={{textAlign: 'start'}}>
                {getLocalizedString('password.requirements', 'Password Must:')}
            </div>

            <CheckList items={passwordRequirementsChecklist}/>
        </>
    )

}