import * as React from 'react';
import {useAppSelector} from "../../store/hooks";
import {brandViewSelector, isEmployeeSelector, tokenIssueTimeSelector} from "./appContextSlice";
import {
    EMERGENCY_TOKEN_EXPIRED_TIME,
    MINUTE_MS,
    TOKEN_EXPIRED_TIME, TOKEN_EXPIRED_TIME_ADMIN,
    TOKEN_EXPIRING_WINDOW,
    TOKEN_EXPIRING_WINDOW_ADMIN,
    useInterval
} from "../../util/TimeUtils";
import {emergencyPortalTokenIssueTimeSelector} from "../emergency-portal/emergencyPortalSlice";

type AppViewContextType = {
    brandView: boolean;
    tokenExpiring: boolean;
    tokenExpired: boolean;
    emergencyTokenExpired: boolean;
}
const initialState : AppViewContextType = {
    brandView: false,
    tokenExpiring: false,
    tokenExpired: false,
    emergencyTokenExpired: false
}
export const AppViewContext = React.createContext(initialState);

export const useAppViewContext = () => React.useContext(AppViewContext);

export const AppViewContextContainer:React.ComponentType = (props) => {
    const isBrandView = useAppSelector(brandViewSelector());
    const tokenIssueTime = useAppSelector(tokenIssueTimeSelector);
    const emergencyTokenIssueTime = useAppSelector(emergencyPortalTokenIssueTimeSelector);
    const isEmployee = useAppSelector(isEmployeeSelector);

    const [tokenExpiring, setTokenExpiring] = React.useState<boolean>(false);
    const [tokenExpired, setTokenExpired] = React.useState<boolean>(false);
    const [emergencyTokenExpired, setEmergencyTokenExpired] = React.useState<boolean>(false);

    const checkTokens = React.useCallback(() => {
        const current_time = Date.now();

        if(tokenIssueTime) {
            setTokenExpiring(current_time - tokenIssueTime > (isEmployee ? TOKEN_EXPIRING_WINDOW : TOKEN_EXPIRING_WINDOW_ADMIN));
            setTokenExpired(current_time - tokenIssueTime > (isEmployee ? TOKEN_EXPIRED_TIME : TOKEN_EXPIRED_TIME_ADMIN));
        } else {
            setTokenExpiring(false);
            setTokenExpired(false);
        }

        if(emergencyTokenIssueTime) {
            setEmergencyTokenExpired(current_time - emergencyTokenIssueTime > EMERGENCY_TOKEN_EXPIRED_TIME);
        } else {
            setEmergencyTokenExpired(false);
        }

    }, [emergencyTokenIssueTime, isEmployee, tokenIssueTime]);

    useInterval(checkTokens, MINUTE_MS / 6);

    return(
        <AppViewContext.Provider value={{
            brandView: isBrandView,
            tokenExpiring: tokenExpiring,
            tokenExpired: tokenExpired,
            emergencyTokenExpired: emergencyTokenExpired
        }}>
            {props.children}
        </AppViewContext.Provider>
    )
}