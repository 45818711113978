import * as React from 'react';
import {
    ARC_TECH_SUPPORT,
    PORTAL_ADMIN, PORTAL_SUPER_ADMIN, SAMS_SSO_HQ_USER, WMCA_SSO_HQ_USER
} from "./containers/UserPermissions";
import {useAppSelector} from "../../store/hooks";
import {
    userAccessLevelSelector,
    employeePermissionSelector,
    userPermissionSelector,
    userPermissionSelectorGroup
} from "./appContextSlice";

type CurrentUserContextType = {
    groups: {
        isPortalAdmin: boolean;
        isPortalSuperAdmin: boolean;
        isArcTechSupport: boolean;
        isSSOHQUser: boolean;
    },
    accessLevel: number;
}
const initialState : CurrentUserContextType = {
    groups: {
        isPortalAdmin: false,
        isPortalSuperAdmin: false,
        isArcTechSupport: false,
        isSSOHQUser: false
    },
    accessLevel: 0
}
export const CurrentUserContext = React.createContext(initialState);

export const useCurrentUserContext = () => React.useContext(CurrentUserContext);

export const CurrentUserContainer:React.ComponentType = (props) => {
    const isPortalAdmin = useAppSelector(employeePermissionSelector(PORTAL_ADMIN));
    const isPortalSuperAdmin = useAppSelector(employeePermissionSelector(PORTAL_SUPER_ADMIN));
    const isArcTechSupport = useAppSelector(userPermissionSelector(ARC_TECH_SUPPORT));
    const isSSOHQUser = useAppSelector(userPermissionSelectorGroup([SAMS_SSO_HQ_USER, WMCA_SSO_HQ_USER]));

    const accessLevel = useAppSelector(userAccessLevelSelector());

    return(
        <CurrentUserContext.Provider value={{
            groups: {
                isPortalAdmin: isPortalAdmin,
                isPortalSuperAdmin: isPortalSuperAdmin,
                isArcTechSupport: isArcTechSupport,
                isSSOHQUser: isSSOHQUser
            },
            accessLevel: accessLevel
        }}>
            {props.children}
         </CurrentUserContext.Provider>
    )
}