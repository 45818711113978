import * as React from 'react';
import {ListProps, VariableSizeList, VariableSizeList as List} from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import '../../css/index.css';

interface ItemHeightContextProps {
    onHeightChange: (index: number, height: number) => void;
}

const ItemHeightContext = React.createContext<ItemHeightContextProps | undefined>(undefined);

export interface InfiniteScrollProps<DataType> {
    data: DataType[] | undefined;
    renderItem: (
        item: DataType | undefined,
        index: number
    ) => React.ReactNode;
    initialHeight?: number;
    padding?: number;
    onItemsRendered: (event: any) => void;
    onScroll?: (event: any) => void;
    listRef?: React.MutableRefObject<VariableSizeList<any> | null>;
}

export const InfiniteScrollList = <DataType,>({
  data,
  renderItem,
  initialHeight = 120,
  padding = 8,
  onItemsRendered,
  onScroll,
  listRef
}: InfiniteScrollProps<DataType>) => {
    const [heights, setHeights] = React.useState<{ [index: number]: number }>({});

    const onHeightChange = React.useCallback((index: number, height: number) => {
        if (heights[index] !== height) {
            setHeights((prevHeights) => ({ ...prevHeights, [index]: height }));
            listRef?.current?.resetAfterIndex(index);
        }
    }, [heights, listRef]);

    const getItemSize = React.useCallback((index: number) => {
        return (heights[index] ?? heights[0] ?? initialHeight) + padding;
    }, [heights, initialHeight, padding]);

    const RenderListItem: ListProps['children'] = React.useCallback(
        ({ index, style }) => {
            const item = data?.[index];
            return (
                <div style={{ ...style, paddingLeft: padding, paddingRight: padding, marginTop: padding, marginBottom: padding}}>
                    {renderItem(item, index)}
                </div>
            );
        },
        [data, padding, renderItem]
    );

    const AutoSizeRender = React.useCallback(({ height, width } : { height:number, width:number }) => {
        // console.log('AutoSizeRender', {height, width });
        return (
            <List
                ref={listRef}
                className={'variable-sized-list'}
                width={width}
                height={height}
                itemCount={data?.length || 0}
                itemSize={getItemSize}
                onItemsRendered={onItemsRendered}
                onScroll={onScroll}
            >
                {RenderListItem}
            </List>
        )
    }, [RenderListItem, data?.length, getItemSize, listRef, onItemsRendered, onScroll]);

    return (
        <ItemHeightContext.Provider value={{ onHeightChange }}>
            <AutoSizer className={'infinite-scroll-auto-sizer'}>
                {AutoSizeRender}
            </AutoSizer>
        </ItemHeightContext.Provider>
    );
};

interface HeightAwareWrapperProps {
    index: number;
    children: React.ReactNode;
}

export const HeightAwareWrapper = ({ index, children }: HeightAwareWrapperProps) => {
    const context = React.useContext(ItemHeightContext);
    const ref = React.useRef<HTMLDivElement>(null);

    if (!context) {
        throw new Error('HeightAwareWrapper must be used within ItemHeightContext');
    }

    const { onHeightChange } = context;

    React.useEffect(() => {
        // console.log('HeightAwareWrapper', {r: ref?.current, h: ref?.current?.getBoundingClientRect().height});
        if (ref.current) {
            const newHeight = ref.current.getBoundingClientRect().height;
            onHeightChange(index, newHeight);
        }
    }, [index, children, onHeightChange]);

    return <div ref={ref}>{children}</div>;
};

