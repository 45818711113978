import { styled } from "@material-ui/core/styles";
import {Button, Container, Grid, FormControl, InputLabel, Link, Table, TextField, Typography} from "@material-ui/core";
import {BLACK, GREEN, DARK_GREY, BLUE, WHITE, LIGHT_RED} from "../../themes/colors";
import {
    calculatedFontSize,
    calculatedSizeMinBasedOnWidthHeightScaler,
    calculateResponsiveSizeWithMinMax
} from "../../util/TextUtils";
import {
    Check,
    Close,
    HelpOutlineRounded, IndeterminateCheckBoxRounded
} from "@material-ui/icons";

export const LogoDiv = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '15vh'
});

export const MainContentContainer = styled(Container)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    float: 'left',
    width: '84vw',
    margin: '2vh 6vw 0 6vw',
    minWidth: 'unset',
    maxWidth: 'unset'
}));

export const SuccessMessageSection = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: '#C5F6E2',
    marginBottom: '10px',
    padding: '16px',
    borderTop: '2px solid #00975B',
    borderBottom: '2px solid #00975B'
});

export const SuccessMessageSectionItem = styled(Typography)({
    width: '100%',
    fontWeight: 'bold',
    fontSize: calculatedFontSize('1em', '1.2em'),
    color: DARK_GREY
});

export const ErrorMessageSection = styled(Typography)({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: '#FFE4A7',
    marginBottom: '10px',
    padding: '16px',
    borderTop: '2px solid #FDBE31',
    borderBottom: '2px solid #FDBE31'
});

export const ErrorMessageSectionItem = styled(Typography)({
    width: '100%',
    fontWeight: 'bold',
    fontSize: calculatedFontSize('1em', '1.2em'),
    color: DARK_GREY
});

export const SuccessMessageText = styled(Typography)({
    width: '100%',
    fontWeight: 600,
    fontSize: calculatedFontSize('1em', '1.2em'),
    color: GREEN,
    marginBottom: '10px'
});

export const ResponsiveInputLabelThin = styled(InputLabel) (({theme}) => ({
    display: 'flex',
    minWidth: '200px',
    fontSize: calculatedFontSize('1.2em', '1.25em'),
    [theme.breakpoints.down('xs')]: {
        minWidth: '125px',
        maxWidth: '125px',
        fontSize: calculatedFontSize('.9em', '1.25em')
    },
    position: "initial",
    fontWeight: 550,
    color: BLACK
}));

export const ResponsiveInputLabel = styled(InputLabel) (({theme}) => ({
    display: 'flex',
    minWidth: '250px',
    fontSize: calculatedFontSize('1.2em', '1.25em'),
        [theme.breakpoints.down('xs')]: {
        minWidth: '125px',
        maxWidth: '125px',
        fontSize: calculatedFontSize('.9em', '1.25em')
    },
    position: "initial",
    fontWeight: 550,
    color: BLACK
}));

export const ResponsiveTextField =  styled(TextField) (({theme}) => ({
    margin: '2vh 0 0 1vh',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '11px',
    width: '30vw',
    maxWidth: '450px',
    minWidth: '150px',
    [theme.breakpoints.down('md')]: {
        width: '80vw',
        maxWidth: '450px',
        minWidth: '150px'
    },
    paddingLeft: '10px'
}));

export const UndeterminedCheckboxIcon = styled(IndeterminateCheckBoxRounded)(({theme}) => ({
    color: theme.palette.primary.main,
    backgroundColor: WHITE,
    border: 'none'
}));

export const LoginButton = styled(Button) (({theme}) => ({
    maxHeight: '60px',
    borderRadius: '30px',
    borderWidth: '0',
    maxWidth: '100% !important',
    font: 'normal normal 550 0 "Proxima Nova"',
    fontSize: calculatedSizeMinBasedOnWidthHeightScaler('2.25'),
    color: '#000000',
    background: '#FBBC41',
    letterSpacing: '0',
    width: '100%',
    padding: '2% 0 2% 0 !important',
    minWidth: '0px !important',
    height: '100%',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FBBC41',
      opacity: '.5'
    }
}));

export const ActionButton = styled(Button) (({theme}) => ({
    color: 'white',
    padding: '20px',
    margin: '15px 0px 45px 0px',
    width: 'fit-content',
    maxWidth: '250px',
    fontSize: calculatedFontSize('1.15em', '1.3em'),
    height: '7%',
    minHeight: '30px',
    maxHeight: '50px',
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '10px',
    textTransform: 'none'
}));


export const HiddenOnMobileDiv = styled('div')(({theme}) => ({
    [theme.breakpoints.down('xs')]: {
        display: 'none'
    },
}));

export const CancelButton = styled(Button)(({theme}) => ({
    color: 'white',
    width: '15vw',
    minWidth: '120px',
    maxWidth: '200px',
    fontSize: calculatedFontSize('.75em', '1.4em'),
    height: '10vh',
    maxHeight: '55px',
    minHeight: '40px',
    backgroundColor: DARK_GREY,
    borderRadius: '49px',
    marginLeft: '40px',
    textTransform: 'none',
    [`${theme.breakpoints.down('md')}`]: {
        marginLeft: '7vw',
        minHeight: '30px',
        height: '5vh',
        minWidth: '100px',
    }
}));

export const SaveButton = styled(Button) (({theme}) => ({
    color: 'white',
    width: '15vw',
    minWidth: '120px',
    maxWidth: '200px',
    fontSize: calculatedFontSize('.75em', '1.4em'),
    height: '10vh',
    maxHeight: '55px',
    minHeight: '40px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '49px',
    marginLeft: '40px',
    textTransform: 'none',
    [`${theme.breakpoints.down('md')}`]: {
        marginLeft: '7vw',
        minHeight: '30px',
        height: '5vh',
        minWidth: '100px',
    }
}));

export const EditButton = styled(Button) (({theme}) => ({
    color: WHITE,
    width: '15vw',
    minWidth: '175px',
    maxWidth: '200px',
    fontSize: calculatedFontSize('.75em', '1.3em'),
    height: '7vh',
    maxHeight: '45px',
    minHeight: '30px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px',
    marginTop: '10px',
    textTransform: 'none',
    [`${theme.breakpoints.down('md')}`]: {
        minHeight: '30px',
        height: '5vh',
    }
}));

export const EditButtonSm = styled(EditButton) (({theme}) => ({
    width: '5vw',
    minWidth: '50px',
    maxWidth: '80px',
    height: '5vh',
    maxHeight: '35px',
    minHeight: '30px',
    fontSize: calculatedFontSize('.75em', '1.1em'),
    fontWeight: 'bold',
    marginTop: '0px',
    borderRadius: '5px'
}));

export const CircleLabelMd = styled(Typography) ({
    border: '2px solid',
    width: calculateResponsiveSizeWithMinMax('3.5', '35', '8'),
    height: calculateResponsiveSizeWithMinMax('3.5', '35', '8'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: BLACK,
    borderRadius: '50px',
    fontSize: calculateResponsiveSizeWithMinMax('1.5', '35', '8'),
    fontWeight: 500
});

export const Spacer = styled(Typography)({
    marginTop: '7vh'
});

export const SmallBr = styled(Typography)({
    marginTop: '2vh'
})

export const XSmallSpacer = styled(Typography)({
    marginTop: '1vh'
})

export const CenteredDiv = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
}));

export const ScalableTable = styled(Table)(({theme}) => ({
    [`${theme.breakpoints.down('md')}`]: {
        tableLayout: 'fixed'
    }
}));

export const ScalableGrid = styled(Grid)(({theme}) => ({
    [`${theme.breakpoints.down('md')}`]: {
        justifyContent: 'center'
    }
}));

export const ScalableForm = styled('form')(({theme}) => ({
    width: '100%',
    [`${theme.breakpoints.down('md')}`]: {
        justifyContent: 'center',
    },
    [`${theme.breakpoints.down('xs')}`]: {
        width: '60vw'
    }
}));

export const ScalableFormControl = styled(FormControl)(({theme}) => ({
    width: '100%',
    [`${theme.breakpoints.down('md')}`]: {
        justifyContent: 'center',
    },
    [`${theme.breakpoints.down('xs')}`]: {
        width: 'unset'
    }
}));

export const FormTitle = styled(Typography)(({theme}) => ({
    width: '100%',
    fontSize: calculateResponsiveSizeWithMinMax('3', '32', '24'),
    color: theme.palette.primary.main,
    fontWeight: 550,
    paddingBottom: '15px'
}));

export const ColoredLink = styled(Link) ({
    cursor: 'pointer',
    color: BLUE,
    fontSize: calculateResponsiveSizeWithMinMax('2', '18', '16'),
});

export const ColorHelpIcon = styled(HelpOutlineRounded) (({theme}) => ({
    color: theme.palette.primary.main,
    marginRight: '5px'
}));

const cancelDimensions = 20;

export const CancelIconContainer = styled('div') ({
    width: `${cancelDimensions}px`,
    height: `${cancelDimensions}px`
})

export const SectionHeaderLg = styled(Typography)({
    fontSize: calculatedFontSize('1.4em', '1.6em'),
    fontWeight: 550,
    marginTop: '4vh',
    marginBottom: '.5vh'
});

export const InputDiv = styled('div') ({
    width: '30vw',
    display: 'flex',
    maxWidth: '450px',
    minWidth: '150px',
    marginLeft: '10px',
    "@media (max-width:1280px)": {
        width: '80vw',
    }
});

export const RowFormControl = styled(FormControl) ({
    display: 'flex',
    flexDirection: 'row'
});

export const InputViewOnlyValue = styled(Typography) ({
    margin: '2vh 0 0 1vh',
    width: '30vw',
    maxWidth: '300px',
    minWidth: '150px',
    "@media (max-width:1280px)": {
        width: '80vw',
        maxWidth: '300px',
        minWidth: '150px'
    },
    paddingLeft: '10px'
})

export const CheckGoodIcon = styled(Check) ({
    color: GREEN,
    marginRight: '10px',
    fontSize: calculatedSizeMinBasedOnWidthHeightScaler('1.75')
});

export const CheckBadIcon = styled(Close) ({
    color: LIGHT_RED,
    marginRight: '10px',
    fontSize: calculatedSizeMinBasedOnWidthHeightScaler('1.75')
})