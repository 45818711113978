import * as React from "react";
import {DialogAlert} from "../../ui-components/DialogAlert";
import {getLocalizedString} from "../../../util/Localization";

type DeviceDialogProps = {
    showDialog: boolean,
    dialogSubmit: () => void,
    dialogCancel: (event: any) => void,
    isError: boolean,
    title: string | React.ReactNode,
    messages: string[]
}

export const DeviceDialog:React.ComponentType<DeviceDialogProps> = (props) => {
    const {showDialog, 
            dialogSubmit,
            dialogCancel,
            isError,
            title,
            messages,} = props
    if (isError) {
        return (
            <DialogAlert
                title={title}
                messages={messages}
                isOpen={showDialog}
                isError={true}
            >
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
                        <button className={'dialog-alert-button confirm'} onClick={dialogCancel}>
                            {getLocalizedString('ok', 'OK')}
                        </button>
                    </div>
                </div>
            </DialogAlert>
        )
    } else {
        return (
            <DialogAlert
                title={title}
                messages={messages}
                isOpen={showDialog}
                isError={false}
            >
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <button className={'dialog-alert-button confirm'} onClick={dialogSubmit}>
                            {getLocalizedString('ok', 'OK')}
                        </button>
                        <div className={'dialog-alert-button cancel'} onClick={dialogCancel}>
                            {getLocalizedString('cancel', 'Cancel')}
                        </div>
                    </div>
                </div>
            </DialogAlert>
        )
    }
}
