import * as React from 'react';
import {Dialog} from "@material-ui/core";
import './css/DialogAlert.css';

type DialogAlertProps = {
    title?: string | React.ReactNode,
    messages: string[],
    isOpen: boolean,
    isError?: boolean,
    confirmButton?: {
        id: string,
        label: string,
        onClick: (...arg: any) => void
    },
    closeButton?: {
        id: string,
        label: string,
        onClick: (...arg: any) => void
    }
}

export const DialogAlert:React.FC<DialogAlertProps> = 
                    ({title, 
                       messages, 
                       isOpen, 
                       confirmButton, 
                       closeButton, 
                       children,
                       isError}) => {
    return (
        <Dialog open={isOpen} >

            <div className={'dialog-alert'}>
                {title &&
                    <div className={!isError ? 'dialog-alert-title' : 'dialog-alert-title-error'}>
                        {title}
                    </div>
                }

                {messages.map((message, idx) =>
                    <div className={'dialog-alert-message'} key={idx}>
                        {message}
                    </div>
                )}

                {children}

                <div style={{display: 'flex', justifyContent: 'space-around', width: '50%'}}>
                    {confirmButton &&
                    <div id={confirmButton.id} className={'dialog-alert-button confirm'} onClick={confirmButton.onClick}>
                        {confirmButton.label}
                    </div>
                    }

                    {closeButton &&
                        <div id={closeButton.id} className={'dialog-alert-button cancel'} onClick={closeButton.onClick}>
                            {closeButton.label}
                        </div>
                     }
                </div>

            </div>
        </Dialog>
    )
}
