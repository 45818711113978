import * as React from 'react';
import {BLACK, GREY} from "../../themes/colors";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    styled,
    Typography
} from "@material-ui/core";
import { ActionButton } from './StyledComponents';
import {calculatedFontSize} from "../../util/TextUtils";
import {getLocalizedString} from "../../util/Localization";

type InactiveDialogProps = {
    showDialog: boolean,
    onClose: () => void,
    onConfirm: () => void
}

export const InactiveDialog:React.ComponentType<InactiveDialogProps> = ({showDialog, onClose, onConfirm}) => {

    return (
        <InactiveDialogBox style={{color: GREY}} open={showDialog}>
            <InactiveDialogTitle id="alert-dialog-title">
                {getLocalizedString('inactive.title', 'Your session will time out in 2 minutes for security reasons.')}
            </InactiveDialogTitle>

            <DialogContent>
                <InactiveDialogText id="alert-dialog-description">
                    {getLocalizedString('inactive.moreTime', 'Do you need more time?')}
                </InactiveDialogText>
            </DialogContent>

            <InactiveDialogSections>
                <InactiveDialogButton onClick={onConfirm} style={{marginRight: '20px'}}>{getLocalizedString('yes', 'Yes')}</InactiveDialogButton>
                <InactiveDialogButton onClick={onClose}>{getLocalizedString('no', 'No')}</InactiveDialogButton>
            </InactiveDialogSections>
        </InactiveDialogBox>
    )
}

const InactiveDialogBox = styled(Dialog) ({
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   textAlign: 'center'
});

const InactiveDialogTitle = styled(Typography)({
    fontWeight: 550,
    padding: '16px 24px 16px 24px',
    fontSize: calculatedFontSize('2.2em', '3.2em')
});

const InactiveDialogText = styled(DialogContentText)({
    color: BLACK,
    fontSize: calculatedFontSize('1.6em', '1.8em')
});

const InactiveDialogSections = styled(DialogActions)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

const InactiveDialogButton = styled(ActionButton)({
    padding: '0.5vh 3vw 0.5vh 3vw'
})