import React, { useState, MouseEvent } from 'react';
import { Menu, MenuItem, Button } from '@material-ui/core';
import {useNavigate} from "react-router-dom";
import { ArrowDropDown } from '@material-ui/icons';
import {calculatedFontSize} from "../../util/TextUtils";

interface Option {
    name: string;
    path: string;
    disabled?: boolean;
}

interface NavigationMenuProps {
    options?: (Option | undefined)[];
    currentPage: string;
    homePath: string;
    navHomeOnClick: boolean;
    restrictMenuToHomePath?: boolean;
    label: string;
    domain: string;
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({ options, currentPage, homePath, navHomeOnClick, restrictMenuToHomePath, label, domain}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        if(restrictMenuToHomePath) {
            if(currentPage.includes(homePath)) {
                setAnchorEl(event.currentTarget)
            } else {
                navigate(homePath);
            }
        }
        else if (currentPage.includes(domain)) {
            setAnchorEl(event.currentTarget);
        } else if(navHomeOnClick) {
            navigate(homePath);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = (option?: string) => {
        setAnchorEl(null);

        if(option) {
            if(option === currentPage) {
                window.location.reload();
            } else {
                navigate(option);
            }
        }
    };

    return (
        <div>
            <Button
                onClick={(event) => handleClick(event)}
                color={currentPage.includes(domain) ? 'primary' : 'default'}
                style={{textTransform: 'none', fontSize: calculatedFontSize('.7em', '1.1em')}}
            >
                {label}

                {currentPage.includes(domain) && options &&
                    <ArrowDropDown/>
                }
            </Button>

            {options &&
            <Menu
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleClose()}
            >
                {options.map((option) => (
                    option &&
                    <MenuItem key={option.name} disabled={option.disabled} onClick={() => handleClose(option.path)}>
                        {option.name}
                    </MenuItem>
                ))}
            </Menu>
            }
        </div>
    );
};

export default NavigationMenu;