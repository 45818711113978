import {BrandDto} from "./Brand";
import {DevicePickupPermissionDto} from "./DevicePickupPermission";
import {DeviceTypeDto} from "./DeviceType";
import {getLocalizedString} from "../util/Localization";

export interface LocationDto {
    locationId: number;
    locationName: string;
    shippingAddress?: string;
    brand: BrandDto;
    activeDeviceLimit: boolean;
    defaultDevicePickupPermission?: DevicePickupPermissionDto;
    managerPin?: string;
    deviceTypes?: DeviceTypeDto[];
    serialIsDevice: boolean;
}

export const AllLocationsDto:Omit<LocationDto, 'brand'> = {
    locationId: -1,
    locationName: getLocalizedString('brandView.name', 'All Locations'),
    activeDeviceLimit: false,
    serialIsDevice: false
}
