import * as React from 'react';
import './css/Toggle.css';

interface ToggleProps {
    on: boolean;
    onText: string;
    offText: string;
    className: string;
    onChange: () => void;
    id: string;
}

const Toggle: React.FC<ToggleProps> = ({ on, onText, offText, className, onChange, id }) => {
    return (
        <div className={'toggle ' + className} onClick={onChange} id={id}>
            <div className={`toggle-option ${!on ? 'active' : ''}`}>
                {offText}
            </div>

            <div className={`toggle-option ${on ? 'active' : ''}`}>
                {onText}
            </div>
        </div>
    );
}

export default Toggle;