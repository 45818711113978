import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store/types";


export const reportSliceName = 'reportSlice';

export interface ReportState {
    employeeId: number | undefined,
    deviceId: number | undefined
}

const initialState : ReportState = {
    employeeId: undefined,
    deviceId: undefined
}

export const reportSlice = createSlice({
    name: reportSliceName,
    initialState,
    reducers: {
        setEmployeeId: (state, action: PayloadAction<number>) => {
            state.employeeId = action.payload;
        },
        setDeviceId: (state, action: PayloadAction<number>) => {
            state.deviceId = action.payload;
        },
        clearState: (state) => {
            state.employeeId = undefined;
            state.deviceId = undefined;
        }
    },
    extraReducers: {}
});

export const reportContextSelector = (state: RootState) => state[reportSliceName];
export const reportContextActions = reportSlice.actions;