import * as React from 'react';
import './css/CheckList.css';
import { CheckBadIcon, CheckGoodIcon } from "./StyledComponents";

type CheckListItem = {
    label: string,
    matchesCriteria: boolean
}

type CheckListProps = {
    items: CheckListItem[]
}

export const CheckList:React.ComponentType<CheckListProps> = (props) => {

    return (
        <>
            {props.items.map(item => {
                return (
                    <div className={'row-div'}>
                        {item.matchesCriteria ? <CheckGoodIcon style={{width: '20px', height: '20px'}}/> : <CheckBadIcon style={{width: '20px', height: '20px'}}/>}

                        <div className={'checklist-help-text'}>
                            {item.label}
                        </div>
                    </div>
                )
            })
            }
        </>
    )
}