import * as React from 'react';
import {SideBar} from "../../sidebar/SideBar";
import AppBar from "../../appbar/AppBar";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {employeeContextActions, employeeContextSelector, exportEmployees} from "../employeeSlice";
import {appContextSelector} from "../../app-context/appContextSlice";
import {FormTitle, ErrorMessageSectionItem} from "../../ui-components/StyledComponents";
import {AppWithFooter} from "../../../App";
import {ServerMessage} from "../../ui-components/ServerMessage";
import {getLocalizedString} from "../../../util/Localization";
import {ARCButton} from "../../ui-components/design-system/ARCButton";
import {ARCRadioInput} from "../../ui-components/design-system/ARCRadioInput";

export const ExportEmployees = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);
    const employeeContext = useAppSelector(employeeContextSelector);

    const [activeOnly, setActiveOnly] = React.useState<boolean>(true);

    React.useEffect(() => {
        return () => {dispatch(employeeContextActions.resetExportEmployee())}
    }, [dispatch]);

    React.useEffect(() => {
        if(employeeContext.exportFile) {
            const link = document.createElement('a');
            link.href = employeeContext.exportFile;
            link.setAttribute('download', 'EmployeeExportFile.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }
    }, [employeeContext.exportFile])

    const submitForm = React.useCallback(() => {
        if(context.currentLocation?.locationId) {
            dispatch(exportEmployees({locationId: context.currentLocation.locationId, activeOnly: activeOnly}));
        }
    }, [activeOnly, context.currentLocation?.locationId, dispatch]);

    return (
        <AppWithFooter>
            <AppBar/>
            <SideBar/>
            <main className={'o-main'}>
                <div className={'l-wrap l-container--lg'}>
                    {employeeContext.exportErrorMessage &&
                        <ServerMessage variant={'error'}>
                            <ErrorMessageSectionItem>
                                {employeeContext.exportErrorMessage}
                            </ErrorMessageSectionItem>
                        </ServerMessage>}

                    <FormTitle variant='h5' className={'o-heading--xl'}>{getLocalizedString('exportEmployees.title', 'Export Users')}</FormTitle>

                    <div className={'u-display--flex u-display--flex-column u-gap-sm'}>
                        <ARCRadioInput
                            id={'export-active-only-radio'}
                            size={'lg'}
                            on={activeOnly}
                            onChange={() => setActiveOnly(true)}
                            label={getLocalizedString('exportEmployees.activeOnly', 'All active users')}
                        />

                        <ARCRadioInput
                            id={'export-all-radio'}
                            size={'lg'}
                            on={!activeOnly}
                            onChange={() => setActiveOnly(false)}
                            label={getLocalizedString('exportEmployees.allEmployees', 'All active and inactive users')}
                        />
                    </div>

                    <div className={'u-display--flex u-justify-content--flex-start u-align-items--base'} style={{marginTop: '40px'}}>
                        <ARCButton
                            id={'export-users-submit-button'}
                            fill={'filled'}
                            variant={'primary'}
                            size={'md'}
                            onClick={submitForm}
                            label={getLocalizedString('exportEmployees.submit', 'Export')}
                        >
                        </ARCButton>
                        <ARCButton
                            id={'export-users-cancel-button'}
                            fill={'outlined'}
                            variant={'secondary'}
                            size={'md'}
                            onClick={() => {navigate(`/employees/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`)}}
                            label={getLocalizedString('exportEmployees.cancel', 'Cancel')}
                        >
                        </ARCButton>
                    </div>
                </div>
            </main>
        </AppWithFooter>
    )
}
