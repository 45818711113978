import * as React from 'react';
import './ARCIdentifierInput.scss';

type ARCIdentifierInputProps = {
    id: string,
    size: 'sm' | 'md' | 'lg',
    label?: string,
    placeholder?: string,
    required: boolean,
    disabled?: boolean;
    value: string | undefined,
    onChange: (event: string) => void,
    validate?: (val: any) => string | undefined,
    className?: string,
}

export const ARCIdentifierInput:React.ComponentType<ARCIdentifierInputProps> = (props) => {

    const [inputError, setInputError] = React.useState<string | undefined>(undefined);

    const handleEditableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value);
    };

    return (
        <div className={`o-text-identifier-input__container ${props.className ? props.className : ''}`}>
            {props.label &&
            <label className={`o-text-identifier-input__label o-body-text--m`}>
                {props.label}

                {props.required &&
                <div className={'required-star'}>
                    {'*'}
                </div>
                }

            </label>
            }
            <div className={'u-display--flex'}>

                <input
                    id={props.id}
                    type={'text'}
                    disabled={props.disabled}
                    placeholder={props.placeholder ?? ''}
                    className={`o-text-identifier-input o-text-identifier-input--${props.size} ${inputError ? 'o-input__error' : ''} o-body-text--${props.size === 'lg' ? 'l' : 'm'} u-letter-space-2xs`}
                    value={props.value}
                    onChange={(event) => {
                        handleEditableChange(event)

                        if(props.validate) {
                            setInputError(props.validate(event.target.value));
                        }
                    }}
                />
            </div>
            {inputError &&
            <div className={'o-text-identifier-input__error'}>
                {inputError}
            </div>
            }


        </div>
    )
}