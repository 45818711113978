import React, {Dispatch, SetStateAction} from "react";
import {IconButton} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import "../css/ImportComponent.css"
import {FormTitle} from "../StyledComponents";
import {LoadingProgressBar} from "../LoadingProgressBar";
import {DeviceValidationDto, validateDeviceUpload} from "../../../util/DeviceFileUploadValidation";
import {useAppSelector} from "../../../store/hooks";
import {appContextSelector} from "../../app-context/appContextSlice";
import "../css/SectionalDropdown.css"
import {ImportChildComponentNames} from "./ImportUploadFile";
import {EmployeeValidationDto, validateEmployeeUpload} from "../../../util/EmployeeFileUploadValidation";
import {devicePickupPermissionContextSelector} from "../../devices/devicePickupPermissionSlice";
import {getLocalizedString} from "../../../util/Localization";
import {deviceContextSelector} from "../../devices/deviceSlice";
import {employeeContextSelector} from "../../employees/employeeSlice";
import {ARCButton} from "../design-system/ARCButton";

interface ValidatingImportProps {
    isDeviceImport: boolean,
    setCurrentChildComponent: Dispatch<SetStateAction<string>>,
    uploadFile: File | undefined,
    deviceValidationDto: DeviceValidationDto | undefined,
    setDeviceValidationDto: Dispatch<SetStateAction<DeviceValidationDto | undefined>> | undefined,
    employeeValidationDto: EmployeeValidationDto | undefined,
    setEmployeeValidationDto: Dispatch<SetStateAction<EmployeeValidationDto | undefined>> | undefined,
    getPreviousStatusAndNavigateToUploadFile: () => void
}

export const ValidatingImport: React.FC<ValidatingImportProps> = ({
    isDeviceImport,
    setCurrentChildComponent, 
    uploadFile,
    setDeviceValidationDto,
    setEmployeeValidationDto,
    getPreviousStatusAndNavigateToUploadFile
}) => {
    const [progress, setProgress] = React.useState<number>(0);
    const [isValidated, setIsValidated] = React.useState<boolean>(false);
    const [rejected, setRejected] = React.useState<boolean>(false);
    const context = useAppSelector(appContextSelector);
    const devicePermissionsContext = useAppSelector(devicePickupPermissionContextSelector);
    const deviceContext = useAppSelector(deviceContextSelector);
    const employeeContext = useAppSelector(employeeContextSelector);
    const validateUploadFile = React.useCallback(() => {
        if (context.currentLocation && context.currentBrand && uploadFile) {
            if (isDeviceImport && setDeviceValidationDto) {
                if (deviceContext.deviceUploadTemplateHeader) {
                    validateDeviceUpload(uploadFile, context.currentBrand, context.currentLocation, setProgress, deviceContext.deviceUploadTemplateHeader).then((dto) => {
                        setDeviceValidationDto(dto)
                        setIsValidated(true);
                    }).catch((reason) => {
                        setRejected(true);
                        setProgress(100);
                        console.error(reason);
                    });
                }
            } else if (setEmployeeValidationDto) {
                if (employeeContext.employeeUploadTemplateHeader) {
                    validateEmployeeUpload(uploadFile, context.currentBrand, devicePermissionsContext.permissions, setProgress, employeeContext.employeeUploadTemplateHeader).then((dto) => {
                        setEmployeeValidationDto(dto)
                        setIsValidated(true);
                    }).catch((reason) => {
                        setRejected(true);
                        setProgress(100);
                        console.error(reason);
                    });
                }
            }
        }
    }, [isDeviceImport, 
                context.currentBrand, 
                context.currentLocation, 
                uploadFile, 
                setDeviceValidationDto, 
                setEmployeeValidationDto, 
                setIsValidated, 
                devicePermissionsContext.permissions,
                deviceContext.deviceUploadTemplateHeader,
                employeeContext.employeeUploadTemplateHeader])
                
    React.useEffect(() => {
        if (progress === 0) {
            validateUploadFile()
        }
    }, [progress, validateUploadFile])

    const titleText = React.useMemo(() => {
        if (rejected) {
            return (
                <div className={'section-header'} style={{color: 'red'}}>
                    {getLocalizedString('import.validate.empty', 'The uploaded file is empty or in the wrong format. Please download and complete the template again before re-uploading.')}
                </div>

            )
        } else if (isValidated) {
            return (
                <div className={'section-header'} style={{color: 'black'}}>
                    {getLocalizedString('import.validate.complete', 'Validation complete! Please review data before finalizing changes.')}
                </div>
            )
        } else {
            return (
                <div className={'section-header'} style={{color: 'black'}}>
                    {getLocalizedString('import.validate.loading.tip', 'Once validated, ' + (isDeviceImport ? 'device ' : 'user ') + ' data can be reviewed before finalizing changes', [`${isDeviceImport ? getLocalizedString('device', 'device ').toLowerCase() : getLocalizedString('employee', 'user ').toLowerCase()}`]) + ' data can be reviewed before finalizing changes'}
                </div>
            )
        }
    }, [isDeviceImport, rejected, isValidated])
    return (
        <>
            <div className={'importuploadfile-root-div'}>
                <IconButton
                    id={`import-${isDeviceImport ? 'devices' : 'users'}-back-button`}
                    color="primary"
                    className={'back-button'}
                    aria-label="back"
                    onClick={() => {
                        getPreviousStatusAndNavigateToUploadFile()
                    }
                    }>
                    <ArrowBack className={'back-button-arrow'}/>
                </IconButton>
                <div className={'importuploadfile-container-div'}>
                    <FormTitle>
                        {getLocalizedString('import.validate.loading', 'Validating ' + (isDeviceImport ? 'Device ' : 'User ') + 'Data...', [`${isDeviceImport ? getLocalizedString('device', 'Device ') : getLocalizedString('employee', 'User ')}`])}
                    </FormTitle>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: '100px', marginBottom: '100px', width: '100%'}}>
                        {titleText}
                    </div>
                    <LoadingProgressBar progress={progress}></LoadingProgressBar>
                    <div style={{display: 'flex', marginTop: '32px'}}>
                        {!rejected ?
                            <ARCButton
                                id={`import-${isDeviceImport ? 'devices' : 'users'}-review-button`}
                                fill={'filled'}
                                variant={'primary'}
                                size={'md'}
                                disabled={!isValidated}
                                onClick={() => {setCurrentChildComponent(ImportChildComponentNames.reviewData)}}
                                label={getLocalizedString('import.validate.review', 'Review Data')}
                            >
                            </ARCButton>
                            :
                            <ARCButton
                                id={`import-${isDeviceImport ? 'devices' : 'users'}-reupload-button`}
                                fill={'filled'}
                                variant={'primary'}
                                size={'md'}
                                onClick={() => {getPreviousStatusAndNavigateToUploadFile()}}
                                label={getLocalizedString('import.review.restart', 'Re-Upload File')}
                            >
                            </ARCButton>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
