import * as React from 'react';
import {
    Button, CircularProgress,
    IconButton,
    InputAdornment,
    Snackbar,
    TextField,
    useTheme
} from "@material-ui/core";
import {useNavigate, useLocation} from "react-router-dom";
import {appContextActions, appContextSelector, authorizeAsync} from "../app-context/appContextSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {ForgotPasswordForm} from "./ForgotPasswordForm";
import {
    calculatedSizeMinBasedOnWidthHeightScaler, calculateResponsiveSizeWithMinMax
} from "../../util/TextUtils";
import {env} from "../../dtos/API_DOMAINS";
import {
    LoginButton,
    SuccessMessageText,XSmallSpacer,
} from "../ui-components/StyledComponents";
import {WHITE} from "../../themes/colors";
import {LocationState} from "../../@types/types";
import ARCLOGO_BLACK from "../../assets/img/ARC_Logo_Black.png";
import ARCLOGO_WHITE from "../../assets/img/ARC_Logo_White.png";
import "../../css/Login.css";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {styled} from "@material-ui/core/styles";
import {HELP_EMAIL, HELP_PHONE, SYSTEM_EMAIL} from "../../constants/StringConstants";
import {getLocalizedString} from "../../util/Localization";

type LoginPageProps = {
    navToEmergency?: boolean;
    navToForgotPassword?: boolean;
}

export const LoginPage:React.FC<LoginPageProps> = ({navToEmergency, navToForgotPassword}) => {
    const theme = useTheme();
    const isSmOrBelow = useMediaQuery(theme.breakpoints.down('sm'));
    const isXsOrBelow = useMediaQuery(theme.breakpoints.down('xs'));
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {state} = useLocation() as LocationState;
    const appContext = useAppSelector(appContextSelector);
    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);
    const [forgotPassword, setForgotPassword] = React.useState(navToForgotPassword);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    
    const [email, setEmail] = React.useState('');

    const {
        authErrorMessage,
        authorize,
        forgotPasswordSuccessful,
        forgotPasswordErrorMessage
    } = useAppContext();
    
    React.useEffect(() => {
        dispatch(appContextActions.resetStatus())
    }, [dispatch]);

    React.useEffect(() => {
        if(state) {
            const {successmessage} = state; // Read values passed on state
            if(successmessage) {
                setSuccessMessage(successmessage);
            }
        }
    }, [state]);
    
    React.useEffect(() => {
        return () => {dispatch(appContextActions.resetAllPasswordMessageStates())}
    }, [dispatch]);
    
    React.useEffect(() => {
        if (appContext.loginFailure?.ipBlocked) {
            setErrorMessage(getLocalizedString('login.ipBlocked', 'We have detected multiple failed login attempts from your IP address. Please try logging in again after 30 minutes.'))
        } else if (appContext.loginFailure?.noPortalAccess) {
            setErrorMessage(getLocalizedString('login.restricted', 'Your account exists but you do not have permission to access the Client Portal. Please contact your system administrator for help.'))
        } else if (appContext.loginFailure?.failedAttempts && appContext.loginFailure?.failedAttempts > 0 && !appContext.loginFailure?.accountLocked) {
            setErrorMessage(getLocalizedString('login.invalidCredentials', 'Please check your login details and try again. You have 5 total attempts before your account is locked.'))
        } else if (forgotPasswordErrorMessage) {
            setErrorMessage(forgotPasswordErrorMessage);
        } else {
            setErrorMessage(undefined)
        }
    }, [
                appContext.loginFailure?.ipBlocked, 
                appContext.loginFailure?.failedAttempts, 
                appContext.loginFailure?.accountLocked, 
                forgotPasswordErrorMessage,
                appContext.loginFailure?.noPortalAccess
    ])
    
    const LoginErrorAlert = React.useMemo(() => {
        return (
            !isSmOrBelow ?
            (authErrorMessage || errorMessage) &&
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={true} className={'login-snackbar'}>
                <div className={'login-snackbar-div'}>
                    <div style={{display: 'flex'}}>
                        <div className={'login-snackbar-text'}>
                            {errorMessage ?? authErrorMessage}
                        </div>
                    </div>
                </div>
            </Snackbar>
            :
            (authErrorMessage || errorMessage) &&
            <div style={{marginBottom: isXsOrBelow ? '3em' : '5.5em'}} className={'div-login-snackbar'}>
                <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={true} className={'login-snackbar'}>
                    <div className={'login-snackbar-div'}>
                        <div style={{display: 'flex'}}>
                            <div className={'login-snackbar-text'}>
                                {errorMessage ?? authErrorMessage}
                            </div>
                        </div>
                    </div>
                </Snackbar>
            </div>
            
        );
    }, [authErrorMessage, errorMessage, isSmOrBelow, isXsOrBelow]);

    const onNewLoginSubmit = React.useCallback(async (username : string, password: string) => {
        let currentFailedAttempts;

        setSuccessMessage('');
        if(!username) {
            setErrorMessage(getLocalizedString('username.required', 'Username is required'));
            return;
        }
        if(!password) {
            setErrorMessage(getLocalizedString('password.required', 'Password is required'));
            return;
        }

        if(!appContext.loginFailure?.failedAttempts) {
            currentFailedAttempts = 0;
        }
        else {
            currentFailedAttempts = appContext.loginFailure?.failedAttempts;
        }
        const result = await authorize(username, password, currentFailedAttempts);
        if (!result.payload?.hasOwnProperty("errorMessage")) {
            if (result.payload?.hasOwnProperty("accountLocked")) {
                if (result.payload.hasOwnProperty("resetToken") && result.payload.resetToken) {
                    navigate(`/updatepassword/token=${result.payload.resetToken}`);
                }
            } else if (result.payload) {
                if(result.payload.employee) {
                    if (navToEmergency) {
                        navigate('/faq/emergency/keypads/view');
                    } else {
                        navigate('/home');
                    }
                } else {
                    navigate('/home', {state: {navToEmergency: navToEmergency}});
                }
            }
        }

    }, [appContext.loginFailure?.failedAttempts, authorize, navigate, navToEmergency]);
    
    // const EmergencyLoginTooltip = React.useMemo(() => {
    //     return (
    //         <div className={isSmOrBelow ? 'login-emergency-tool-tip-div-mobile' : 'login-emergency-tool-tip-div-desktop'}>
    //             <Tooltip
    //                 title={
    //                     <div style={{fontSize: '12px'}}>
    //                         {'A portal with instructions to retrieve devices from a malfunctioning kiosk'}
    //                     </div>
    //                 }
    //                 onClick={() => navigate('/emergency/login')}
    //                 PopperProps={{
    //                     style: {width: '230px'} // width of hover box
    //                 }}
    //             >
    //                 <div className={'login-emergency-tool-tip'}>
    //                     <ErrorOutline style={{
    //                                           fontSize: calculateResponsiveSizeWithMinMax('2', '20', '8'),
    //                                           marginRight: '5px'
    //                     }}/>
    //                     {"Emergency Kiosk Access"}
    //                 </div>
    //             </Tooltip>
    //         </div>
    //     )
    // }, [isSmOrBelow, navigate])

    return   <div className={'container'}>
                {LoginErrorAlert}
                {/*{!isSmOrBelow &&*/}
                {/*    EmergencyLoginTooltip*/}
                {/*}*/}
                {forgotPasswordSuccessful && forgotPassword ?
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div className={'login-logo-div login-logo-div-hover'} onClick={
                            () => {setForgotPassword(!forgotPassword)}
                        }>
                            <img className={'login-logo'} src={ARCLOGO_WHITE}
                                 alt="ARC Logo"/>
                        </div>
                        <div className={'login-forgot-password-text-div'}>
                            <div style={{color: WHITE, padding: '5% 20% 5% 20%', textAlign: 'center', maxWidth: '1600px', fontWeight: 400, fontSize: calculateResponsiveSizeWithMinMax('3', '20', '10')}}>
                                {getLocalizedString('forgotpassword.emailSent','If your email is in our system, you will receive an email from ' + SYSTEM_EMAIL + ' with a link to reset your password.')}
                            </div>
                            <div style={{color: WHITE, padding: '5% 20% 5% 20%', textAlign: 'center', maxWidth: '1600px', fontWeight: 400, fontSize: calculateResponsiveSizeWithMinMax('3', '20', '10')}}>
                                {getLocalizedString('forgotpassword.emailNotReceived', 'If you don’t receive an email, ask your manager to verify that your correct email address is set up in the Client Portal, or contact ARC Support below.')}
                            </div>
                        </div>
                    </div>
                    :
                    <div className={'login-page-body'}>
                        <div className={'login-header'}>
                            {forgotPassword ?
                                <div className={'login-logo-div login-logo-div-hover'} onClick={() => setForgotPassword(!forgotPassword)}>
                                    <img className={'login-logo'} src={ARCLOGO_BLACK}
                                         alt="ARC Logo"/>
                                    <div className={'logo-typography'}>{getLocalizedString('title', 'Client Portal')}</div>
                                </div>
                                :
                                <div className={'login-logo-div'}>
                                    <img className={'login-logo'} src={ARCLOGO_BLACK}
                                         alt="ARC Logo"/>
                                    <div className={'logo-typography'}>{getLocalizedString('title', 'Client Portal')}</div>
                                </div>
                            }
                        </div>
                        <div style={{textAlign: 'center', width: '100%'}}>
                            {!forgotPassword ?
                                <div
                                    className={'env-label'}
                                    style={{color: theme.palette.primary.light}}
                                >
                                    {
                                        (env && env !== 'production') ? env.toUpperCase() : ''
                                    }
                                    {successMessage ?
                                        <SuccessMessageText style={{textAlign: 'center'}}>
                                                {successMessage}
                                        </SuccessMessageText>
                                        :
                                        <div style={{marginBottom: '10px'}}/>
                                    }
                                </div>
                                :
                                <XSmallSpacer/>
                            }
                        </div>
        
                        {appContext.loginFailure?.accountLocked ?
                            <>
                                <div className={'error-message'}
                                            style={{color: theme.palette.warning.main}}>{getLocalizedString('accountLocked.title', 'Account Locked')}</div>
                                <br/>
                                {appContext.loginFailure?.existingResetToken ?
                                    <div className={'login-text'}>
                                        {getLocalizedString('accountLocked.emailAlreadySent', 'A password reset link has already been sent to the email address associated with this user account. Only one reset link can be sent every 24 hours.')}
                                    </div>
                                    :
                                    <div className={'login-text'}>
                                        {getLocalizedString('accountLocked.emailSent1', 'A password reset link has been sent to the email address associated with this user account. ')}
                                        {getLocalizedString('accountLocked.emailSent2', 'You must reset your password to unlock your account. ')}<br/>
                                        {getLocalizedString('accountLocked.emailSent3', 'The password reset link will be valid for 24 hours only.')}
                                    </div>}
                                <br/>
                                <LoginButtonCancel style={{marginLeft: '0'}} onClick={(event) => {
                                    dispatch(appContextActions.clearState())
                                }}>{getLocalizedString('back', 'Back')}</LoginButtonCancel>
                            </>
                            :
                            forgotPassword ?
                                <ForgotPasswordForm 
                                    cancelPressed={() => {
                                        setForgotPassword(false)
                                    }}
                                    email={email} 
                                    onEmailChange={setEmail}
                                    onSubmit={() => {
                                        setForgotPassword(true)
                                    }}
                                />
                                :
                                <form onSubmit={async (e) => {
                                    e.preventDefault(); // Stops the page reload since that is how forms work typically and we hate that
                                    /* TypeScript doesnt know about username and password inputs on form */
                                    /* So we need to define the fields on the target of event by extending type */
                                    const target = e.target as typeof e.target & {
                                        ['login-username-email-input']: { value: string };
                                        ['login-password-input']: { value: string };
                                    };
        
                                    await onNewLoginSubmit(
                                        target['login-username-email-input'].value,
                                        target['login-password-input'].value
                                    );
        
                                    target['login-password-input'].value = ""; // Clear password after submit
                                }}
                                      className={'login-form'}
                                >
                                    <div
                                        className={'login-input-root'}>
        
                                        <div className={'login-row-form-control'}>
                                            {/*<img alt={'username icon'} src={USER_ICON_YELLOW} className={styles.input_icon}/>*/}
                                            <TextField
                                                autoFocus
                                                autoComplete={"Username"}
                                                id="login-username-email-input"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    style: {
                                                        marginTop: 0,
                                                        height: '100%',
                                                        fontSize: calculateResponsiveSizeWithMinMax('2', '18', '8'),
                                                        paddingLeft: '15px',
                                                        paddingRight: '10px'
                                                    }
                                                }}
                                                placeholder={getLocalizedString('login.username', 'Email or Username')}
                                                className={'login-input-text'}
                                            />
                                        </div>
        
                                        <div className={'login-row-form-control'}>
                                            {/*<img alt={'password icon'} src={PASS_ICON_YELLOW} className={styles.input_icon}/>*/}
                                            <TextField
                                                autoComplete={"current-password"}
                                                id="login-password-input"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    className: 'input_text_props',
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="end"
                                                            style={{height: '50%', width: '20%'}}
                                                        >
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => setShowPassword(!showPassword)}
                                                                edge="end"
                                                                style={{height: '50%', width: '50%', fontSize: calculatedSizeMinBasedOnWidthHeightScaler('4')}}
                                                            >
                                                                {!showPassword ? (
                                                                    <Visibility style={{maxWidth: 'unset'}} fontSize="inherit"/>
                                                                ) : (
                                                                    <VisibilityOff style={{maxWidth: 'unset'}} fontSize="inherit"/>
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    style: {
                                                        marginTop: 0,
                                                        height: '100%',
                                                        fontSize: calculateResponsiveSizeWithMinMax('2', '18', '8'),
                                                        paddingLeft: '15px',
                                                        paddingRight: '10px'
                                                    }
                                                }}
                                                placeholder={getLocalizedString('login.password', 'Password')}
                                                type={showPassword ? "text" : "password"}
                                                className={'login-input-text'}
                                            />
                                        </div>
                                        <div className={'login-button-div'}>
                                            <LoginButton
                                                id="login-submit-button"
                                                type="submit"
                                                variant='contained'
                                                disabled={appContext.authStatus === 'loading'}
                                                style={{fontSize: calculateResponsiveSizeWithMinMax('2', '18', '8'),}}
                                            >
                                                {getLocalizedString('login.submit', 'Log In')}
                                                {appContext.authStatus === 'loading' &&
                                                    <CircularProgress style={{
                                                        width: calculateResponsiveSizeWithMinMax('4', '24', '8'),
                                                        height: calculateResponsiveSizeWithMinMax('4', '24', '8'),
                                                    }}/>
                                                }
                                            </LoginButton>
                                            
                                        </div>
                                        <div
                                            id="login-forgot-password-button"
                                            className={'forgot-label'}
                                            title={getLocalizedString('login.forgotPassword', 'Forgot Password?')}
                                            onClick={() => {
                                                dispatch(appContextActions.resetAuthError());
                                                dispatch(appContextActions.resetForgotPassword());
                                                setSuccessMessage(undefined);
                                                setShowPassword(false);
                                                setForgotPassword(true);
                                                setErrorMessage(undefined);
                                            }
                                            }>
                                            {getLocalizedString('login.forgotPassword', 'Forgot Password?')}
                                        </div>
        
                                    </div>
                                </form>
                        }
                    </div>
                }
                <div className={'tool-tip'}>
                    {getLocalizedString('support', 'ARC Support') + ' | '+ getLocalizedString('support.email', HELP_EMAIL) + ' | ' + getLocalizedString("support.phone", HELP_PHONE)}
                </div>
                <div className={'login-terms-privacy-div'}>
                    <div className={'login-privacy-text'} id={'privacy-page-link'} onClick={() => navigate('/privacy/clientportal')}>
                        {getLocalizedString('privacy.title', 'Privacy Policy')}
                    </div>
                    <div style={{marginLeft: '5px', marginRight: '5px', fontSize: calculateResponsiveSizeWithMinMax('1.4', '18', '6')}}>
                        {' | '}
                    </div>
                    <div className={'login-terms-text'} id={'terms-conditions-page-link'} onClick={() => navigate('/terms/clientportal')}>
                        {getLocalizedString('terms.title', 'Terms & Conditions')}
                    </div>
                </div>
                {/*{isSmOrBelow &&*/}
                {/*    EmergencyLoginTooltip*/}
                {/*}*/}
         </div>
};

const useAppContext = () => {
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();

    const authorize = React.useCallback(async (username:string, password:string, failedAttempts: number) => {
        return await dispatch(authorizeAsync({ username, password, failedAttempts}));
    }, [dispatch])

    return {
        authStatus: context.authStatus,
        authErrorMessage: context.authErrorMessage,
        hasAuthorization: !!(context.authorizationDto),
        authDto: context.authorizationDto,
        authorize, 
        forgotPasswordSuccessful: context.forgotPasswordSuccessful,
        forgotPasswordErrorMessage: context.forgotPasswordErrorMessage
    }
}

const LoginButtonCancel = styled(Button)({
    maxHeight: '60px',
    borderRadius: '30px',
    borderWidth: '0',
    maxWidth: '100% !important',
    font: 'normal normal 600 0 "Proxima Nova"',
    fontSize: calculateResponsiveSizeWithMinMax('2', '18', '8'),
    color: '#FFFFFF',
    background: '#444444',
    letterSpacing: '0',
    width: '60%',
    padding: '2% 0 2% 0 !important',
    textTransform: 'none',
    minWidth: '0px !important',
    '&:hover': {
      backgroundColor: '#444444',
      opacity: '.5'
    }
});
