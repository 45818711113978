import * as React from 'react';
import {
    deviceContextActions,
    deviceContextSelector, saveExistingDevice,
    transferDevice,
    transferDeviceStayOnPage, updateDuplicateList
} from "../../deviceSlice";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {useNavigate} from "react-router-dom";
import "../../../../css/CreateExistingDevice.css"
import {IconButton} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {DEV_STATUS_INACTIVE, DeviceDto, existingDeviceAtDiffLocation, getStatusDisplay} from "../../../../dtos/Device";
import {appContextSelector} from "../../../app-context/appContextSlice";
import {Dispatch, SetStateAction, useRef} from "react";
import {getLocalizedString} from "../../../../util/Localization";
import {ARCButton} from "../../../ui-components/design-system/ARCButton";

type CreateExistingDeviceProps = {
    formDevice: DeviceDto,
    setCreateDeviceSuccessMessage: Dispatch<SetStateAction<string | undefined>>,
    createDeviceSuccessMessage: string | undefined,
    uploadIndex?: string,
    duplicateListUpdated?: string
}

export const CreateExistingDevice:React.ComponentType<CreateExistingDeviceProps> = (props) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const deviceContext = useAppSelector(deviceContextSelector);
    const context = useAppSelector(appContextSelector);
    const [pageNumber, setPageNumber] = React.useState<number>(0);
    const {formDevice, setCreateDeviceSuccessMessage, uploadIndex, duplicateListUpdated} = props;
    
    const modalRef = useRef<HTMLDivElement>(null);
    
    React.useEffect(() => {
        if (deviceContext.existingDeviceDto) {
            setPageNumber(0);
        }
    },[deviceContext.existingDeviceDto])
    
    const closeExistingDeviceModal = React.useCallback(() => {
        dispatch(deviceContextActions.resetExistingDevice());
        setPageNumber(0);
    }, [dispatch])
    
    // Handle click away and escape to close modal
    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node | null)) {
                closeExistingDeviceModal();
            }
        };
        
        const handleEscapeKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                closeExistingDeviceModal();
            }
        };
        
        if (deviceContext.existingDeviceDto) {
            window.addEventListener('mousedown', handleClickOutside);
            window.addEventListener('keydown', handleEscapeKey);
        }
        
        return () => {
            window.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('keydown', handleEscapeKey);
        };
    },[closeExistingDeviceModal, deviceContext.existingDeviceDto])

    const transferExistingDevice = React.useCallback((exitDevicePage: boolean, reactivate?: boolean) => {
        if (!deviceContext.existingDeviceDto?.deviceDto || !context.currentLocation) return;
        let transferDeviceDto:DeviceDto = {
            ...deviceContext.existingDeviceDto?.deviceDto
        }
        transferDeviceDto.locationId = context.currentLocation.locationId;
        transferDeviceDto.deviceTag = formDevice.deviceTag;
        if (formDevice.deviceIdNumber && formDevice.deviceIdNumber.length > 0) { // import can have blank idnumber
            transferDeviceDto.deviceIdNumber = formDevice.deviceIdNumber;
        }
        transferDeviceDto.deviceType = formDevice.deviceType;
        if (!exitDevicePage) {
            dispatch(
                transferDeviceStayOnPage({
                        deviceDto: transferDeviceDto,
                        locationId: context.currentLocation.locationId,
                        reactivate: reactivate
                    }
                )
            )
        } else {
            dispatch(
                transferDevice({
                        deviceDto: transferDeviceDto,
                        locationId: context.currentLocation.locationId,
                        reactivate: reactivate
                    }
                )
            )
        }
        
    },[deviceContext.existingDeviceDto?.deviceDto, context.currentLocation, formDevice.deviceTag, formDevice.deviceIdNumber, formDevice.deviceType, dispatch]);
             
    const updateExistingDevice = React.useCallback((fieldUpdating: keyof DeviceDto) => {
        if (!deviceContext.existingDeviceDto?.deviceDto || !context.currentLocation) return;
        const deviceDto = {
            ...deviceContext.existingDeviceDto.deviceDto,
            [fieldUpdating]: formDevice[fieldUpdating]
        };
        dispatch(saveExistingDevice({
            deviceDto,
            locationId: deviceContext.existingDeviceDto?.deviceDto?.locationId
        }));
        
    },[deviceContext.existingDeviceDto?.deviceDto, context.currentLocation, formDevice, dispatch]);
    
    React.useEffect(() => {
        if (deviceContext.importResult && uploadIndex !== undefined && duplicateListUpdated && deviceContext.savedDeviceId) {
            dispatch(updateDuplicateList({
                portalUploadStatusId: deviceContext.importResult?.portalUploadStatusId,
                duplicateList: duplicateListUpdated,
            }));
        }
    }, [deviceContext.importResult, uploadIndex, dispatch, duplicateListUpdated, deviceContext.savedDeviceId])
    
    type SerialMatchModalProps = {
        onClick: () => void,
        hasLocation: boolean
    }
    const SerialMatchModal:React.ComponentType<SerialMatchModalProps> = React.useCallback(({onClick, hasLocation}) => {
        return (
            <div>
                <div className={'createexistingdevice-cancel-div'}>
                    <IconButton>
                        <Close onClick={() => closeExistingDeviceModal()}/>
                    </IconButton>
                </div>
                <div className={'createexistingdevice-text-div-left'}>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '15px'}}>
                        {getLocalizedString('createDevice.existingDevice.serialMatch', 'The Serial Number you entered matches the following device: ')}
                    </div>
                    <div className={'createexistingdevice-devicedata-div'}>
                        <div className={'createexistingdevice-devicedata-text'} style={{
                            backgroundColor: 'rgba(40,44,52,0.89)',
                            color: 'white',
                            paddingLeft: '10px'
                        }}>
                            {getLocalizedString('createDevice.deviceTag', 'Serial Number') + ': '} <span style={{
                            fontWeight: 'normal',
                            paddingLeft: '5px'
                        }}>{deviceContext.existingDeviceDto?.deviceDto?.deviceTag}</span>
                        </div>
                        <div className={'createexistingdevice-devicedata-text'}>
                            {getLocalizedString('createDevice.deviceIdNumber', 'Device ID') + ': '} <span style={{
                            fontWeight: 'normal',
                            paddingLeft: '5px'
                        }}>{deviceContext.existingDeviceDto?.deviceDto?.deviceIdNumber}</span>
                        </div>
                        <div className={'createexistingdevice-devicedata-text'}>
                            {getLocalizedString('viewDevices.status', 'Status: ')} <span style={{
                            fontWeight: 'normal',
                            paddingLeft: '5px'
                        }}>{deviceContext.existingDeviceDto?.deviceDto?.status}</span>
                        </div>
                        {hasLocation &&
                            <div className={'createexistingdevice-devicedata-text'}>
                                {getLocalizedString('viewDevices.filter.location', 'Location') + ': '} <span style={{
                                fontWeight: 'normal',
                                paddingLeft: '5px'
                            }}>{deviceContext.existingDeviceDto?.locationName}</span>
                            </div>
                        }
                    </div>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '15px'}}>
                        {getLocalizedString('createDevice.existingDevice.serialMatch.verify', 'Please double-check that ' + deviceContext.existingDeviceDto?.deviceDto?.deviceTag + ' is correct', [`${deviceContext.existingDeviceDto?.deviceDto?.deviceTag}`])}
                    </div>
                </div>
                <div className={'createexistingdevice-button-div'}
                     style={{justifyContent: 'left', padding: '12px'}}>
                    <ARCButton
                        fill={'filled'}
                        variant={'primary'}
                        size={'md'} 
                        onClick={onClick}
                        label={getLocalizedString('createDevice.existingDevice.serialMatch.confirm', 'Yes, it\'s correct')}
                    >
                    </ARCButton>
                    <ARCButton
                        fill={'outlined'}
                        variant={'secondary'}
                        size={'md'} 
                        onClick={() => {
                            closeExistingDeviceModal()
                        }}
                        label={getLocalizedString('createDevice.existingDevice.serialMatch.cancel', 'Edit Serial Number')}
                    >
                    </ARCButton>
                </div>
            </div>
        )
    },[closeExistingDeviceModal, deviceContext.existingDeviceDto])
    
    type SameTagIdLocationInactiveModalProps = {}
    const SameTagIdLocationInactiveModal:React.ComponentType<SameTagIdLocationInactiveModalProps> = React.useCallback(() => {
        return (
            <div>
                <div className={'createexistingdevice-cancel-div'}>
                    <IconButton>
                        <Close onClick={() => closeExistingDeviceModal()}/>
                    </IconButton>
                </div>
                <div className={'createexistingdevice-text-div'}>
                    <div className={'createexistingdevice-title-text'}>
                        {getLocalizedString('createDevice.existingDevice.sameTagIdLocationInactive.title', 'The information matches a deactivated device. ')}
                    </div>
                    <br/>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '30px'}}>
                        {getLocalizedString('createDevice.existingDevice.sameTagIdLocationInactive.advance1', 'Please click Next to') + ' '} <span style={{fontWeight: 'bold'}}>{getLocalizedString('createDevice.existingDevice.sameTagIdLocationInactive.advance2', 'view deactivation details') + ' '}</span>
                        {getLocalizedString('createDevice.existingDevice.sameTagIdLocationInactive.advance3', 'and') + ' '} <span style={{fontWeight: 'bold'}}>{getLocalizedString('createDevice.existingDevice.sameTagIdLocationInactive.advance4', 'reactivate the device:')}</span>
                    </div>
                </div>
                <div className={'createexistingdevice-button-div'}>
                    <ARCButton
                        fill={'filled'}
                        variant={'primary'}
                        size={'md'}  
                        onClick={() => {
                            navigate(`/devices/reactivate/locationId=${context.currentLocation?.locationId}/deviceId=${deviceContext.existingDeviceDto?.deviceDto?.deviceId}`, 
                                {state: {
                                    portalUploadStatusId: deviceContext.importResult?.portalUploadStatusId,
                                    duplicateListUpdated: duplicateListUpdated
                                }})
                        }}
                        label={getLocalizedString('next', 'Next')}
                    >
                    </ARCButton>
                </div>
            </div>
        )
    },[closeExistingDeviceModal, context.currentLocation?.locationId, deviceContext.existingDeviceDto?.deviceDto?.deviceId, navigate, duplicateListUpdated, deviceContext.importResult?.portalUploadStatusId])
    
    type SameTagIdDifLocationModalProps = {}
    const SameTagIdDifLocationModal:React.ComponentType<SameTagIdDifLocationModalProps> = React.useCallback(() => {
        return (
            <div>
                <div className={'createexistingdevice-cancel-div'}>
                    <IconButton>
                        <Close onClick={() => closeExistingDeviceModal()}/>
                    </IconButton>
                </div>
                <div className={'createexistingdevice-text-div'}>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '30px'}}>
                        {getLocalizedString('createDevice.existingDevice.sameTagIdDifLocation.title', 'This device is already active at ' + deviceContext.existingDeviceDto?.locationName, [`${deviceContext.existingDeviceDto?.locationName}`])}
                    </div>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '30px'}}>
                        {getLocalizedString('createDevice.existingDevice.sameTagIdDifLocation.advance1', 'Please transfer this device to use it at')}
                        <br/>
                        {context.currentLocation?.locationName}
                    </div>
                </div>
                <div className={'createexistingdevice-button-div'}>
                    <ARCButton
                        fill={'filled'}
                        variant={'primary'}
                        size={'md'}
                        onClick={() => {
                            setCreateDeviceSuccessMessage('successfully transferred to ' + context.currentLocation?.locationName)
                            transferExistingDevice(true)
                        }}
                        label={getLocalizedString('createDevice.existingDevice.transfer', 'Transfer Device')}
                    >
                    </ARCButton>
                </div>
            </div>
        )
    },[closeExistingDeviceModal, deviceContext.existingDeviceDto?.locationName, context.currentLocation?.locationName, setCreateDeviceSuccessMessage, transferExistingDevice])
    
    type SameTagIdDifLocationInactiveModalProps = {}
    const SameTagIdDifLocationInactiveModal:React.ComponentType<SameTagIdDifLocationInactiveModalProps> = React.useCallback(() => {
        return (
            <div>
                <div className={'createexistingdevice-cancel-div'}>
                    <IconButton>
                        <Close onClick={() => closeExistingDeviceModal()}/>
                    </IconButton>
                </div>
                <div className={'createexistingdevice-text-div'}>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '30px'}}>
                        {getLocalizedString('createDevice.existingDevice.sameTagIdDiffLocationInactive.title1', 'Device ID and Serial Number match a deactivated device')}
                        <br/>
                        {getLocalizedString('createDevice.existingDevice.sameTagIdDiffLocationInactive.title2', 'at ' + deviceContext.existingDeviceDto?.locationName, [`${deviceContext.existingDeviceDto?.locationName}`])}
                    </div>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '30px'}}>
                        {getLocalizedString('createDevice.existingDevice.sameTagIdDiffLocationInactive.advance1', 'Please transfer this device to use it at')}
                        <br/>
                        {context.currentLocation?.locationName}
                    </div>
                </div>
                <div className={'createexistingdevice-button-div'}>
                    <ARCButton
                        fill={'filled'}
                        variant={'primary'}
                        size={'md'} 
                        onClick={
                            () => {
                                transferExistingDevice(true, true)
                                setCreateDeviceSuccessMessage(getLocalizedString('createDevice.existingDevice.sameTagIdDiffLocationInactive.success', `successfully reactivated and transferred to ${context.currentLocation?.locationName ?? ''}`, [`${context.currentLocation?.locationName ?? ''}`]))
                            }
                        }
                        label={getLocalizedString('createDevice.existingDevice.transfer', 'Transfer Device')}
                    >
                    </ARCButton>
                </div>
            </div>
        )
    },[deviceContext.existingDeviceDto?.locationName, context.currentLocation?.locationName, closeExistingDeviceModal, transferExistingDevice, setCreateDeviceSuccessMessage])
    
    type SameTagDifIdSameLocationInactiveModal1Props = {}
    const SameTagDifIdSameLocationInactiveModal1:React.ComponentType<SameTagDifIdSameLocationInactiveModal1Props> = React.useCallback(() => {
        return (
            <div>
                <div className={'createexistingdevice-cancel-div'}>
                    <IconButton>
                        <Close onClick={() => closeExistingDeviceModal()}/>
                    </IconButton>
                </div>
                <div className={'createexistingdevice-text-div'}>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '15px'}}>
                        {getLocalizedString('createDevice.existingDevice.sameTagIdDiffLocationInactiveSuccess.title', 'Great, the Device ID has been updated to ' + formDevice.deviceIdNumber, [`${formDevice.deviceIdNumber}`])}
                    </div>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '30px'}}>
                        {getLocalizedString('createDevice.existingDevice.sameTagIdDiffLocationInactiveSuccess.advance1', 'Please click Next to ')} <span style={{fontWeight: 'bold'}}>{getLocalizedString('createDevice.existingDevice.sameTagIdDiffLocationInactiveSuccess.advance2', 'view deactivation details')}</span> {' ' + getLocalizedString('createDevice.existingDevice.sameTagIdDiffLocationInactiveSuccess.advance3', 'and')}
                        <br/>
                        <span style={{fontWeight: 'bold'}}>{getLocalizedString('createDevice.existingDevice.sameTagIdDiffLocationInactiveSuccess.advance4', 'reactivate this device')}</span>
                    </div>
                </div>
                <div className={'createexistingdevice-button-div'}>
                    <ARCButton
                        fill={'filled'}
                        variant={'primary'}
                        size={'md'} 
                        onClick={() => {
                        navigate(`/devices/reactivate/locationId=${context.currentLocation?.locationId}/deviceId=${deviceContext.existingDeviceDto?.deviceDto?.deviceId}`, 
                            {state: {
                                portalUploadStatusId: deviceContext.importResult?.portalUploadStatusId,
                                duplicateListUpdated: duplicateListUpdated
                            }})
                        }}
                        label={getLocalizedString('next', 'Next')}
                    >
                    </ARCButton>
                </div>
            </div>
        )
    },[formDevice.deviceIdNumber, closeExistingDeviceModal, navigate, context.currentLocation?.locationId, deviceContext.existingDeviceDto?.deviceDto?.deviceId, duplicateListUpdated, deviceContext.importResult?.portalUploadStatusId])
    
    type SameIdDifTagModal3Props = {}
    const SameIdDifTagModal3:React.ComponentType<SameIdDifTagModal3Props> = React.useCallback(() => {
        return (
            <div>
                <div className={'createexistingdevice-cancel-div'}>
                    <IconButton>
                        <Close onClick={() => closeExistingDeviceModal()}/>
                    </IconButton>
                </div>
                <div className={'createexistingdevice-text-div'}>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '30px'}}>
                        {getLocalizedString('createDevice.existingDevice.SameIdDifTagModal3.title1', 'Got it! In that case, please transfer this device')}
                        <br/>
                        {getLocalizedString('createDevice.existingDevice.SameIdDifTagModal3.title2', 'to use it at ' + context.currentLocation?.locationName, [`${context.currentLocation?.locationName}`])}
                    </div>
                </div>
                <div className={'createexistingdevice-button-div'}>
                    <ARCButton
                        fill={'filled'}
                        variant={'primary'}
                        size={'md'}
                        onClick={() => {
                            if(deviceContext.existingDeviceDto?.deviceDto?.status === DEV_STATUS_INACTIVE) {
                                transferExistingDevice(true, true);
                                setCreateDeviceSuccessMessage(getLocalizedString('createDevice.existingDevice.SameIdDifTagModal3.success.reactive', `successfully reactivated and transferred to ${context.currentLocation?.locationName ?? ''}`, [`${context.currentLocation?.locationName ?? ''}`]))
                            } else {
                                transferExistingDevice(true);
                                setCreateDeviceSuccessMessage(getLocalizedString('createDevice.existingDevice.SameIdDifTagModal3.success', `successfully transferred to ${context.currentLocation?.locationName ?? ''}`, [`${context.currentLocation?.locationName ?? ''}`]))
                            }
                        }}
                        label={getLocalizedString('createDevice.existingDevice.transfer', 'Transfer Device')}
                    >
                    </ARCButton>
                </div>
            </div>
        )
    },[closeExistingDeviceModal, context.currentLocation?.locationName, deviceContext.existingDeviceDto?.deviceDto?.status, setCreateDeviceSuccessMessage, transferExistingDevice])
    
    type SameTagDifIdModal1Props = {}
    const SameTagDifIdModal1:React.ComponentType<SameTagDifIdModal1Props> = React.useCallback(() => {
        return (
            <div>
                <div className={'createexistingdevice-cancel-div'}>
                    <IconButton>
                        <Close onClick={() => closeExistingDeviceModal()}/>
                    </IconButton>
                </div>
                <div className={'createexistingdevice-text-div'}>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '30px'}}>
                        {getLocalizedString('createDevice.existingDevice.SameTagDifIdModal1.title1', 'Great, we will update the Device ID to ' + formDevice.deviceIdNumber, [`${formDevice.deviceIdNumber}`])}
                    </div>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '30px'}}>
                        {getLocalizedString('createDevice.existingDevice.SameTagDifIdModal1.title2', 'Please transfer this device to save changes and')}
                        <br/>
                        {getLocalizedString('createDevice.existingDevice.SameTagDifIdModal1.title3', 'to use it at ' + context.currentLocation?.locationName, [`${context.currentLocation?.locationName}`])}
                    </div>
                </div>
                <div className={'createexistingdevice-button-div'}>
                    <ARCButton
                        fill={'filled'}
                        variant={'primary'}
                        size={'md'}
                        onClick={() => {
                            transferExistingDevice(true, true)
                            setCreateDeviceSuccessMessage(getLocalizedString('createDevice.existingDevice.SameTagDifIdModal1.success', `successfully transferred to ${context.currentLocation?.locationName ?? ''}`, [`${context.currentLocation?.locationName ?? ''}`]))
                        }}
                        label={getLocalizedString('createDevice.existingDevice.transfer', 'Transfer Device')}
                    >
                    </ARCButton>
                </div>
            </div>
        )
    },[closeExistingDeviceModal, formDevice.deviceIdNumber, context.currentLocation?.locationName, transferExistingDevice, setCreateDeviceSuccessMessage])
    
    type SameTagDifIdDifLocationInactiveModal1Props = {}
    const SameTagDifIdDifLocationInactiveModal1:React.ComponentType<SameTagDifIdDifLocationInactiveModal1Props> = React.useCallback(() => {
        return (
            <div>
                <div className={'createexistingdevice-cancel-div'}>
                    <IconButton>
                        <Close onClick={() => closeExistingDeviceModal()}/>
                    </IconButton>
                </div>
                <div className={'createexistingdevice-text-div'}>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '15px'}}>
                        {getLocalizedString('createDevice.existingDevice.SameTagDifIdDifLocationInactiveModal1.title1', 'Great, the Device ID has been updated to ' + formDevice.deviceIdNumber, [`${formDevice.deviceIdNumber}`])}
                    </div>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '30px'}}>
                        {getLocalizedString('createDevice.existingDevice.SameTagDifIdDifLocationInactiveModal1.title2', 'Please transfer this device')}
                        <br/>
                        {getLocalizedString('createDevice.existingDevice.SameTagDifIdDifLocationInactiveModal1.title3', 'to use it at ' + context.currentLocation?.locationName, [`${context.currentLocation?.locationName}`])}
                    </div>
                </div>
                <div className={'createexistingdevice-button-div'}>
                    <ARCButton
                        fill={'filled'}
                        variant={'primary'}
                        size={'md'} 
                        onClick={() => {
                            transferExistingDevice(true, true)
                            setCreateDeviceSuccessMessage(getLocalizedString('createDevice.existingDevice.SameTagDifIdDifLocationInactiveModal1.success', `successfully reactivated and transferred to ${context.currentLocation?.locationName ?? ''}`, [`${context.currentLocation?.locationName ?? ''}`]))
                        }}
                        label={getLocalizedString('createDevice.existingDevice.transfer', 'Transfer Device')}
                    >
                    </ARCButton>
                </div>
            </div>
        )
    },[closeExistingDeviceModal, context.currentLocation?.locationName, formDevice.deviceIdNumber, setCreateDeviceSuccessMessage, transferExistingDevice])
    
    type SameIdDifTagModal0Props = {}
    const SameIdDifTagModal0:React.ComponentType<SameIdDifTagModal0Props> = React.useCallback(() => {
        return (
            <div>
                <div className={'createexistingdevice-cancel-div'}>
                    <IconButton>
                        <Close onClick={() => closeExistingDeviceModal()}/>
                    </IconButton>
                </div>
                <div className={'createexistingdevice-text-div'}>
                    <div className={'createexistingdevice-title-text'}>
                        {getLocalizedString('createDevice.existingDevice.SameIdDifTagModal0.title1', 'The Device ID you entered ')} <span style={{fontWeight: 'bold'}}>{getLocalizedString('createDevice.existingDevice.SameIdDifTagModal0.title2', 'matches an existing device.')}</span>
                    </div>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '30px'}}>
                        {getLocalizedString('createDevice.existingDevice.SameIdDifTagModal0.title3', 'Let\'s resolve this!')}
                    </div>
                </div>
                <div className={'createexistingdevice-button-div'}>
                    <ARCButton
                        fill={'filled'}
                        variant={'primary'}
                        size={'md'} 
                        onClick={() => {
                            setPageNumber(1);
                        }}
                        label={getLocalizedString('next', 'Next')}
                    >
                    </ARCButton>
                </div>
            </div>
        )
    },[closeExistingDeviceModal])
    
    type DoubleCheckInfoModalProps = {}
    const DoubleCheckInfoModal:React.ComponentType<DoubleCheckInfoModalProps> = React.useCallback(() => {
        return (
            <div>
                <div className={'createexistingdevice-cancel-div'}>
                    <IconButton>
                        <Close onClick={() => closeExistingDeviceModal()}/>
                    </IconButton>
                </div>
                <div className={'createexistingdevice-text-div-left'}>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '15px'}}>
                        {getLocalizedString('createDevice.existingDevice.DoubleCheckInfoModal.title', 'First, please double-check that the info you entered is correct:')}
                    </div>
                    <div className={'createexistingdevice-comparedevice-div'} style={{marginBottom: '15px'}}>
                        <div className={'createexistingdevice-comparedevice-data-div-short'}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div className={'createexistingdevice-comparedevice-data-title'}>
                                    {getLocalizedString('createDevice.deviceTag', 'Serial Number')}
                                </div>
                                <div className={'createexistingdevice-comparedevice-data'}>
                                    {formDevice.deviceTag}
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div className={'createexistingdevice-comparedevice-data-title'}>
                                    {getLocalizedString('createDevice.deviceIdNumber', 'Device ID')}
                                </div>
                                <div className={'createexistingdevice-comparedevice-data'}>
                                    {formDevice.deviceIdNumber}
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div className={'createexistingdevice-button-div'} style={{justifyContent: 'center', padding: '12px'}}>
                    <ARCButton
                        fill={'filled'}
                        variant={'primary'}
                        size={'md'}
                        onClick={() => {
                            setPageNumber(2)
                        }}
                        label={getLocalizedString('createDevice.existingDevice.DoubleCheckInfoModal.confirm', 'My Info is Correct')}
                    >
                    </ARCButton>
                    <ARCButton
                        fill={'outlined'}
                        variant={'secondary'}
                        size={'md'} 
                        onClick={() => {closeExistingDeviceModal()}}
                        label={getLocalizedString('createDevice.existingDevice.DoubleCheckInfoModal.cancel', 'Edit Device Details')}
                    >
                    </ARCButton>
                </div>
            </div>
        )
    },[closeExistingDeviceModal, formDevice.deviceIdNumber, formDevice.deviceTag])
    
    type CompareInfoModalProps = {
        onYes: () => void
    }
    const CompareInfoModal:React.ComponentType<CompareInfoModalProps> = React.useCallback(({onYes}) => {
        return (
            <div>
                <div className={'createexistingdevice-cancel-div'}>
                    <IconButton>
                        <Close onClick={() => closeExistingDeviceModal()}/>
                    </IconButton>
                </div>
                <div className={'createexistingdevice-text-div-left'}>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '15px'}}>
                        {getLocalizedString('createDevice.existingDevice.CompareInfoModal.title1', 'Could these devices be the same? The Serial Number on the ')} <span style={{fontWeight: 'bold'}}>{getLocalizedString('createDevice.existingDevice.CompareInfoModal.title2', 'matching device ')}</span>
                        {getLocalizedString('createDevice.existingDevice.CompareInfoModal.title3', 'may have a typo')}
                    </div>
                    <div className={'createexistingdevice-comparedevice-div'}>
                        <div className={'createexistingdevice-comparedevice-data-div'}>
                            <div className={'createexistingdevice-comparedevice-title'}>
                                {getLocalizedString('createDevice.existingDevice.CompareInfoModal.entered', 'Your Entry')}
                            </div>
                            <div className={'createexistingdevice-comparedevice-data-title'}>
                                {getLocalizedString('createDevice.deviceTag', 'Serial Number')}
                            </div>
                            <div className={'createexistingdevice-comparedevice-data'}>
                                {formDevice.deviceTag}
                            </div>
                            <div className={'createexistingdevice-comparedevice-data-title'}>
                                {getLocalizedString('createDevice.deviceIdNumber', 'Device ID')}
                            </div>
                            <div className={'createexistingdevice-comparedevice-data'}>
                                {formDevice.deviceIdNumber}
                            </div>
                        </div>
                        <div className={'createexistingdevice-comparedevice-data-div'} style={{marginLeft: '40px'}}>
                            <div className={'createexistingdevice-comparedevice-title'}>
                                {getLocalizedString('createDevice.existingDevice.CompareInfoModal.existing', 'Matching Device')}
                            </div>
                            <div className={'createexistingdevice-comparedevice-data-title'}>
                                {getLocalizedString('createDevice.deviceTag', 'Serial Number')}
                            </div>
                            <div className={'createexistingdevice-comparedevice-data'}>
                                {deviceContext.existingDeviceDto?.deviceDto?.deviceTag}
                            </div>
                            <div className={'createexistingdevice-comparedevice-data-title'}>
                                {getLocalizedString('createDevice.deviceIdNumber', 'Device ID')}
                            </div>
                            <div className={'createexistingdevice-comparedevice-data'}>
                                {deviceContext.existingDeviceDto?.deviceDto?.deviceIdNumber}
                            </div>
                            <div className={'createexistingdevice-comparedevice-data-title'}>
                                {getLocalizedString('viewDevices.status', 'Status')}
                            </div>
                            <div className={'createexistingdevice-comparedevice-data'}>
                                {deviceContext.existingDeviceDto?.deviceDto && getStatusDisplay(deviceContext.existingDeviceDto?.deviceDto)}
                            </div>
                            {existingDeviceAtDiffLocation(deviceContext.existingDeviceDto) &&
                            <>
                                <div className={'createexistingdevice-comparedevice-data-title'}>
                                    {getLocalizedString('viewDevices.filter.location', 'Location')}
                                </div>
                                <div className={'createexistingdevice-comparedevice-data'} style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                    {deviceContext.existingDeviceDto?.deviceDto && deviceContext.existingDeviceDto.locationName}
                                </div>
                            </>
                            }
                        </div>
                    </div>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '15px', marginTop: '15px', fontWeight: 'bold', textAlign: 'center'}}>
                        {getLocalizedString('createDevice.existingDevice.CompareInfoModal.same', 'Do you think these devices are the same?')}
                    </div>
                </div>
                <div className={'createexistingdevice-button-div'} style={{justifyContent: 'center', padding: '12px'}}>
                    <ARCButton
                        fill={'filled'}
                        variant={'primary'}
                        size={'md'}
                        onClick={onYes}
                        label={getLocalizedString('createDevice.existingDevice.CompareInfoModal.confirm', 'Yes')}
                    >
                    </ARCButton>
                    <ARCButton
                        fill={'outlined'}
                        variant={'secondary'}
                        size={'md'}  
                        onClick={() => {
                            setPageNumber(4);
                        }}
                        label={getLocalizedString('createDevice.existingDevice.CompareInfoModal.cancel', 'I\'m Not Sure')}
                    >
                    </ARCButton>
                </div>
            </div>
        )
    },[formDevice.deviceTag, formDevice.deviceIdNumber, deviceContext.existingDeviceDto, closeExistingDeviceModal])
    
    type SameIdDifTagSameLocationModal3Props = {}
    const SameIdDifTagSameLocationModal3:React.ComponentType<SameIdDifTagSameLocationModal3Props> = React.useCallback(() => {
        return (
            <div>
                <div className={'createexistingdevice-cancel-div'}>
                    <IconButton>
                        <Close onClick={() => closeExistingDeviceModal()}/>
                    </IconButton>
                </div>
                <div className={'createexistingdevice-text-div'}>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '30px'}}>
                        {getLocalizedString('createDevice.existingDevice.SameIdDifTagSameLocationModal3.title', 'Great, Serial Number has been updated to ' + formDevice.deviceTag, [`${formDevice.deviceTag}`])}
                    </div>
                </div>
                <div className={'createexistingdevice-button-div'}>
                    <ARCButton
                        fill={'filled'}
                        variant={'primary'}
                        size={'md'} 
                        onClick={() => {
                            navigate(`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`, 
                                {state: {
                                            editingFromDeviceId: deviceContext.savedDeviceId, 
                                            editingFromDeviceIdNumber: formDevice.deviceIdNumber, 
                                            editDeviceFrom: 'create',
                                            createDeviceSuccessMessage: getLocalizedString('createDevice.existingDevice.SameIdDifTagSameLocationModal3.success', 'successfully updated'),
                                    }});
                            dispatch(deviceContextActions.resetSaveDevice());
                        }}
                        label={getLocalizedString('createDevice.existingDevice.SameIdDifTagSameLocationModal3.confirm', 'Back to Devices')}
                    >
                    </ARCButton>
                </div>
            </div>
        )
    },[closeExistingDeviceModal, 
            formDevice.deviceTag, 
            navigate, 
            dispatch,
            context.currentLocation?.locationId,
            context.currentBrand?.brandId,
            deviceContext.savedDeviceId,
            formDevice.deviceIdNumber])
    
    type SameIdDifTagSameLocationInactiveModal3Props = {}
    const SameIdDifTagSameLocationInactiveModal3:React.ComponentType<SameIdDifTagSameLocationInactiveModal3Props> = React.useCallback(() => {
        return (
            <div>
                <div className={'createexistingdevice-cancel-div'}>
                    <IconButton>
                        <Close onClick={() => closeExistingDeviceModal()}/>
                    </IconButton>
                </div>
                <div className={'createexistingdevice-text-div'}>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '30px'}}>
                        {getLocalizedString('createDevice.existingDevice.SameIdDifTagSameLocationInactiveModal3.title1', 'Got it! In that case, please click Next')}
                        <br/>
                        {getLocalizedString('createDevice.existingDevice.SameIdDifTagSameLocationInactiveModal3.title2', 'to ')}  <span style={{fontWeight: 'bold'}}>{getLocalizedString('createDevice.existingDevice.SameIdDifTagSameLocationInactiveModal3.title3', 'view deactivation details ')}</span> {getLocalizedString('createDevice.existingDevice.SameIdDifTagSameLocationInactiveModal3.title4', 'and')} <span style={{fontWeight: 'bold'}}>{getLocalizedString('createDevice.existingDevice.SameIdDifTagSameLocationInactiveModal3.title5', 'reactivate device: ')}</span>
                    </div>
                </div>
                <div className={'createexistingdevice-button-div'}>
                    <ARCButton
                        fill={'filled'}
                        variant={'primary'}
                        size={'md'}
                        onClick={() => {
                            navigate(`/devices/reactivate/locationId=${context.currentLocation?.locationId}/deviceId=${deviceContext.existingDeviceDto?.deviceDto?.deviceId}`, 
                            {state: {
                                portalUploadStatusId: deviceContext.importResult?.portalUploadStatusId,
                                duplicateListUpdated: duplicateListUpdated
                            }})
                        }}
                        label={getLocalizedString('next', 'Next')}
                    >
                    </ARCButton>
                </div>
            </div>
        )
    },[closeExistingDeviceModal, navigate, deviceContext.existingDeviceDto?.deviceDto?.deviceId, context.currentLocation?.locationId, deviceContext.importResult?.portalUploadStatusId, duplicateListUpdated])
    
    type SameIdDifTagModal4Props = {}
    const SameIdDifTagModal4:React.ComponentType<SameIdDifTagModal4Props> = React.useCallback(() => {
        return (
            <div>
                <div className={'createexistingdevice-cancel-div'}>
                    <IconButton>
                        <Close onClick={() => closeExistingDeviceModal()}/>
                    </IconButton>
                </div>
                <div className={'createexistingdevice-text-div'}>
                    <div className={'createexistingdevice-title-text'} style={{marginBottom: '30px'}}>
                        {getLocalizedString('createDevice.existingDevice.SameIdDifTagModal4.title', 'Got it! In that case, please apply a new Device ID')}
                    </div>
                </div>
                <div className={'createexistingdevice-button-div'}>
                    <ARCButton
                        fill={'filled'}
                        variant={'primary'}
                        size={'md'} 
                        onClick={() => {
                            closeExistingDeviceModal();
                        }}
                        label={getLocalizedString('createDevice.existingDevice.SameIdDifTagModal4.confirm', 'Edit Device Details')}
                    >
                    
                    </ARCButton>
                </div>
            </div>
        )
    },[closeExistingDeviceModal])
    
    const sameTagIdLocation = React.useMemo(() => {
        return (
            <SameTagIdLocationInactiveModal/>
        )
    }, [SameTagIdLocationInactiveModal]);
    
    const sameTagIdDifLocation = React.useMemo(() => {
        if (deviceContext.existingDeviceDto?.sameTagIdDifLocation) {
            if (deviceContext.existingDeviceDto.deviceDto?.status !== DEV_STATUS_INACTIVE) {
                return (
                    <SameTagIdDifLocationModal/>
                )
            } else {
                return (
                    <SameTagIdDifLocationInactiveModal/>
                )
            }
        }
        return <></>;
    }, [deviceContext.existingDeviceDto, SameTagIdDifLocationInactiveModal, SameTagIdDifLocationModal])
    
    const sameTagIdOnDifferentDevices = React.useMemo(() => {
        if (deviceContext.existingDeviceDto?.sameTagIdOnDifferentDevices) {
            if (pageNumber === 0) {
                return (
                    <SerialMatchModal 
                        onClick={() => {
                            setPageNumber(1);
                        }} 
                        hasLocation={!!deviceContext.existingDeviceDto.locationName}/>
                )
            }
            if (pageNumber === 1) {
                return (
                    <SameIdDifTagModal4/>
                )
            }
        }
        return <></>;
    }, [deviceContext.existingDeviceDto, 
            SerialMatchModal, 
            pageNumber, 
            SameIdDifTagModal4,])
    
    const sameTagDifIdSameLocation = React.useMemo(() => {
        if (deviceContext.existingDeviceDto?.sameTagDifIdSameLocation) {
            if (deviceContext.existingDeviceDto.deviceDto?.status !== DEV_STATUS_INACTIVE) {
                return (
                    <SerialMatchModal 
                    onClick={() => {
                        setCreateDeviceSuccessMessage(getLocalizedString('createDevice.existingDevice.serialMatch.success', 'successfully updated'))
                        transferExistingDevice(true)
                    }} 
                    hasLocation={false}/>
                )
            } else {
                if (pageNumber === 0) {
                    return (
                        <SerialMatchModal 
                            onClick={() => {
                                setPageNumber(1);
                                updateExistingDevice("deviceIdNumber");
                            }} 
                            hasLocation={false}/>
                    )
                }
                if (pageNumber === 1) {
                    return (
                        <SameTagDifIdSameLocationInactiveModal1/>
                    )
                }
            }
        }
        return <></>;
    }, [deviceContext.existingDeviceDto, 
            SerialMatchModal, 
            pageNumber, 
            SameTagDifIdSameLocationInactiveModal1, 
            setCreateDeviceSuccessMessage,
            transferExistingDevice, 
            updateExistingDevice])
    
    const sameTagDifIdDifLocation = React.useMemo(() => {
        if (deviceContext.existingDeviceDto?.sameTagDifIdDifLocation) {
            if (deviceContext.existingDeviceDto.deviceDto?.status !== DEV_STATUS_INACTIVE) {
                if (pageNumber === 0) {
                    return (
                        <SerialMatchModal 
                            onClick={() => {
                                setPageNumber(1);
                            }} 
                            hasLocation={true}/>
                    )
                }
                if (pageNumber === 1) {
                    return (
                        <SameTagDifIdModal1/>
                    )
                }
            } else {
                if (pageNumber === 0) {
                    return (
                        <SerialMatchModal 
                            onClick={() => {
                                setPageNumber(1);
                            }} 
                            hasLocation={true}/>
                    )
                }
                if (pageNumber === 1) {
                    return (
                        <SameTagDifIdDifLocationInactiveModal1/>
                    )
                }
            }
        }
        return <></>;
    }, [deviceContext.existingDeviceDto, pageNumber, SerialMatchModal, SameTagDifIdDifLocationInactiveModal1, SameTagDifIdModal1])
    
    const sameIdDifTagSameLocation = React.useMemo(() => {
        if (deviceContext.existingDeviceDto?.sameIdDifTagSameLocation) {
            if (pageNumber === 0) {
                return (
                    <SameIdDifTagModal0/>
                )
            }
            if (pageNumber === 1) {
                return (
                    <DoubleCheckInfoModal/>
                )
            }
            if (pageNumber === 2) {
                return (
                    <CompareInfoModal onYes={() => {
                        setPageNumber(3);
                    }}/>
                )
                
            }
            if (pageNumber === 3) {
                if (deviceContext.existingDeviceDto.deviceDto?.status !== DEV_STATUS_INACTIVE) {
                    return (
                        <SameIdDifTagSameLocationModal3/>
                    )
                } else {
                    return (
                        <SameIdDifTagSameLocationInactiveModal3/>
                    )
                }
            }
            if (pageNumber === 4) {
                return (
                    <SameIdDifTagModal4/>
                )
            }
        }
        return <></>;
    }, [deviceContext.existingDeviceDto,
              pageNumber,
              CompareInfoModal, 
              DoubleCheckInfoModal, 
              SameIdDifTagModal0, 
              SameIdDifTagModal4, 
              SameIdDifTagSameLocationInactiveModal3,
              SameIdDifTagSameLocationModal3,])
    
    const sameIdDifTagDifLocation = React.useMemo(() => {
        if (deviceContext.existingDeviceDto?.sameIdDifTagDifLocation) {
            if (pageNumber === 0) {
                return (
                    <SameIdDifTagModal0/>
                )
            }
            if (pageNumber === 1) {
                return (
                    <DoubleCheckInfoModal/>
                )
            }
            if (pageNumber === 2) {
                return (
                    <CompareInfoModal onYes={() => {
                        setPageNumber(3);
                    }}/>
                )
            }
            if (pageNumber === 3) {
                return (
                    <SameIdDifTagModal3/>
                )
            }
        }
        return <></>;
    }, [deviceContext.existingDeviceDto, 
              pageNumber,
              CompareInfoModal,
              DoubleCheckInfoModal,
              SameIdDifTagModal0,
              SameIdDifTagModal3])
    
    return (
        deviceContext.existingDeviceDto ?
        <>
            {deviceContext.existingDeviceDto.sameTagIdLocation ?
                deviceContext.existingDeviceDto.deviceDto?.status === DEV_STATUS_INACTIVE ?
                    <div className={'createexistingdevice-modal'} ref={modalRef}>
                        {sameTagIdLocation}
                    </div>
                    :
                    null
                :
                <div className={'createexistingdevice-modal'} ref={modalRef}>
                    {deviceContext.existingDeviceDto.sameTagIdLocation ?
                        sameTagIdLocation
                        :
                        <>
                            {sameTagIdDifLocation}
                            {sameTagIdOnDifferentDevices}
                            {sameTagDifIdSameLocation}
                            {sameTagDifIdDifLocation}
                            {sameIdDifTagSameLocation}
                            {sameIdDifTagDifLocation}
                        </>
                    }
                </div>
            }
        </>
        :
        <></>
    )
}
