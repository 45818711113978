import * as React from 'react';
import {useAppSelector} from "../../../store/hooks";
import {Link} from "react-router-dom";
import {appContextSelector} from "../../app-context/appContextSlice";

type EmployeeDuplicateLinkProps = {
    err: string
}

export const EmployeeDuplicateLink:React.ComponentType<EmployeeDuplicateLinkProps> = ({err}) => {

    const appContext = useAppSelector(appContextSelector);

    if(err.includes('DUPLICATE')) {
        const errParts = err.split('DUPLICATE');

        const duplicateEmployeeId = errParts[1];

        if (duplicateEmployeeId) {
            return (
                <>
                    <Link target={'_self'} to={`/employees/edit/locationId=${appContext.currentLocation?.locationId}/brandId=${appContext.currentBrand?.brandId}/employeeId=${duplicateEmployeeId}`}>
                        {errParts[0]}
                    </Link>
    
                    {errParts[2]}
                </>
            )
        } else {
            return <>{errParts[0]}</>;
        }
    } else {
        return <>{err}</>;
    }
}
