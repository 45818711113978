import {Error, Warning } from '@material-ui/icons';
import * as React from 'react';
import {ErrorMessageSection, SuccessMessageSection } from './StyledComponents';
import {DARK_GREEN, LIGHT_RED} from "../../themes/colors";
import {Close} from "@material-ui/icons";

type ServerMessageProps = {
    variant: 'success' | 'error',
    closable?: {
        isOpen: boolean,
        onClose: () => void
    }
}

export const ServerMessage:React.ComponentType<ServerMessageProps> = ({variant, closable, children}) => {
    return (
        !closable || closable.isOpen ?
        (variant === 'success' ?
            <SuccessMessageSection>
                <Error style={{marginRight: '16px', color: DARK_GREEN}}/>
                {children}

                {closable &&
                <div style={{display: 'flex', flexGrow: '1', justifyContent: 'flex-end'}}>
                    <Close onClick={() => closable.onClose()}>
                    </Close>
                </div>
                }
            </SuccessMessageSection>
            :
            <ErrorMessageSection>
                <Warning style={{marginRight: '16px', color: LIGHT_RED}}/>
                {children}

                {closable &&
                <Close onClick={() => closable.onClose()}>
                </Close>
                }
            </ErrorMessageSection>)
            :
            null
    )
}
