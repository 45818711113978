import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import {ARCIcon} from "./ARCIcon";
import {getLocalizedString} from "../../../util/Localization";

type ARCBannerProps = {
    message: string,
    size: 'sm' | 'md',
    hideIcon?: boolean,
    accentedMessage?: boolean,
    onClose?: () => void
}

export const ARCBanner:React.ComponentType<ARCBannerProps> = (props) => {
    return (
        <div className={`o-banner o-banner--${props.size} o-banner--info`}>
            <div className={'o-banner__content'}>
                <div className={'o-banner__content--padded-top'}>

                    {!props.hideIcon &&
                        <ARCIcon icon={'alert'} size={'md'} color={'var(--color-blue-50)'}/>
                    }

                    <div className={'o-banner__text'}>
                        <ReactMarkdown components={{ a: function LinkRenderer(props) {
                            return (
                                <a className={'o-banner__link--text'} href={props.href?.includes('http') ? props.href : ('https://' + props.href)} target={'_blank'} rel={'noopener noreferrer'}>
                                    {props.children}
                                </a>
                            );
                        },
                        p: function LinkRenderer(linkProps) {
                            return (
                                <div className={props.accentedMessage ? 'o-banner__text--accented' : 'o-banner__text'}>
                                    {linkProps.children}
                                </div>
                            );
                        }}}>
                            {props.message}
                        </ReactMarkdown>
                    </div>
                </div>

                {props.onClose &&
                    <button className={'o-textlink o-textlink--s o-textlink--primary'} id={'arc-banner-close-link'} onClick={props.onClose}>
                        <ARCIcon icon={'close'} size={'md'}/>

                        {getLocalizedString('close', 'Close')}
                    </button>
                }
            </div>
        </div>
    );
}
