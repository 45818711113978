import {createAsyncThunk} from "@reduxjs/toolkit";
import {appContextActions, locationByIdSelector} from "../appContextSlice";
import {AllLocationsDto} from "../../../dtos/Location";

export const selectLocationThunk = createAsyncThunk(
    `CLIENTPORTAL-SELECTLOCATION`,
    async (locationId: number, {getState, dispatch, extra}) =>
    {
        let location;
        if(locationId === -1) {
            location = AllLocationsDto;
        }
        else {
            location = locationByIdSelector(locationId) (getState());
        }

        dispatch(appContextActions.setUnauthorizedAccess(false));

        if(location) {
            dispatch(appContextActions.setCurrentLocation(location));
        }
    }
);