import {createAsyncThunk} from "@reduxjs/toolkit";
import {appContextActions, brandByIdSelector} from "../appContextSlice";
import {employeeContextActions} from "../../employees/employeeSlice";
import {deviceContextActions} from "../../devices/deviceSlice";

export const selectBrandThunk = createAsyncThunk(
    `CLIENTPORTAL-SELECTBRAND`,
    async (brandId: number, {getState, dispatch, extra}) =>
    {
        const brand = brandByIdSelector(brandId) (getState());
        dispatch(appContextActions.setUnauthorizedAccess(false));

        if(brand) {
            dispatch(appContextActions.setCurrentBrand(brand));
            dispatch(employeeContextActions.clearEmployeeFilter()); // TODO: there is probably a better way to clear data when switching brands
            dispatch(deviceContextActions.clearDeviceFilter());
        }
    }
);
