import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createDeepEqualSelector, RootState} from "../../store/types";
import {EmployeeGroupDto} from "../../dtos/EmployeeGroup";
import {get} from "../app-context/thunks/API_Thunks";

export const employeeGroupSliceName = 'employeeGroupSlice';

export interface EmployeeGroupState {
    employeeGroupsMap: EmployeeGroupDto[]
}

const initialState: EmployeeGroupState = {
    employeeGroupsMap: []
}

export const getEmployeeGroups = createAsyncThunk(
    `${employeeGroupSliceName}/findall`,
    async ({arg} : {arg?: undefined}, {dispatch}) => {
        try {
            const findAll = await dispatch(get({urlSuffix: '/clientportal/employeegroup/findall', params: null}));
            // console.log({findAll});
            return findAll;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
)

export const employeeGroupSlice = createSlice({
    name: employeeGroupSliceName,
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEmployeeGroups.pending, (state) => {
                // console.log('getEmployeeGroups.pending');
            })
            .addCase(getEmployeeGroups.rejected, (state, action) => {
                // console.log('getEmployeeGroups.rejected');
            })
            .addCase(getEmployeeGroups.fulfilled, (state, action) => {
                // console.log('getEmployeeGroups.fulfilled');
                if(action.payload.payload) {
                    action.payload.payload.forEach((dto: EmployeeGroupDto) => {
                        if (state.employeeGroupsMap[dto.employeeGroupId] === undefined) {
                            state.employeeGroupsMap[dto.employeeGroupId] = {...dto};
                        }
                    })
                }
            })
    }
})
export const employeeGroupSelector = (state: RootState) => state[employeeGroupSliceName];

export const employeeGroupSortedSelector = createDeepEqualSelector(
    employeeGroupSelector,
    ({employeeGroupsMap}) => {
        return (employeeGroupsMap?.slice() ?? []).sort((g1, g2) => {
            return g1.accessLevel > g2.accessLevel ? 1 : -1;
        })
    }
)

export const employeeGroupActions = employeeGroupSlice.actions;
export default employeeGroupSlice.reducer;
