import Button from '@material-ui/core/Button';
import styled from '@material-ui/core/styles/styled';
import * as React from 'react';
import {useNavigate} from "react-router-dom";
import ARCLOGO_BLACK from "../../assets/img/ARC_Logo_Black.png";
import {HELP_EMAIL, HELP_PHONE} from "../../constants/StringConstants";
import {getLocalizedString} from "../../util/Localization";

export const UnauthenticatedPage = () => {
    const navigate = useNavigate();

    return (
        <div className={'container'}>
            <div className={'new-account-page-body-sm'}>

                <div className={'new-account-header'}>
                    <div className={'new-account-logo-div'}>
                        <img className={'new-account-login-logo'} src={ARCLOGO_BLACK}
                             alt="ARC Logo"/>
                        <div className={'new-account-logo-typography'}>{getLocalizedString('title', 'Client Portal')}</div>
                    </div>
                </div>

                <br/>

                <div className={'new-account-title-full'}>
                    <div className={'new-account-title'} style={{ maxWidth: '500px'}}>
                        {getLocalizedString('unauthenticated.title', 'Your session has timed out.')}
                    </div>

                    <div className={'new-account-title'} style={{ width: '80%', marginTop: '0px', textAlign: 'center'}}>
                        {getLocalizedString('unauthenticated.reauthenticate', 'Please log in again to continue working')}
                    </div>
                </div>

                <LoginButton style={{minWidth: '150px'}} onClick={() => navigate('/login')}>
                    {getLocalizedString('unauthenticated.login', 'Log In')}
                </LoginButton>

            </div>


            <div className={'tool-tip'} style={{marginTop: '6%'}}>
                {getLocalizedString('support', 'ARC Support') + ' | '+ getLocalizedString('support.email', HELP_EMAIL) + ' | ' + getLocalizedString("support.phone", HELP_PHONE)}
            </div>
        </div>
    );
}

const LoginButton = styled(Button) (({theme}) => ({
    height: '5vw',
    maxHeight: '60px',
    width: '20vw',
    minWidth: '0px !important',
    maxWidth: '174px',
    margin: '10px 0px 30px 0px',
    padding: '0px 10px 0px 10px',
    borderRadius: '30px',
    borderWidth: '0',
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#000000',
    background: '#FBBC41',
    letterSpacing: '0',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#FBBC41',
        opacity: '.5'
    },
    [theme.breakpoints.down('md')]: {
        width: '60vw',
        maxWidth: '97px',
        height: '10vh',
        maxHeight: '28px',
        fontSize: '14px',
        margin: '0px 0px 40px 0',
        padding: '0px 10px 0px 10px',
    }
}));