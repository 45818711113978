import {MenuItem, styled} from '@material-ui/core';
import { ArrowForwardRounded } from '@material-ui/icons';
import React from 'react';
import {BLACK, WHITE} from "../../themes/colors";
import {useTheme} from '@material-ui/core';
import {calculatedFontSize} from "../../util/TextUtils";
import { BrandDto } from '../../dtos/Brand';

type BrandItemProps = {
    brand: BrandDto
    onClick: () => void
}


export const BrandListItem : React.ComponentType<BrandItemProps> = (props) => {
    const theme = useTheme();

    const [iconColor, setIconColor] = React.useState<string>(theme.palette.primary.main);

    return (
        <BrandItem onMouseOver={() => {setIconColor(BLACK)}} onMouseLeave={() => setIconColor(theme.palette.primary.main)} onClick={props.onClick} key={props.brand.brandId}>
            <BrandIcon style={{backgroundColor: iconColor}}/>
            {props.brand.brandName}
        </BrandItem>
    )
}

const BrandIcon = styled(ArrowForwardRounded)(({theme}) => ({
    color: WHITE,
    borderRadius: '15px',
    marginRight: '10px',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: BLACK,
    },
}));

const BrandItem = styled(MenuItem)(({theme}) => ({
    font: 'normal normal normal 22px/28px Proxima Nova',
    fontSize: calculatedFontSize('1.3em', '1.6em'),
    cursor: 'pointer',
    marginBottom: '1.5vh',
    [`${theme.breakpoints.down('xs')}`]: {
        fontSize: calculatedFontSize('1em', '1.4em'),
    }
}));