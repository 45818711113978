import * as React from 'react';

type ARCFormErrorProps = {
    size: 'sm' | 'md',
    errorMessage: string
}

export const ARCFormError : React.FC<ARCFormErrorProps> = (props) => {

    return (
        <div className={`o-form-error__container o-form-error__container--${props.size}`}>
            <div className={`o-form-error__message o-form-error__message--${props.size}`}>
                {props.errorMessage}
            </div>
        </div>
    )
}