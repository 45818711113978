import * as React from 'react';
import AppBar from "../../appbar/AppBar";
import {SideBar} from "../../sidebar/SideBar";
import {FormControl} from "@material-ui/core";
import {styled} from "@material-ui/core/styles";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {appContextActions, appContextSelector, changePassword} from "../../app-context/appContextSlice";
import {getPasswordStrength, isPasswordStrong, passwordsMatch} from "../../../util/PasswordUtil";
import {PasswordUpdateDto} from "../../../dtos/PasswordUpdateDto";
import {AppWithFooter} from "../../../App";
import {
    CancelButton,
    SaveButton,
    ResponsiveTextField,
    FormTitle, ResponsiveInputLabelThin, ErrorMessageSectionItem
} from "../../ui-components/StyledComponents";
import {ServerMessage} from "../../ui-components/ServerMessage";
import {getLocalizedString} from "../../../util/Localization";
import "./ChangePassword.scss"

export const ChangePassword = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);

    const [changePasswordForm, setChangePasswordForm] = React.useState<PasswordUpdateDto>({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        passwordStrength: 0
    });

    const [formErrors, setFormErrors] = React.useState<string | undefined>(undefined);

    const submitForm = React.useCallback( async (event : React.FormEvent) => {
        event.preventDefault();

        if(passwordsMatch(changePasswordForm.newPassword, changePasswordForm.confirmNewPassword)) {
            setFormErrors('');

            if(isPasswordStrong(changePasswordForm.newPassword)) {
                await dispatch(changePassword({passwordUpdateDto: changePasswordForm}))
            }
            else {
                setFormErrors(getLocalizedString('changepassword.weak', 'New password does not meet security requirements') + '\n');
            }
        }
        else {
            setFormErrors(getLocalizedString('changepassword.mismatch', 'Passwords do not match. Please try again.') + '\n');
        }

    }, [changePasswordForm, dispatch]);

    React.useEffect(() => {
        if(context.changePasswordSuccessful) {
            dispatch(appContextActions.resetChangePassword());

            if(context.currentLocation?.locationId) {
                navigate(`/employees/view/locationId=${context.currentLocation?.locationId}`, {state: {successmessage: 'Password Updated!'}});
            }
            else {
                navigate(`/home`, {state: {successmessage: getLocalizedString('changepassword.success', 'Password Updated!')}});
            }
        }
    }, [dispatch, navigate, context.changePasswordSuccessful, context.currentLocation?.locationId]);

    return (
        <AppWithFooter>
            <AppBar/>
            <SideBar/>
            <main className={'o-main'}>
                <div className={'l-wrap l-container--lg'}>
                    <form onSubmit={submitForm} style={{width: '100%'}}>
                        {context.changePasswordErrorMessage &&
                            <ServerMessage variant={'error'}>
                                <ErrorMessageSectionItem>
                                    {context.changePasswordErrorMessage}
                                </ErrorMessageSectionItem>
                            </ServerMessage>
                        }

                        {formErrors &&
                            <ServerMessage variant={'error'}>
                                <ErrorMessageSectionItem>
                                    {formErrors}
                                </ErrorMessageSectionItem>
                            </ServerMessage>}

                        <FormTitle variant='h5'>{getLocalizedString('changepassword.title', 'CHANGE YOUR PASSWORD')}</FormTitle>
                        <RowFormControl>
                            <ResponsiveInputLabelThin>{getLocalizedString('changepassword.current', 'Current Password:')}<span style={{color: 'red'}}>*</span></ResponsiveInputLabelThin>

                            <ResponsiveTextField
                                InputProps={{disableUnderline: true}}
                                fullWidth
                                autoComplete={"current password"}
                                id="changepass-currentpass"
                                type='password'
                                required={true}
                                onChange={(event) => {
                                    setChangePasswordForm({
                                        ...changePasswordForm,
                                        oldPassword: event.target.value
                                    })
                                }}
                            />
                        </RowFormControl>

                        <RowFormControl>
                            <ResponsiveInputLabelThin>{getLocalizedString('changepassword.new', 'New Password:')}<span style={{color: 'red'}}>*</span></ResponsiveInputLabelThin>

                            <ResponsiveTextField
                                InputProps={{disableUnderline: true}}
                                fullWidth
                                autoComplete={"new password"}
                                id="changepass-newpass"
                                type='password'
                                required={true}
                                onChange={(event) => {
                                    setChangePasswordForm({
                                        ...changePasswordForm,
                                        newPassword: event.target.value,
                                        passwordStrength: getPasswordStrength(event.target.value)
                                    })
                                }}
                            />
                        </RowFormControl>

                        <RowFormControl>
                            <ResponsiveInputLabelThin>{getLocalizedString('changepassword.confirm', 'Confirm Password:')}<span style={{color: 'red'}}>*</span></ResponsiveInputLabelThin>

                            <ResponsiveTextField
                                InputProps={{disableUnderline: true}}
                                fullWidth
                                autoComplete={"confirm password"}
                                id="changepass-confirmnewpass"
                                type='password'
                                required={true}
                                onChange={(event) => {
                                    setChangePasswordForm({
                                        ...changePasswordForm,
                                        confirmNewPassword: event.target.value
                                    })
                                }}
                            />
                        </RowFormControl>

                        <div className={'change-password__instruction'}>
                            {getLocalizedString('changepassword.requirements', 'To meet security requirements, your password should (1) have a minimal length of 8; (2) contain both numeric and alphabetic uppercase characters; (3) and not be any of the last 4 passwords used.')}
                        </div>

                        <div className={'change-password__button-div'}>
                            <CancelButton
                                id="changepassword-cancel"
                                variant='contained'
                                onClick={() => {navigate(-1)}}
                            >
                                {getLocalizedString('changepassword.cancel', 'Cancel')}
                            </CancelButton>
                            <SaveButton
                                id="changepassword-save"
                                variant='contained'
                                type='submit'
                            >
                                {getLocalizedString('changepassword.submit', 'Save And Exit')}
                            </SaveButton>
                        </div>
                    </form>
                </div>
            </main>
        </AppWithFooter>
    )
}

const RowFormControl = styled(FormControl)(() => ({
    display: 'flex',
    flexDirection: 'row',
}));
