import React, {Dispatch, SetStateAction} from "react";
import {ArrowBack} from "@material-ui/icons";
import {IconButton} from "@material-ui/core";
import {CircleLabelMd, ColoredLink, FormTitle} from "../StyledComponents";
import "../css/ImportComponent.css"
import {calculateResponsiveSizeWithMinMax} from "../../../util/TextUtils";
import {FileDropzone} from "../FileDropzone";
import {
    downloadEmployeeImportTemplate,
} from "../../employees/employeeSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {appContextSelector} from "../../app-context/appContextSlice";
import {
    devicePickupPermissionContextSelector,
    viewAllDevicePickupPermissions
} from "../../devices/devicePickupPermissionSlice";
import {downloadDeviceImportTemplate} from "../../devices/deviceSlice";
import {PORTAL_UPLOAD_STATUS_STATUSES, PortalUploadStatusDto} from "../../../dtos/PortalUploadStatus";
import {DialogAlert} from "../DialogAlert";
import {getLocalizedString} from "../../../util/Localization";
import {ARCButton} from "../design-system/ARCButton";
import {ARCTextLink} from "../design-system/ARCTextLink";

export const ImportChildComponentNames = {
    importLanding: 'importLanding',
    uploadFilePage: 'uploadFilePage',
    validatingImport: 'validatingImport',
    reviewData: 'reviewData',
    importUploadData: 'importUploadData'
}

interface ImportUploadFileProps {
    isDeviceImport: boolean,
    setCurrentChildComponent: Dispatch<SetStateAction<string>>,
    onFileChange: React.ChangeEventHandler<HTMLInputElement>,
    uploadFile: File | undefined,
    setUploadFile: Dispatch<SetStateAction<File | undefined>>,
    previousStatus: PortalUploadStatusDto | undefined,
    idNumberRequirements: string | undefined
}

export const ImportUploadFile: React.FC<ImportUploadFileProps> = ({
    isDeviceImport, 
    setCurrentChildComponent, 
    onFileChange, 
    uploadFile, 
    setUploadFile,
    previousStatus,
    idNumberRequirements
}) => {
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    const devicePermissionsContext = useAppSelector(devicePickupPermissionContextSelector);
    
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [uploadInProgress, setUploadInProgress] = React.useState<boolean>(false);
    const [showDialog, setShowDialog] = React.useState<boolean>(false);
    
    React.useEffect(() => {
        if (context.currentLocation?.locationId && !devicePermissionsContext.permissions) {
            dispatch(viewAllDevicePickupPermissions({locationId: context.currentLocation.locationId}))
        }
    }, [context.currentLocation?.locationId, devicePermissionsContext.permissions, dispatch])
    
    React.useEffect(() => {
        if (previousStatus && (previousStatus.status === PORTAL_UPLOAD_STATUS_STATUSES.CREATED || previousStatus.status === PORTAL_UPLOAD_STATUS_STATUSES.IN_PROGRESS)) {
            setUploadInProgress(true);
        } else {
            setUploadInProgress(false);
        }
    }, [previousStatus])

    const downloadTemplate = React.useCallback(() => {
        if(context.currentLocation?.locationId && context.currentBrand) {
            if (!isDeviceImport) {
                dispatch(downloadEmployeeImportTemplate({locationId: context.currentLocation.locationId}))
            } else {
                dispatch(downloadDeviceImportTemplate({locationId: context.currentLocation.locationId}))
            }
        }
    }, [context.currentLocation?.locationId, dispatch, context.currentBrand, isDeviceImport])

    
    const locationHasDeviceTypes = React.useMemo(() => {
        return context.currentLocation?.deviceTypes && context.currentLocation?.deviceTypes.length > 0
    }, [context.currentLocation?.deviceTypes])

    const previousStatusText = React.useMemo(() => {
        if (previousStatus && previousStatus.createDateTime) {
            const date = new Date(previousStatus.createDateTime);
            //TODO localize
            const month = date.toLocaleString('en-US', { month: 'long' });
            const day = date.getDate().toString();

            const formattedDate = `(${month} ${day} at ${date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })})`;

            return formattedDate;
        }
    }, [previousStatus])

    return (
        <>
            <div className={'importuploadfile-root-div'}>
                <IconButton
                    id={`import-${isDeviceImport ? 'devices' : 'users'}-back-button`}
                    color="primary"
                    className={'back-button'}
                    aria-label="back"
                    onClick={() => {
                            setCurrentChildComponent(ImportChildComponentNames.importLanding)
                        }
                    }>
                    <ArrowBack className={'back-button-arrow'}/>
                </IconButton>
                <div className={'importuploadfile-container-div'}>
                    <DialogAlert
                        title={getLocalizedString('import.dialog.title', 'File cannot be uploaded')}
                        messages={[getLocalizedString('import.dialog.message.1', 'A file is currently being imported'), getLocalizedString('import.dialog.message.2', 'Please wait until the current file is finished importing to upload another file')]}
                        isOpen={showDialog}
                        isError={true}
                    >
                        <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
                                <button className={'dialog-alert-button confirm'} onClick={() => {
                                    setShowDialog(false)
                                }}>
                                    {getLocalizedString('ok', 'OK')}
                                </button>
                            </div>
                        </div>
                    </DialogAlert>
                    <FormTitle>
                        {getLocalizedString('import.upload', 'Upload File')}
                    </FormTitle>
                    {previousStatus &&
                        <div className={'importuploadfile-row-div'} style={{marginTop: '40px'}}>
                            <ARCTextLink
                                variant={'primary'}
                                size={'md'}
                                label={getLocalizedString('import.viewPrevious', 'View status of most recent import')}
                                href={''}
                                onClick={() => {
                                    setCurrentChildComponent(ImportChildComponentNames.importUploadData)
                                    setUploadFile(undefined);
                                }}>
                            </ARCTextLink>
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontStyle: 'italic',
                                    fontSize: calculateResponsiveSizeWithMinMax('3', '18', '16')
                                }}
                            >
                                {previousStatusText}
                            </span>
                        </div>
                    }
                    <div className={'importuploadfile-row-div'}>
                        <div className={'importuploadfile-help-text-circle'}>
                            <CircleLabelMd>{'1'}</CircleLabelMd>
                        </div>
                        <div>
                            <div className={'field-text'} style={{marginBottom: '16px'}}>
                                {getLocalizedString('import.downloadTemplate', 'Download the import template:')}
                            </div>
                            <ARCButton
                                id={`import-${isDeviceImport ? 'devices' : 'users'}-download-template-link`}
                                fill={'filled'}
                                variant={'primary'}
                                size={'md'}
                                onClick={() => downloadTemplate()}
                                label={getLocalizedString('import.downloadTemplate.button', 'Download Template')}
                            >
                            </ARCButton>
                        </div>
                    </div>
                    <div className={'importuploadfile-row-div'}>
                        <div className={'importuploadfile-help-text-circle'}>
                            <CircleLabelMd>{'2'}</CircleLabelMd>
                        </div>
                        <div>
                            <div className={'field-text'} style={{marginBottom: '32px'}}>
                                {getLocalizedString('import.fillOutTemplate', 'Fill out the import template. Refer to the instructions below for how to complete the template:')}
                            </div>
                            {!isDeviceImport ?
                                <table className={'importuploadfile-table'}>
                                    <thead className={'importuploadfile-table-header-row'}>
                                    <tr>
                                        <th className={'importuploadfile-table-header-cell'}>
                                        </th>
                                        <th className={'importuploadfile-table-header-cell'}>
                                            {getLocalizedString('employee.firstName', 'First Name')}
                                            <span className={'required-star'}>{'*'}</span>
                                        </th>
                                        <th className={'importuploadfile-table-header-cell'}>
                                            {getLocalizedString('employee.middleName', 'Middle Initial')}
                                        </th>
                                        <th className={'importuploadfile-table-header-cell'}>
                                            {getLocalizedString('employee.lastName', 'Last Name')}
                                            <span className={'required-star'}>{'*'}</span>
                                        </th>
                                        <th className={'importuploadfile-table-header-cell'}>
                                            {getLocalizedString('employee.kioskGroup', 'Kiosk Role')}
                                            <span className={'required-star'}>{'*'}</span>
                                        </th>
                                        <th className={'importuploadfile-table-header-cell'}>
                                            {getLocalizedString('employee.badgeId', 'Badge ID')}
                                            <span className={'required-star'}>{'*'}</span>
                                        </th>
                                        <th className={'importuploadfile-table-header-cell'}>
                                            {getLocalizedString('viewEmployees.header.status', 'Status')}
                                            <span className={'required-star'}>{'*'}</span>
                                        </th>
                                        <th className={'importuploadfile-table-header-cell'}>
                                            {getLocalizedString('viewEmployees.devicePickupPermission', 'Device Group')}
                                        </th>
                                        <th className={'importuploadfile-table-header-cell'}>
                                            {getLocalizedString('employee.portalGroup', 'Client Portal Role')}
                                            <span className={'required-star'}>{'*'}</span>
                                        </th>
                                        <th className={'importuploadfile-table-header-cell'}>
                                            {getLocalizedString('employee.emailAddress', 'Email Address')}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={'importuploadfile-table-row-cell'}
                                            style={{backgroundColor: 'lightgrey'}}>
                                            {getLocalizedString('import.template.explain', 'What is this field?')}
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            {getLocalizedString('import.template.employee.firstName', 'Employee’s first name')}
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            {getLocalizedString('import.template.employee.middleName', 'Employee’s middle initial')}
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            {getLocalizedString('import.template.employee.lastName', 'Employee’s last name')}
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            {getLocalizedString('import.template.employee.kioskGroup', 'Permissions this user has at the kiosk.')}
                                            <div style={{height: '32px'}}></div>
                                            <ColoredLink
                                                id={`import-users-view-kiosk-group-image-link`}
                                                target={'_blank'}
                                                rel={'noopener noreferrer'}
                                                style={{textDecoration: 'underline', marginTop: '32px'}}
                                                href={`/faq/view/locationId=${context.currentLocation?.locationId}/openKioskRole=true`}
                                            >
                                                {getLocalizedString('import.template.employee.kioskGroup.link', 'View breakdown of kiosk roles here')}
                                            </ColoredLink>
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            <div>
                                                {getLocalizedString('import.template.employee.badgeId', 'Employee’s identification number.')}
                                            </div>
                                            <div style={{marginTop: '16px'}}>
                                                {getLocalizedString('import.template.employee.badgeId.tip', 'Optional if Kiosk Role is None')}
                                            </div>
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            {getLocalizedString('import.template.employee.status', 'Denotes the user’s status. Only active users can access the Kiosk and Client Portal.')}
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            {getLocalizedString('import.template.employee.devicePickupPermission', 'Denotes what and how many devices a user can pick up')}
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            {getLocalizedString('import.template.employee.portalGroup', 'Permissions this user has to access the Client Portal')}
                                            <div style={{height: '32px'}}></div>
                                            <ColoredLink
                                                id={`import-users-view-portal-group-image-link`}
                                                target={'_blank'}
                                                rel={'noopener noreferrer'}
                                                style={{textDecoration: 'underline', marginTop: '32px'}}
                                                href={`/faq/view/locationId=${context.currentLocation?.locationId}/openClientPortalRole=true`}
                                            >
                                                {getLocalizedString('import.template.employee.portalGroup.link', 'View breakdown of Client Portal roles here')}
                                            </ColoredLink>
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            {getLocalizedString('import.template.employee.email', 'Only required if the user has Client Portal Role')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={'importuploadfile-table-row-cell'}
                                            style={{backgroundColor: 'lightgrey'}}>
                                            {getLocalizedString('import.template.accepted', 'Accepted Values')}
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            {getLocalizedString('import.template.employee.firstName.accepted', 'Only letters, spaces, hyphens, and apostrophes are allowed')}
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            {getLocalizedString('import.template.employee.middleName.accepted', 'Only letters are allowed')}
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            {getLocalizedString('import.template.employee.lastName.accepted', 'Only letters, spaces, hyphens, and apostrophes are allowed')}
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            <ul className={'importuploadfile-table-bullet-cell'}>
                                                <li className={'field-text'}>
                                                    {getLocalizedString('employee.kioskGroup.none', 'None')}
                                                </li>
                                                <li className={'field-text'}>
                                                    {getLocalizedString('employee.kioskGroup.associate', 'Associate')}
                                                </li>
                                                <li className={'field-text'}>
                                                    {getLocalizedString('employee.kioskGroup.teamLead', 'Team Lead')}
                                                </li>
                                                <li className={'field-text'}>
                                                    {getLocalizedString('employee.kioskGroup.manager', 'Manager')}
                                                </li>
                                            </ul>
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            <div>
                                                {idNumberRequirements}
                                            </div>
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            <ul className={'importuploadfile-table-bullet-cell'}>
                                                <li className={'field-text'}>
                                                    {getLocalizedString('viewEmployees.status.active', 'Active')}
                                                </li>
                                                <li className={'field-text'}>
                                                    {getLocalizedString('viewEmployees.status.inactive', 'Inactive')}
                                                </li>
                                            </ul>

                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            <ul className={'importuploadfile-table-bullet-cell'}>
                                                {devicePermissionsContext.permissions?.map((devicePermission, idx) => {
                                                    return (
                                                        <li className={'field-text'} key={idx}>
                                                            {devicePermission.permissionName}
                                                        </li>
                                                    )
                                                })
                                                }
                                            </ul>
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            <ul className={'importuploadfile-table-bullet-cell'}>
                                                <li className={'field-text'}>
                                                    {getLocalizedString('employee.portalGroup.none', 'None')}
                                                </li>
                                                <li className={'field-text'}>
                                                    {getLocalizedString('employee.portalGroup.admin', 'Admin')}
                                                </li>
                                                <li className={'field-text'}>
                                                    {getLocalizedString('employee.portalGroup.superAdmin', 'Super Admin')}
                                                </li>
                                            </ul>
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            {getLocalizedString('import.template.employee.email.accepted', 'Must be a valid email address')}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                :
                                <table className={'importuploadfile-table'}>
                                    <thead className={'importuploadfile-table-header-row'}>
                                    <tr>
                                        <th className={'importuploadfile-table-header-cell'}>
                                        </th>
                                        <th className={'importuploadfile-table-header-cell'}>
                                            {getLocalizedString('createDevice.deviceTag.title', 'Device Serial Number')}
                                            <span className={'required-star'}>{'*'}</span>
                                        </th>
                                        <th className={'importuploadfile-table-header-cell'}>
                                            {getLocalizedString('createDevice.deviceIdNumber', 'Device ID')}
                                            <span className={'required-star'}>{'*'}</span>
                                        </th>
                                        {locationHasDeviceTypes &&
                                            <th className={'importuploadfile-table-header-cell'}>
                                                {getLocalizedString('createDevice.deviceType', 'Device Type')}
                                                <span className={'required-star'}>{'*'}</span>
                                            </th>
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={'importuploadfile-table-row-cell'}
                                            style={{backgroundColor: 'lightgrey'}}>
                                            {getLocalizedString('import.template.explain', 'What is this field?')}
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            {getLocalizedString('import.template.device.deviceTag', 'The unique identifier associated with the device.')}
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            {getLocalizedString('import.template.device.deviceIdNumber', 'The code affixed to the device.')}
                                        </td>
                                        {locationHasDeviceTypes &&
                                            <td className={'importuploadfile-table-row-cell'}>
                                                {getLocalizedString('import.template.device.deviceType', 'The model or make of the device.')}
                                            </td>
                                        }
                                    </tr>
                                    <tr>
                                        <td className={'importuploadfile-table-row-cell'}
                                            style={{backgroundColor: 'lightgrey'}}>
                                            {getLocalizedString('import.template.accepted', 'Accepted Values')}
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            {getLocalizedString('import.template.device.deviceTag.accepted', 'Only letters and/or numbers are allowed.')}
                                        </td>
                                        <td className={'importuploadfile-table-row-cell'}>
                                            {idNumberRequirements ?? ''}
                                        </td>
                                        {locationHasDeviceTypes &&
                                            <td className={'importuploadfile-table-row-cell'}>
                                                <ul className={'importuploadfile-table-bullet-cell'}>
                                                    {context.currentLocation?.deviceTypes?.map((deviceType, idx) => {
                                                        return (
                                                            <li className={'field-text'} key={idx}>
                                                                {deviceType.deviceTypeName}
                                                            </li>
                                                        )
                                                    })
                                                    }
                                                </ul>
                                            </td>
                                        }
                                    </tr>
                                    </tbody>
                                </table>
                            }
                            <div className={'field-text'} style={{marginTop: '16px'}}>
                                <span className={'required-star'}>{'*'}</span>
                                {getLocalizedString('import.template.required', ' - indicates a required field')}
                            </div>
                        </div>
                    </div>
                    <div className={'importuploadfile-row-div'}>
                        <div className={'importuploadfile-help-text-circle'}>
                            <CircleLabelMd>{'3'}</CircleLabelMd>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <div className={'field-text'} style={{marginBottom: '32px'}}>
                                {getLocalizedString('import.template.upload', 'Upload the completed template:')}
                            </div>
                            <FileDropzone
                                onFileChange={(event) => {
                                    if (!uploadInProgress) {
                                        onFileChange(event);
                                    } else {
                                        setShowDialog(true);
                                        if (fileInputRef.current) {
                                            fileInputRef.current.value = "";
                                        }
                                    }
                                }}
                                fileInputRef={fileInputRef}
                            />
                            <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                                <ARCButton
                                    id={`import-${isDeviceImport ? 'devices' : 'users'}-next-button`}
                                    fill={'filled'}
                                    variant={'primary'}
                                    size={'md'}
                                    label={getLocalizedString('next', 'Next')}
                                    onClick={() => {
                                        setCurrentChildComponent(ImportChildComponentNames.validatingImport)
                                    }}
                                    disabled={!uploadFile || uploadInProgress}
                                >
                                </ARCButton>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
