import {EmployeeDto, EmployeeFilterDto, EmployeeLocation} from "../../dtos/Employee";
import {Action, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {createDeepEqualSelector, RootState} from "../../store/types";
import {get, getFile, post, postFormData} from "../app-context/thunks/API_Thunks";
import {ImportResponse} from "../../dtos/Response/ImportResponse";
import {currentLocationSelector} from "../app-context/appContextSlice";
import {PortalUploadStatusDto} from "../../dtos/PortalUploadStatus";
import {BulkActionResponseDto} from "../../dtos/BulkAction";
import {getLocalizedString} from "../../util/Localization";

export const employeeSliceName = 'employeeContext';

export type EmployeeSortTypes = 'firstName' | 'kioskGroup' | 'portalGroup' | 'devicePickupPermission' | 'active';
export type EmployeeSortOrder = 'asc' | 'desc';

type ViewPageContext = {
    sortBy: EmployeeSortTypes,
    order: EmployeeSortOrder,
    lastSelectedIdx: number
}

export interface EmployeeState {
    selectedEmployee?: EmployeeDto,
    viewPageContext: ViewPageContext
    employeesList:  EmployeeDto[] | undefined,
    saveErrorMessage: string | undefined,
    saveProcessing?: boolean,
    saveSuccessful?: boolean,
    sendInviteProcessing?: boolean,
    sendInviteSuccessful?: boolean,
    sendInviteErrorMessage?: string,
    getEmployeesErrorMessage?: string,
    getEmployeesLoading: boolean,
    updateLocationSuccessful: boolean,
    updateLocationErrorMessage?: string,
    addLocationSuccessful: boolean,
    addLocationErrorMessage?: string,
    badgeChangeSuccessful?: boolean,
    badgeChangeErrorMessage?: string,
    importErrorMessage?: string,
    importProcessing?: boolean,
    importResponse?: ImportResponse,
    importStatuses?: PortalUploadStatusDto[],
    importStatusesLoading?: boolean,
    importStatusesSuccess?: boolean,
    importResult?: PortalUploadStatusDto,
    downloadTemplateSuccessful?: boolean,
    downloadTemplateErrorMessage?: string,
    downloadTemplateFile?: string,
    exportSuccessful: boolean,
    exportErrorMessage?: string,
    exportFile?: string,
    bulkActionSuccessful?: boolean,
    bulkActionLoading?: boolean,
    bulkActionResponse?: BulkActionResponseDto,
    employeesListOffset: number,
    employeesListLimit: number,
    employeeFilters: EmployeeFilterDto,
    totalFilteredEmployees: number | undefined,
    employeeUploadTemplateHeader?: string[]
}

const initialState: EmployeeState = {
    employeesList: undefined,
    viewPageContext: {
      sortBy: 'firstName',
      order: 'asc',
      lastSelectedIdx: 0
    },
    saveErrorMessage: undefined,
    getEmployeesLoading: false,
    badgeChangeErrorMessage: undefined,
    updateLocationSuccessful: false,
    addLocationSuccessful: false,
    exportSuccessful: false,
    employeesListOffset: 0,
    employeesListLimit: 100,
    employeeFilters: {
        isAsc: true,
        nameOrId: undefined,
        kioskAccess: undefined,
        orderByString: undefined,
        status: undefined,
        devicePickupPermissionId: undefined
    },
    totalFilteredEmployees: undefined
}

type GetEmployeesBrandType = {
    locationId: number,
    brandId?: number,
};
export const getEmployeesByBrand = createAsyncThunk(
    `${employeeSliceName}/findall`,
    async ({locationId, brandId} : GetEmployeesBrandType, {dispatch, getState}) => {
        let {employeeFilters, employeesListLimit, employeesListOffset} = employeeContextSelector(getState());
        let trimmedFilter = {...employeeFilters};
        if (trimmedFilter.nameOrId) {
            trimmedFilter.nameOrId = trimmedFilter.nameOrId.replace(/\s/g, '');
        }
        try {
            const getAll = await dispatch(
                post({
                    urlSuffix: `/clientportal/employee/findall`, 
                    params: {locationId, brandId, limit: employeesListLimit, offset: employeesListOffset}, 
                    body: trimmedFilter
                })
            );
            // console.log({getAll});
            return getAll.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
)

export const getEmployeesByBrandRefresh = createAsyncThunk(
    `${employeeSliceName}/findallrefresh`,
    async ({locationId, brandId} : GetEmployeesBrandType, {dispatch}) => {
        dispatch(employeeContextActions.resetGetEmployees());
        dispatch(getEmployeesByBrand({
            locationId,
            brandId
        }))
    }
)

export const getFirstOffsetEmployeesByBrandWithoutFilter = createAsyncThunk(
    `${employeeSliceName}/findallwithoutfilter`,
    async ({locationId, brandId} : GetEmployeesBrandType, {dispatch}) => {
        dispatch(employeeContextActions.clearEmployeeFilter());
        dispatch(getEmployeesByBrand({
            locationId,
            brandId
        })) 
    }
)

export const getEmployeeForEdit = createAsyncThunk(
    `${employeeSliceName}/edit`,
    async ({locationId, brandId, employeeId} : {locationId : number, brandId?: number, employeeId: number}, {dispatch}) => {
        try {
            const editEmployee = await dispatch(get({urlSuffix: `/clientportal/employee/edit`, params: {locationId, brandId, employeeId}}))
            // console.log({editEmployee});

            return editEmployee.payload;
        }
        catch(err) {
            // console.log(err);
            throw err;
        }
    }
)

export const saveEmployee = createAsyncThunk(
    `${employeeSliceName}/save`,
    async ({newEmployee, locationId, brandId, createPermission} : {newEmployee: EmployeeDto, locationId: number, brandId: number, createPermission: boolean},  {dispatch}) => {
        try {
            const save = await dispatch(post({urlSuffix: `/clientportal/employee/save`, params: {locationId, brandId, createPermission}, body: newEmployee}));
            // console.log({save});
            return save.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
)

// export const saveEmployeeBadgeId = createAsyncThunk(
//     `${employeeSliceName}/savebadge`,
//     async ({employee, badgeId, locationId, brandId} : {employee: EmployeeDto, badgeId: string, locationId: number, brandId: number},  {dispatch}) => {
//         try {
//             const save = await dispatch(post({urlSuffix: `/clientportal/employee/savebadge`, params: {locationId, brandId, badgeId}, body: employee}));
//             // console.log({save});
//             return save.payload;
//         }
//         catch (err) {
//             // console.log(err);
//             throw err;
//         }
//     }
// );

export const sendPortalInvite = createAsyncThunk(
    `${employeeSliceName}/sendportalinvite`,
    async ({employeeId, email} : {employeeId: number, email: string}, {dispatch}) => {
        try {
            // console.log({employeeLocationDto});
            const sendInvite = await dispatch(post({urlSuffix: `/clientportal/employee/sendportalinvite`, params: {employeeId, email}, body: undefined}))
            // console.log({addLocation});

            return sendInvite.payload;
        }
        catch(err) {
            // console.log(err);
            throw err;
        }
    }
)

export const updateEmployeeLocations = createAsyncThunk(
    `${employeeSliceName}/updatelocations`,
    async ({employeeDto} : {employeeDto: EmployeeDto} , {dispatch}) => {
        try {
            const updateLocations = await dispatch(post({urlSuffix: `/clientportal/employee/updatelocations`, params: undefined, body: employeeDto}))
            // console.log({updateLocations});

            return updateLocations.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
)

export const addEmployeeLocation = createAsyncThunk(
    `${employeeSliceName}`,
    async ({employeeLocationDto, employeeId} : {employeeLocationDto : EmployeeLocation, employeeId: number}, {dispatch}) => {
        try {
            // console.log({employeeLocationDto});
            const addLocation = await dispatch(post({urlSuffix: `/clientportal/employee/addlocation`, params: {employeeId}, body: employeeLocationDto}))
            // console.log({addLocation});

            return addLocation.payload;
        }
        catch(err) {
            // console.log(err);
            throw err;
        }
    }
)

export const importEmployees = createAsyncThunk(
    `${employeeSliceName}/import`,
    async ({file, locationId} : {file: File, locationId: number}, {dispatch}) => {
        try {
            const formData = new FormData();
            if(file) {
                // Update the formData object
                formData.append(
                    "file",
                    file,
                    file.name
                );

                const importFile = await dispatch(postFormData({urlSuffix: `/clientportal/employee/import`, params: {locationId}, body: formData, data: formData}));
                // console.log({importFile});
                return importFile.payload;
            }
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
);

export const getEmployeeUploadTemplateHeader = createAsyncThunk(
    `${employeeSliceName}/getuploadtemplateheader`,
    async ({locationId} : {locationId: number}, {dispatch}) => {
        try {
            const headerResponse = await dispatch(get({urlSuffix: `/clientportal/employee/getuploadtemplateheader`, params: {locationId}}));

            return headerResponse.payload;
        }
        catch (err) {
            //console.log(err);
            throw err;
        }
    }
);

export const getEmployeeImportStatuses = createAsyncThunk(
    `${employeeSliceName}/import/status`,
    async ({locationId} : {locationId: number}, {dispatch}) => {
        try {
            const getImportStatus = await dispatch(get({urlSuffix: `/clientportal/employee/import/status`, params: {locationId}}));

            return getImportStatus.payload;
        }
        catch (err) {
            //console.log(err);
            throw err;
        }
    }
);

export const getEmployeeImportResult = createAsyncThunk(
    `${employeeSliceName}/import/result`,
    async ({locationId, portalUploadStatusId} : {locationId: number, portalUploadStatusId: number}, {dispatch}) => {
        try {
            const getImportStatus = await dispatch(get({urlSuffix: `/clientportal/employee/import/result`, params: {locationId, portalUploadStatusId}}));

            return getImportStatus.payload;
        }
        catch (err) {
            //console.log(err);
            throw err;
        }
    }
);

export const downloadEmployeeImportTemplate = createAsyncThunk(
    `${employeeSliceName}/downloadimport`,
    async ({locationId} : {locationId: number}, {dispatch}) => {
        try {
            const downloadEmployeeImportTemplate = await dispatch(getFile({urlSuffix: `/clientportal/employee/generateuploadtemplate`, params: {locationId}}));

            // console.log({downloadEmployeeImportTemplate});

            return downloadEmployeeImportTemplate.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
)

export const exportEmployees = createAsyncThunk(
    `${employeeSliceName}/export`,
    async ({locationId, activeOnly} : {locationId: number, activeOnly: boolean}, {dispatch}) => {
        try {
            const exportFile = await dispatch(getFile({urlSuffix: `/clientportal/employee/export`, params: {locationId, activeOnly}}));
            // console.log({exportFile});
            return exportFile.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
);

export const bulkDeactivateEmployees = createAsyncThunk(
    `${employeeSliceName}/bulk/deactivate`,
    async ({locationId, employeeList} : {locationId: number, employeeList: number[]}, {dispatch}) => {
        try {
            const bulkDeactivate = await dispatch(post({urlSuffix: `/clientportal/employee/bulk/deactivate`, params: {locationId}, body: {employeeList: employeeList}}));

            return bulkDeactivate.payload;
        }
        catch(err) {
            throw err;
        }
    }
);

export const bulkReactivateEmployees = createAsyncThunk(
    `${employeeSliceName}/bulk/reactivate`,
    async ({locationId, employeeList} : {locationId: number, employeeList: number[]}, {dispatch}) => {
        try {
            const bulkDeactivate = await dispatch(post({urlSuffix: `/clientportal/employee/bulk/reactivate`, params: {locationId}, body: {employeeList: employeeList}}));

            return bulkDeactivate.payload;
        }
        catch(err) {
            throw err;
        }
    }
);

export const bulkAddEmployeeLocation = createAsyncThunk(
    `${employeeSliceName}/bulk/addlocation`,
    async ({brandId, locationId, selectedLocationId, employeeList} : {brandId: number, locationId: number, selectedLocationId: number, employeeList: number[]}, {dispatch}) => {
        try {
            const bulkDeactivate = await dispatch(post({urlSuffix: `/clientportal/employee/bulk/addlocation`, params: {brandId, locationId, selectedLocationId}, body: {employeeList: employeeList}}));

            return bulkDeactivate.payload;
        }
        catch(err) {
            throw err;
        }
    }
);

export const bulkUpdateDevicePermissions = createAsyncThunk(
    `${employeeSliceName}/bulk/updatedevicepermissions`,
    async ({locationId, devicePickupPermissionId, employeeList} : {locationId: number, devicePickupPermissionId: number, employeeList: number[]}, {dispatch}) => {
        try {
            const bulkUpdatePermission = await dispatch(post({urlSuffix: `/clientportal/employee/bulk/updatedevicepermissions`, params: {locationId, devicePickupPermissionId}, body: {employeeList: employeeList}}));

            return bulkUpdatePermission.payload;
        }
        catch(err) {
            throw err;
        }
    }
);

export const employeeSlice = createSlice({
    name: employeeSliceName,
    initialState,
    reducers: {
        setSaveSuccessful: (state, action: PayloadAction<boolean>) => {
            state.saveSuccessful = action.payload
        },
        setUpdateLocationSuccessful: (state, action: PayloadAction<boolean>) => {
            state.updateLocationSuccessful = action.payload;
        },
        resetSaveEmployee: (state) => {
            state.saveSuccessful = false;
            state.saveErrorMessage = undefined;
            state.sendInviteSuccessful = undefined;
            state.sendInviteErrorMessage = undefined;
            state.selectedEmployee = undefined
        },
        resetGetEmployees: (state) => {
            state.getEmployeesErrorMessage = undefined;
            state.employeesListOffset = 0;
            state.employeesList = undefined;
            state.selectedEmployee = undefined
        },
        resetBulkAction: (state) => {
            state.bulkActionResponse = undefined;
        },
        resetBadgeChange: (state) => {
            state.badgeChangeSuccessful = undefined;
            state.badgeChangeErrorMessage = undefined;
        },
        resetImportEmployee: (state) => {
            state.importResponse = undefined;
            state.importErrorMessage = undefined;
            state.downloadTemplateFile = undefined;
            state.importResult = undefined;
        },
        resetExportEmployee: (state) => {
            state.exportErrorMessage = undefined;
            state.exportFile = undefined;
        },
        setSelectedEmployee: (state, action: PayloadAction<EmployeeDto | undefined>) => {
            state.selectedEmployee = action.payload;
        },
        setViewPageContext: (state, action: PayloadAction<Partial<ViewPageContext>>) => {
            state.viewPageContext = {
                ...state.viewPageContext,
                ...action.payload
            };
        },
        setEmployeeFilter: (state, action: PayloadAction<EmployeeFilterDto>) => {
            state.employeesList = undefined;
            state.employeesListOffset = 0;
            state.employeeFilters = action.payload;
        },
        setEmployeeFilterDevicePickupPermissionId: (state, action: PayloadAction<number>) => {
            state.employeesList = undefined;
            state.employeesListOffset = 0;
            state.employeeFilters = {
                ...(state.employeeFilters ?? initialState.employeeFilters),
                devicePickupPermissionId: action.payload
            }
        },
        clearEmployeeFilter: (state, action: Action) => {
            state.employeesList = undefined;
            state.employeesListOffset = 0;
            state.employeeFilters = initialState.employeeFilters;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEmployeesByBrand.pending, (state) => {
                // console.log('getEmployeesByBrand.pending');
                state.getEmployeesLoading = true;
            })
            .addCase(getEmployeesByBrand.rejected, (state, action) => {
                // console.log('getEmployeesByBrand.rejected');
                state.getEmployeesLoading = false;
                state.getEmployeesErrorMessage = getLocalizedString('viewEmployees.error', 'Error getting employees');
            })
            .addCase(getEmployeesByBrand.fulfilled, (state, action) => {
                // console.log('getEmployeesByBrand.fulfilled');
                state.getEmployeesLoading = false;
                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.getEmployeesErrorMessage = action.payload.errorMessage;
                }
                else if (action.payload?.hasOwnProperty("employeeDtoList")) {
                    let employeeList = action.payload?.employeeDtoList.map((item : EmployeeDto) => {
                        return {
                            ...item
                        }
                    })
                    let offsetHasData = employeeList?.length > 0;
                    if (offsetHasData) {
                        if (state.employeesList && state.employeesList?.length > 0 && state.employeesListOffset > 0) {
                            state.employeesList = state.employeesList.concat(employeeList);
                            state.employeesListOffset += state.employeesListLimit; // increment offset
                        } else {
                            state.employeesList = employeeList;
                            state.employeesListOffset = state.employeesListLimit;
                        }
                    } else {
                        state.employeesListOffset = -state.employeesListLimit;
                    }
                    state.totalFilteredEmployees = action.payload.totalFilteredEmployees;
                }
            })
            .addCase(getEmployeeForEdit.rejected, (state, action) => {
                state.saveErrorMessage = getLocalizedString('employee.load.error', 'Unable to load employee data')
                state.selectedEmployee = undefined
            })
            .addCase(getEmployeeForEdit.pending, (state, action) => {
                // console.log('getEmployeeForEdit.pending');
            })
            .addCase(getEmployeeForEdit.fulfilled, (state, action) => {
                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.saveErrorMessage = action.payload.errorMessage;
                }
                else if(action.payload?.hasOwnProperty("employeeId")) {
                    state.selectedEmployee = action.payload;

                    state.saveErrorMessage = undefined;
                }
                else {
                    state.saveErrorMessage = getLocalizedString('employee.load.error', 'Unable to load employee data')
                }
            })
            .addCase(saveEmployee.rejected, (state, action) => {
                // console.log('saveEmployee.rejected');
                state.saveSuccessful = false;
                state.saveErrorMessage = getLocalizedString('employee.save.error', 'Unable to save employee');
                state.saveProcessing = false;
            })
            .addCase(saveEmployee.pending, (state, action) => {
                // console.log('saveEmployee.pending');
                state.saveProcessing = true;
            })
            .addCase(saveEmployee.fulfilled, (state, action) => {
                // console.log('saveEmployee.fulfilled');

                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.saveSuccessful = false;
                    state.saveErrorMessage = action.payload.errorMessage;
                }
                else if(action.payload?.hasOwnProperty("employeeId")) {
                    state.saveSuccessful = true;
                    state.saveErrorMessage = undefined;
                    state.selectedEmployee = action.payload
                }
                else {
                    state.saveSuccessful = false;
                    state.saveErrorMessage = getLocalizedString('employee.save.error', 'Unable to save employee');
                }
                state.saveProcessing = false;
            })
            .addCase(sendPortalInvite.rejected, (state, action) => {
                state.sendInviteSuccessful = false;
                state.sendInviteErrorMessage = getLocalizedString('employee.sendNewInvite.error', 'Error sending new login email');
                state.sendInviteProcessing = false;
            })
            .addCase(sendPortalInvite.pending, (state, action) => {
                state.sendInviteProcessing = true;
            })
            .addCase(sendPortalInvite.fulfilled, (state, action) => {
                if(action.payload?.hasOwnProperty("employeeId")) {
                    state.sendInviteSuccessful = true;
                    state.sendInviteProcessing = false;
                    state.sendInviteErrorMessage = undefined;

                    if(state.employeesList) {
                        const employeeIdx = state.employeesList?.findIndex((employee) => employee.employeeId === action.payload.employeeId);
                        state.employeesList[employeeIdx] = action.payload;
                    }
                }
                else if (action.payload?.hasOwnProperty("errorMessage")) {
                    state.sendInviteSuccessful = false;
                    state.sendInviteErrorMessage = action.payload.errorMessage;
                    state.sendInviteProcessing = false;
                }
                else {
                    state.sendInviteSuccessful = false;
                    state.sendInviteErrorMessage = getLocalizedString('employee.sendNewInvite.error', 'Error sending new login email');
                    state.sendInviteProcessing = false;
                }
            })
            .addCase(updateEmployeeLocations.fulfilled, (state, action) => {
                // console.log('updateEmployeeLocations.fulfilled');
                if(action.payload?.hasOwnProperty("errorMessage")){
                    state.updateLocationSuccessful = false;
                    state.updateLocationErrorMessage = action.payload.errorMessage;
                }
                else {
                    state.updateLocationSuccessful = true;
                    state.updateLocationErrorMessage = undefined;
                }
            })
            .addCase(addEmployeeLocation.fulfilled, (state, action) => {
                // console.log('addEmployeeLocation.fulfilled');
                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.addLocationSuccessful = false;
                    state.addLocationErrorMessage = action.payload.errorMessage;
                }
                else {
                    state.addLocationSuccessful = true;
                    state.addLocationErrorMessage = undefined;
                }
            })
            .addCase(importEmployees.fulfilled, (state, action) => {
                // console.log('importEmployees.fulfilled');
                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.importErrorMessage = action.payload.errorMessage;
                }
                else if(action.payload) {
                    state.importResponse = {
                        successCount: action.payload.successCount,
                        successMessage: action.payload.successMessage,
                        updatedCount: action.payload.updatedCount,
                        updatedMessage: action.payload.updatedMessage,
                        duplicateCount: action.payload.duplicateCount,
                        duplicateMessage: action.payload.duplicateMessage,
                        existingIdCount: action.payload.existingIdCount,
                        existingIdMessage: action.payload.existingIdMessage,
                        badFormatCount: action.payload.badFormatCount,
                        badFormatMessage: action.payload.badFormatMessage
                    }
                }
            })
            .addCase(getEmployeeUploadTemplateHeader.fulfilled, (state, action) => {
                if(!action.payload.hasOwnProperty("errorMessage")) {
                    state.employeeUploadTemplateHeader = action.payload
                }
            })
            .addCase(getEmployeeImportStatuses.pending, (state, action) => {
                state.importStatusesLoading = true;
                state.importStatusesSuccess = false;
            })
            .addCase(getEmployeeImportStatuses.fulfilled, (state, action) => {
                state.importStatusesLoading = false;
                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.importStatusesSuccess = false;
                }
                else if(action.payload) {
                    state.importStatusesSuccess = true;
                    state.importStatuses = action.payload.map((status : PortalUploadStatusDto) => {
                        return {
                            portalUploadStatusId: status.portalUploadStatusId,
                            status: status.status,
                            createDateTime: status.createDateTime,
                            fileName: status.fileName,
                            uploadType: status.uploadType,
                            totalRows: status.totalRows,
                            processedRows: status.processedRows,
                            uploadedRows: status.uploadedRows,
                            uploadedList: status.uploadedList,
                            badFormatRows: status.badFormatRows,
                            badFormatList: status.badFormatList,
                            duplicateRows: status.duplicateRows,
                            duplicateList: status.duplicateList,
                            duplicateCredentialRows: status.duplicateCredentialRows,
                            duplicateCredentialList: status.duplicateCredentialList
                        }
                    })
                }
            })
            .addCase(getEmployeeImportResult.fulfilled, (state, action) => {
                if(action.payload?.hasOwnProperty("errorMessage")) {

                }
                else if(action.payload) {
                    state.importResult = action.payload as PortalUploadStatusDto;
                }
            })
            .addCase(downloadEmployeeImportTemplate.fulfilled, (state, action) => {
                // console.log('downloadEmployeeImportTemplate.fulfilled');

                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.downloadTemplateErrorMessage = action.payload.errorMessage;
                    state.downloadTemplateSuccessful = false;
                    state.downloadTemplateFile = undefined;
                }
                else if (action.payload) {
                    state.downloadTemplateFile = window.URL.createObjectURL(new Blob([action.payload]));
                }
            })
            .addCase(exportEmployees.fulfilled, (state, action) => {
                // console.log('exportEmployees.fulfilled');

                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.exportErrorMessage = action.payload.errorMessage;
                    state.exportFile = undefined;
                }
                else if (action.payload) {
                    state.exportFile = window.URL.createObjectURL(new Blob([action.payload]));
                }
            })
            .addCase(bulkReactivateEmployees.pending, (state, action) => {
                state.bulkActionLoading = true;
            })
            .addCase(bulkDeactivateEmployees.pending, (state, action) => {
                state.bulkActionLoading = true;
            })
            .addCase(bulkAddEmployeeLocation.pending, (state, action) => {
                state.bulkActionLoading = true;
            })
            .addCase(bulkUpdateDevicePermissions.pending, (state, action) => {
                state.bulkActionLoading = true;
            })
            .addCase(bulkReactivateEmployees.fulfilled, (state, action) => {
                // console.log('exportEmployees.fulfilled');
                state.bulkActionLoading = false;

                if(action.payload?.hasOwnProperty("message")) {
                    state.bulkActionResponse = action.payload;
                }
                else {
                }
            })
            .addCase(bulkDeactivateEmployees.fulfilled, (state, action) => {
                // console.log('exportEmployees.fulfilled');
                state.bulkActionLoading = false;

                if(action.payload?.hasOwnProperty("message")) {
                    state.bulkActionResponse = action.payload;
                }
                else {
                }
            })
            .addCase(bulkAddEmployeeLocation.fulfilled, (state, action) => {
                // console.log('exportEmployees.fulfilled');
                state.bulkActionLoading = false;

                if(action.payload?.hasOwnProperty("message")) {
                    state.bulkActionResponse = action.payload;
                }
                else {
                    state.getEmployeesErrorMessage = getLocalizedString('bulkTask.error', 'Error Running Bulk Task');
                }
            })
            .addCase(bulkUpdateDevicePermissions.fulfilled, (state, action) => {
                // console.log('exportEmployees.fulfilled');
                state.bulkActionLoading = false;

                if(action.payload?.hasOwnProperty("message")) {
                    state.bulkActionResponse = action.payload;
                }
                else {
                    state.getEmployeesErrorMessage = getLocalizedString('bulkTask.error', 'Error Running Bulk Task');
                }
            })
    }
})

export const employeeContextSelector = (state: RootState) => state[employeeSliceName];
export const employeeOffsetSelector = (state: RootState) => employeeContextSelector(state).employeesListOffset;
export const employeeListSelector = (state: RootState) => employeeContextSelector(state).employeesList;
export const viewPageContextSelector = (state: RootState) => employeeContextSelector(state).viewPageContext;

export const sortedEmployeeSelector = createDeepEqualSelector(
    employeeListSelector,
    viewPageContextSelector,
    currentLocationSelector,
    (employeeList, {sortBy, order}, currentLocation) => {

        return (employeeList?.slice() ?? []).sort((e1, e2) => {

                //Inactive employees always displayed on bottom

                if(currentLocation?.locationId !== -1) {
                    const e1Active = e1.employeeLocations.some(location => location.location.locationId === currentLocation?.locationId && location.active);
                    const e2Active = e2.employeeLocations.some(location => location.location.locationId === currentLocation?.locationId && location.active);

                    if (e1Active && !e2Active) {
                        return -1;
                    }
                    if (!e1Active && e2Active) {
                        return 1;
                    }
                }

                if (sortBy === 'firstName') {
                    if (order === 'asc') {
                        let cmp = e1.firstName.localeCompare(e2.firstName);

                        if (cmp === 0) {
                            if (e1.middleName && e2.middleName) {
                                cmp = e1.middleName.localeCompare(e2.middleName);
                            } else {
                                if (e1.middleName) {
                                    cmp = 1;
                                }
                                if (e2.middleName) {
                                    cmp = -1;
                                }
                            }
                        }

                        return cmp;

                    } else {
                        return e2.firstName.localeCompare(e1.firstName);
                    }
                }
                if (sortBy === 'kioskGroup') {
                    let e1Group = e1.employeeGroups.find(group => group.kioskGroup)?.groupName as string ?? '';
                    let e2Group = e2.employeeGroups.find(group => group.kioskGroup)?.groupName as string ?? '';

                    if (order === 'asc') {
                        return e1Group?.localeCompare(e2Group);
                    } else {
                        return e2Group?.localeCompare(e1Group);
                    }
                }
                if (sortBy === 'portalGroup') {
                    let e1Group = e1.employeeGroups.find(group => !group.kioskGroup)?.groupName as string ?? '';
                    let e2Group = e2.employeeGroups.find(group => !group.kioskGroup)?.groupName as string ?? '';

                    if (order === 'asc') {
                        return e1Group?.localeCompare(e2Group);
                    } else {
                        return e2Group?.localeCompare(e1Group);
                    }
                }

                return 0;
            }
        );
});
export const employeeContextActions = employeeSlice.actions;
export default employeeSlice.reducer;
