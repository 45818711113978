import { useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import './css/Carousel.css';
import {WHITE} from "../../themes/colors";
import {getLocalizedString} from "../../util/Localization";

interface CarouselProps {
    images: string[];
    title?: string;
    firstTimeLogin?: boolean;
    onClose?: () => void;
}

const Carousel: React.FC<CarouselProps> = ({ images, title, firstTimeLogin, onClose }) => {
    const theme = useTheme();
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handleDotClick = (index: number) => {
        setCurrentIndex(index);
    };

    return (
        <div className="carousel-container">
            {title && <div className="carousel-title">{title}</div>}
            <div className="carousel-content">
                <img className="carousel-image" src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
                
                <div className={"carousel-dots-div"}>
                    <div className={"carousel-arrow-div"}>
                        {currentIndex > 0 &&
                        <div className="carousel-left-arrow" id={'carousel-forward-button'} style={{color: theme.palette.primary.main}}
                             onClick={handlePrevious}>
                            &lt;
                        </div>
                        }
                    </div>
                    <div className="carousel-dots">
                        {images.map((_, index) => (
                            <div
                                key={index}
                                style={{color: theme.palette.primary.main, borderColor: theme.palette.primary.main, backgroundColor: currentIndex === index ? WHITE : theme.palette.primary.main}}
                                className={`carousel-dot ${currentIndex === index ? 'active' : ''}`}
                                onClick={() => handleDotClick(index)}
                            />
                        ))}
                    </div>
                    <div className={"carousel-arrow-div"}>
                        {currentIndex < images.length - 1 &&
                        <div className="carousel-right-arrow" id={'carousel-backward-button'} style={{color: theme.palette.primary.main}} onClick={handleNext}>
                            &gt;
                        </div>
                        }
                    </div>
                </div>
                
                <div className={"carousel-close-button-div"}>
                    {onClose &&
                        firstTimeLogin ?
                        <div id={'carousel-close-button'} className="carousel-close-button-first" onClick={onClose}>
                            {getLocalizedString('carousel.skip', 'Skip Intro')}
                        </div>
                        :
                        <div id={'carousel-close-button'} className="carousel-close-button" style={{backgroundColor: theme.palette.primary.main}} onClick={onClose}>
                            {getLocalizedString('carousel.close', 'Close')}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Carousel;
