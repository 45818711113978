export const RED = "#BC3E39";
export const WARM_RED = '#C3483E';
export const LIGHT_RED = "#ff0000";
export const LIGHT_GREEN = "#def2ea";
export const GREEN = "#5E9732";
export const DARK_GREEN = '#00975B';
export const YELLOW = "#FABC2F";
export const DARK_BLUE = "#2E7EAA";
export const PRIMARY = "#2E7E9F";
export const BLUE = "#2E7E9F";
export const BLACK = "#000000";
export const DARK_GREY = "#444444";
export const GREY = "#777777";
export const LIGHT_GREY = "#A4A2A2FF";
export const DISABLED_GREY = "#d1d1d1";
export const LIGHTER_GREY = "#E0E0E0";
export const WHITE = "#ffffff";