import {Action, AsyncThunk, AsyncThunkOptions, AsyncThunkPayloadCreator, ThunkAction} from "@reduxjs/toolkit";
import { shallowEqual } from "react-redux";
import { createSelectorCreator, defaultMemoize } from "reselect";
import {store} from "./store";

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

declare module "@reduxjs/toolkit" {
    type AsyncThunkConfig = {
        state?: unknown;
        dispatch?: AppDispatch;
        extra?: unknown;
        rejectValue?: unknown;
        serializedErrorType?: unknown;
    };

    function createAsyncThunk<
        Returned,
        ThunkArg = void,
        ThunkApiConfig extends AsyncThunkConfig = { state: RootState } // here is the magic line
        >(
        typePrefix: string,
        payloadCreator: AsyncThunkPayloadCreator<
            Returned,
            ThunkArg,
            ThunkApiConfig
            >,
        options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>,
    ): AsyncThunk<Returned, ThunkArg, ThunkApiConfig>;
}

export const createDeepEqualSelector = createSelectorCreator(defaultMemoize,
    (a, b) => {
        // console.log({a, b});
        const eq = {
            shallowEq: shallowEqual(a, b),
            '===': a === b
        };

        if (!eq.shallowEq || !eq['===']) {
            // console.log(`${a} !== ${b}`);
            // console.log({...eq});
        }
        return shallowEqual(a, b);
    }
)