import * as React from 'react';
import ARCLOGO_BLACK from "../../assets/img/ARC_Logo_Black.png";

export type ARCLogoProps = {
    onClick?: () => void,
    logoSrc?: string
}

export const ARCLogo:React.ComponentType<ARCLogoProps> = ({onClick, logoSrc}) => {

    return (
        <img className={'arc-logo'}
             style={!!onClick ? {cursor: 'pointer'} : {}}
             onClick={onClick}
             src={logoSrc ?? ARCLOGO_BLACK}
             alt="ARC Logo"
        />
    )
}