import * as React from 'react';
import {ARCIcon, ARCIcons} from "./ARCIcon";

export type ARCButtonSizeType = 'sm' | 'md' | 'lg';
export type ARCButtonVariantType = 'primary' | 'secondary' | 'tertiary' | 'danger';
export type ARCButtonFillType = 'filled' | 'outlined';

type ARCButtonType = {
    size: ARCButtonSizeType,
    variant: ARCButtonVariantType,
    fill: ARCButtonFillType,
    label: string,
    disabled? : boolean,
    icon?: ARCIcons,
    onClick: () => void,
    id?: string,
    submit?: boolean
}

export const ARCButton : React.FC<ARCButtonType> = (props) => {

    const buttonSizeClass = React.useMemo(() =>{
        return `o-button--${props.size}`;
    }, [props.size]);

    return (
        <button className={`o-button ${buttonSizeClass} o-button--${props.variant}--${props.fill} ${props.disabled ? 'o-button--disabled' : ''}`} onClick={props.onClick} id={props.id} type={props.submit ? 'submit' : 'button'}>
            {props.icon && <ARCIcon icon={props.icon} className={`o-button__icon--${props.size}`} size={'sm'}/>}
            {props.label}
        </button>
    )
}

export type ARCButtonComponentType = React.FC<ARCButtonType>;
