import * as React from 'react';
import {
    format as dateFnsFormat,
    getUnixTime,
    parse
} from "date-fns";

export const MINUTE_MS = 60000;
export const TOKEN_EXPIRING_WINDOW = MINUTE_MS * 28;
export const TOKEN_EXPIRED_TIME = MINUTE_MS * 30;
export const EMERGENCY_TOKEN_EXPIRED_TIME = MINUTE_MS * 30;
export const TOKEN_EXPIRING_WINDOW_ADMIN = MINUTE_MS * 238;
export const TOKEN_EXPIRED_TIME_ADMIN = MINUTE_MS * 240;

export const MonthsOfYear = [
    {monthNumber: 1, monthNumberString: '01', abrev: 'Jan', name: 'January'},
    {monthNumber: 2, monthNumberString: '02', abrev: 'Feb', name: 'February'},
    {monthNumber: 3, monthNumberString: '03', abrev: 'Mar', name: 'March'},
    {monthNumber: 4, monthNumberString: '04', abrev: 'Apr', name: 'April'},
    {monthNumber: 5, monthNumberString: '05', abrev: 'May', name: 'May'},
    {monthNumber: 6, monthNumberString: '06', abrev: 'Jun', name: 'June'},
    {monthNumber: 7, monthNumberString: '07', abrev: 'Jul', name: 'July'},
    {monthNumber: 8, monthNumberString: '08', abrev: 'Aug', name: 'August'},
    {monthNumber: 9, monthNumberString: '09', abrev: 'Sep', name: 'September'},
    {monthNumber: 10, monthNumberString: '10', abrev: 'Oct', name: 'October'},
    {monthNumber: 11, monthNumberString: '11', abrev: 'Nov', name: 'November'},
    {monthNumber: 12, monthNumberString: '12', abrev: 'Dec', name: 'December'}
]

export const Months = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12'
]

export function getNumDaysInMonth(monthNumber = 1) {
    const date = new Date(1920, monthNumber);
    date.setDate(0);

    return date.getDate();
}

export function getMonthDaysList(monthNumber = 1) {
    const numDaysInMonth = getNumDaysInMonth(monthNumber);
    const days = [];
    for (let i = 1; i <= numDaysInMonth ; i++) {
        days[i-1] = `${i < 10 ? '0' : ''}${i}`;
    }

    return days;
}

export function convertMinutesToMS(minutes: number) {
    const seconds = convertMinutesToSeconds(minutes);
    return convertSecondsToMS(seconds);
}

export function convertMinutesToSeconds(minutes: number) {
    return minutes * 60.0;
}

export function convertSecondsToMS(seconds: number) {
    return seconds * 1000.0;
}

export function epochTimeMS() {
    return getUnixTime(new Date());
}

export function formatDate(date: Date, format = "MM/dd/yyyy") {
    return dateFnsFormat(date, format);
}

export function parseDateStr(date: string, format = "yyyy-MM-dd") {
    return parse(date, format, new Date());
}

export const useInterval = (callback: () => any, ms = MINUTE_MS) => {
    React.useEffect(() => {
        // console.log('Beginning interval timer');
        // Run the callback on first load of the interval components
        callback && callback();

        const interval = setInterval(callback, ms);

        return () => {
            // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
            // console.log('Ending timer');
            clearInterval(interval);
        }
    }, [callback, ms]);
}