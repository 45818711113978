import * as React from 'react';
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    appContextActions,
    appContextSelector,
    getPortalGlobalMessages
} from "../app-context/appContextSlice";
import {media_url} from "../../dtos/API_DOMAINS";
import {hasOnboardingMedia} from "../../dtos/Brand";
import Dialog from "@material-ui/core/Dialog";
import Carousel from "../ui-components/Carousel";
import {useNavigate} from "react-router-dom";
import {useCurrentUserContext} from "../app-context/CurrentUserContextContainer";
import {getLocalizedString} from "../../util/Localization";
import {ARCHeader} from "../ui-components/design-system/ARCHeader";

type AppBarProps = {
    left?: React.ReactNode,
    right?: React.ReactNode,
    title?: React.ReactNode,
    className?: string,
    static?: boolean,
    logoNonInteractive?: boolean
};

const AppBar:React.ComponentType<AppBarProps> = (props) => {
    const navigate = useNavigate();
    const context = useAppSelector(appContextSelector);
    const currentUserContext = useCurrentUserContext();
    const dispatch = useAppDispatch();

    const firstTimeLogin = React.useMemo<boolean>(() => context.firstTimeLogin, [context.firstTimeLogin]);
    const [showDialog, setShowDialog] = React.useState<boolean>(firstTimeLogin);

    React.useEffect(() => {
        dispatch(getPortalGlobalMessages({brandId: context.currentBrand?.brandId}));
    }, [context.currentBrand?.brandId, dispatch]);
    
    const logoOnClick = React.useCallback(() => {
        if (!props.logoNonInteractive) {
            (!currentUserContext.groups.isArcTechSupport ?
                    navigate(`/reports/view/locationId=${context.currentLocation?.locationId}`)
                    :
                    navigate(`/employees/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`)
            )
        }
    },[props.logoNonInteractive, currentUserContext.groups.isArcTechSupport, context.currentLocation?.locationId, context.currentBrand?.brandId, navigate])

    const showOnboardingGuide = React.useMemo(() => {
        return context.currentBrand && context.firstTimeLogin && hasOnboardingMedia(context.currentBrand);
    }, [context.currentBrand, context.firstTimeLogin]);

    return (
        <>
            <ARCHeader
                left={props.left}
                title={props.title}
                right={props.right}
                static={props.static}
                logoOnClick={logoOnClick}
            />

            {showOnboardingGuide &&
            <Dialog maxWidth={'lg'} open={showDialog} onClose={() => {}}>
                <Carousel
                    onClose={() => {
                        setShowDialog(false);
                        dispatch(appContextActions.setFirstTimeLogin());
                    }}
                    firstTimeLogin={true}
                    title={getLocalizedString('onboarding.title', 'Welcome to ARC\'s Client Portal')}
                    images={[
                        context.currentBrand?.onboardingImageFile1 ? (media_url + context.currentBrand?.brandId + '/' + context.currentBrand?.onboardingImageFile1) : '',
                        context.currentBrand?.onboardingImageFile2 ? (media_url + context.currentBrand?.brandId + '/' + context.currentBrand?.onboardingImageFile2) : '',
                        context.currentBrand?.onboardingImageFile3 ? (media_url + context.currentBrand?.brandId + '/' + context.currentBrand?.onboardingImageFile3) : '',
                        context.currentBrand?.onboardingImageFile4 ? (media_url + context.currentBrand?.brandId + '/' + context.currentBrand?.onboardingImageFile4) : '',
                        context.currentBrand?.onboardingImageFile5 ? (media_url + context.currentBrand?.brandId + '/' + context.currentBrand?.onboardingImageFile5) : ''
                    ].filter(img => img !== '')}>

                </Carousel>
            </Dialog>
            }
        </>
    );
};

export default AppBar;
