import * as React from 'react';
import {AppWithFooter} from "../../App";
import AppBar from "../appbar/AppBar";
import {CancelButton, FormTitle} from "../ui-components/StyledComponents";
import {SideBar} from "../sidebar/SideBar";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {appContextSelector} from "../app-context/appContextSlice";
import {useNavigate, useParams} from "react-router-dom";
import {labelRequestContextSelector, viewLabelRequest} from "./labelRequestSlice";
import '../../css/LabelRequest.css';
import {
    getUrgencyString,
    LABEL_TYPE_EMPLOYEE,
    REQUEST_ERROR_HELP,
    REQUEST_STATUS_ERROR
} from "../../dtos/LabelRequestDto";
import {getLocalizedString} from "../../util/Localization";

export const ViewLabelRequest = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);
    const labelRequestContext = useAppSelector(labelRequestContextSelector);
    const {qrRequestId} = useParams();
    const labelRequestId = qrRequestId;

    const request = labelRequestContext.viewedLabelRequest;

    React.useEffect(() => {
        if(context.currentLocation?.locationId && labelRequestId) {
            dispatch(viewLabelRequest({labelRequestId: parseInt(labelRequestId)}));
        }
    }, [context.currentLocation?.locationId, dispatch, labelRequestId]);

    return (
        <AppWithFooter>
            <AppBar/>
            <SideBar/>
            <main className={'o-main'}>
                <div className={'l-wrap l-container--lg'}>
                    <FormTitle>{getLocalizedString('labelRequest.view.title', 'QR Code Request Details')}</FormTitle>

                    {request &&
                        <div style={{width: '100%'}}>
                            <div className={'request-section'}>
                                <span className={'request-label'}>{getLocalizedString('labelRequest.createDatetime', 'Date Submitted') + ': '}</span>
                                <span>{request.createDateTime}</span>
                            </div>


                            <div className={'request-section'}>
                                <span className={'request-label'}>{getLocalizedString('labelRequest.createUser', 'Submitted By ') + ': '}</span>
                                <span>{request.createUser}</span>
                            </div>


                            <div className={'request-section'}>
                                <span className={'request-label'}>{getLocalizedString('labelRequest.status', 'Status') + ': '}</span>
                                <span>{request.requestStatus}</span>

                                {request.requestStatus === REQUEST_STATUS_ERROR &&
                                    <span>{REQUEST_ERROR_HELP}</span>
                                }
                            </div>


                            <div className={'request-section'}>
                                <span className={'request-label'}>{getLocalizedString('labelRequest.location', 'Location') + ': '}</span>
                                <span>{request.location?.locationName ?? ''}</span>
                            </div>

                            <div className={'request-section'}>
                                <span className={'request-label'}>{getLocalizedString('labelRequest.location.shippingAddress', 'Shipping Address:') + ' '}</span>
                                <span>{request.location?.shippingAddress ?? ''}</span>
                            </div>

                            <div className={'request-section'}>
                                <span className={'request-label'}>{getLocalizedString('labelRequest.labelType', 'ID Type') + ': '}</span>
                                <span>
                                    {request.labelType === LABEL_TYPE_EMPLOYEE ?
                                        getLocalizedString('labelRequest.badge', 'Badge IDs - ' + context.currentBrand?.employeeNumberLength + ' digits', [`${context.currentBrand?.employeeNumberLength ?? 0}`])
                                        :
                                        getLocalizedString('labelRequest.device', 'Device IDs - ' + context.currentBrand?.deviceNumberLength + ' digits', [`${context.currentBrand?.deviceNumberLength ?? 0}`])
                                    }
                                </span>
                            </div>


                            <div className={'request-section'}>
                                <span className={'request-label'}>{getLocalizedString('labelRequest.urgency', 'Urgency:') + ' '}</span>
                                <span>{getUrgencyString(request.urgency ?? '')}</span>
                            </div>

                            <div className={'request-section'}>
                                <span className={'request-label'}>{getLocalizedString('labelRequest.requestNotes', 'Notes: ')}</span>
                                <span>{request.requestNotes}</span>
                            </div>
                        </div>
                    }

                    <div style={{width: '100%', marginTop: '10vh', display: 'flex', justifyContent: 'flex-end'}}>
                        <CancelButton
                            id="view-label-request-back-button"
                            variant='contained'
                            onClick={() => {navigate(`/qr/viewall/locationId=${context.currentLocation?.locationId}`)}}
                        >
                            {getLocalizedString('labelRequest.cancel', 'Back')}
                        </CancelButton>
                    </div>
                </div>
            </main>
        </AppWithFooter>
    )
}