import {createMigrate} from "redux-persist";
import {env} from "../../dtos/API_DOMAINS";
// https://gist.github.com/lafiosca/b7bbb569ae3fe5c1ce110bf71d7ee153
const persistMigrations = {

};

export const persistMigrate = createMigrate(persistMigrations, {
    debug: env !== 'production'
});
