import * as React from 'react';
import {AppWithFooter} from "../../App";
import AppBar from "../appbar/AppBar";
import {CancelButton, FormTitle} from "../ui-components/StyledComponents";
import {SideBar} from "../sidebar/SideBar";
import {Link, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {appContextSelector} from "../app-context/appContextSlice";
import {labelRequestContextSelector, viewAllLabelRequest} from "./labelRequestSlice";
import {getStatusLabel, LABEL_TYPE_EMPLOYEE} from '../../dtos/LabelRequestDto';
import {calculateResponsiveSizeWithMinMax} from "../../util/TextUtils";
import {getLocalizedString} from "../../util/Localization";

export const ViewLabelRequestList = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);
    const labelRequestContext = useAppSelector(labelRequestContextSelector);

    const labelList = labelRequestContext.viewAllLabelRequestList;

    React.useEffect(() => {
        if(context.currentLocation?.locationId && context.currentBrand?.brandId) {
            dispatch(viewAllLabelRequest({locationId: context.currentLocation?.locationId, brandId: context.currentBrand?.brandId}));
        }
    }, [context.currentBrand?.brandId, context.currentLocation?.locationId, dispatch]);

    return (
        <AppWithFooter>
            <AppBar/>
            <SideBar/>
            <main className={'o-main'}>
                <div className={'l-wrap l-container--lg'}>
                    <FormTitle>{getLocalizedString('labelRequest.viewList.title', 'QR Code Request Status')}</FormTitle>

                    <Table>
                        <TableHead>
                            <TableCell style={{padding: '2%'}}>
                                <Typography style={{fontSize: calculateResponsiveSizeWithMinMax('2', '24', '8')}}>
                                    {getLocalizedString('labelRequest.createDatetime', 'Date Submitted')}
                                </Typography>
                            </TableCell>

                            <TableCell style={{padding: '2%'}}>
                                <Typography style={{fontSize: calculateResponsiveSizeWithMinMax('2', '24', '8')}}>
                                    {getLocalizedString('labelRequest.createUser', 'Submitted By')}
                                </Typography>
                            </TableCell>

                            <TableCell style={{padding: '2%'}}>
                                <Typography style={{fontSize: calculateResponsiveSizeWithMinMax('2', '24', '8')}}>
                                    {getLocalizedString('labelRequest.location', 'Location')}
                                </Typography>
                            </TableCell>

                            <TableCell style={{padding: '2%'}}>
                                <Typography style={{fontSize: calculateResponsiveSizeWithMinMax('2', '24', '8')}}>
                                    {getLocalizedString('labelRequest.labelType', 'ID Type')}
                                </Typography>
                            </TableCell>

                            <TableCell style={{padding: '2%'}}>
                                <Typography style={{fontSize: calculateResponsiveSizeWithMinMax('2', '24', '8')}}>
                                    {getLocalizedString('labelRequest.status', 'Status')}
                                </Typography>
                            </TableCell>

                            <TableCell/>
                        </TableHead>

                        <TableBody>
                            {labelList && labelList.map((request, idx) =>
                                <TableRow key={idx}>
                                    <TableCell style={{fontSize: calculateResponsiveSizeWithMinMax('1.5', '18', '6'), padding: '2%'}}>
                                        {request.createDateTime}
                                    </TableCell>

                                    <TableCell style={{fontSize: calculateResponsiveSizeWithMinMax('1.5', '18', '6'), padding: '2%'}}>
                                        {request.createUser}
                                    </TableCell>

                                    <TableCell style={{fontSize: calculateResponsiveSizeWithMinMax('1.5', '18', '6'), padding: '2%'}}>
                                        {request.location?.locationName ?? ''}
                                    </TableCell>

                                    <TableCell style={{fontSize: calculateResponsiveSizeWithMinMax('1.5', '18', '6'), padding: '2%'}}>
                                        {request.labelType === LABEL_TYPE_EMPLOYEE ? getLocalizedString('labelRequest.labelType.badgeID', 'Badge IDs') : getLocalizedString('labelRequest.labelType.deviceID', 'Device IDs')}
                                    </TableCell>

                                    <TableCell style={{fontSize: calculateResponsiveSizeWithMinMax('1.5', '18', '6'), padding: '2%'}}>
                                        {getStatusLabel(request.requestStatus)}
                                    </TableCell>

                                    <TableCell style={{fontSize: calculateResponsiveSizeWithMinMax('1.5', '18', '6'), marginLeft: '2%'}}>
                                        {(request.requestStatus === 'SUBMITTED' || request.requestStatus === 'ERROR') &&
                                        <Link
                                            id={'view-label-requests-view-request-link'}
                                            target={'_self'} 
                                            onClick={() => {
                                                navigate(`/qr/view/locationId=${context.currentLocation?.locationId}/qrRequestId=${request.labelRequestId}`)
                                            }}>{getLocalizedString('labelRequest.viewList.view', 'View Results')}</Link>
                                        }
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                    <div style={{width: '100%', marginTop: '10vh', display: 'flex', justifyContent: 'flex-end'}}>
                        <CancelButton
                            id="view-label-requests-back-button"
                            variant='contained'
                            onClick={() => {navigate(`/faq/view/locationId=${context.currentLocation?.locationId}`)}}
                        >
                            {getLocalizedString('labelRequest.cancel', 'Back')}
                        </CancelButton>
                    </div>
                </div>
            </main>
        </AppWithFooter>
    )
}
