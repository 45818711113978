import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import {persistStore} from "redux-persist";
import { store } from './store/store';
import {PersistGate} from "redux-persist/integration/react";
import {CurrentUserContainer} from "./features/app-context/CurrentUserContextContainer";
import axios from "axios";
import {appContextActions} from "./features/app-context/appContextSlice";
import {createTheme, CssBaseline, ThemeOptions, ThemeProvider } from '@material-ui/core';
import {
    BLACK,
    BLUE,
    DARK_BLUE,
    LIGHT_GREY,
    LIGHT_RED,
    LIGHTER_GREY,
    RED,
    WHITE,
    YELLOW
} from "./themes/colors";
import {calculatedFontSize} from "./util/TextUtils";
import {AppViewContextContainer} from "./features/app-context/AppViewContainer";
import './css/design-system/main.scss';
import {getLocalizedString} from "./util/Localization";

const persistor = persistStore(store);

const themeBase : ThemeOptions = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1280,
            lg: 1280,
            xl: 2000
        }
    },
    palette: {
        primary: {
            light: BLUE,
            main: BLUE,
            dark: BLUE
        },
        secondary: {
            main: YELLOW,
            light: DARK_BLUE
        },
        info: {
            main: BLACK,
            dark: RED
        },
        warning: {
            main: LIGHT_RED
        }
    },
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: WHITE
            }
        },
        MuiCheckbox: {
            colorSecondary: {
                '&$checked': {
                    color: BLUE
                }
            },
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: LIGHTER_GREY,
                color: BLACK,
                fontSize: calculatedFontSize('.85em', '.9em'),
                maxWidth: 'unset'
            }
        },
        MuiInput: {
            input: {
                '&:-webkit-autofill': {
                    '-webkit-box-shadow': '0 0 0 100px transparent inset',
                    '-webkit-text-fill-color': '#000'
                },
                '&.Mui-disabled': {
                    borderColor: LIGHT_GREY
                }
            }
        },
        MuiFormLabel: {
          root: {
              '&.Mui-focused': {
                  color: BLACK
              }
          }
        },
        MuiFormControlLabel: {
            label: {
                '&.Mui-disabled': {
                    color: LIGHT_GREY
                }
            }
        },
        MuiAccordionSummary: {
            root: {
                backgroundColor: YELLOW,
                color: BLACK,
            }
        },
        MuiAccordion: {
            root: {
                "&$expanded": {
                    margin: "auto",
                    boxShadow: 'unset',
                    zIndex: 1000
                }
            }
        },
        MuiCssBaseline: {
            '@global': {
                body: {
                    letterSpacing: 'inherit'
                },
            },
        },
    }
}

const theme = createTheme(themeBase);

ReactDOM.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
      <CssBaseline/>
          <Provider store={store}>
              <PersistGate persistor={persistor}>
                  <AppViewContextContainer>
                      <CurrentUserContainer>
                              <App />
                      </CurrentUserContainer>
                  </AppViewContextContainer>
              </PersistGate>
          </Provider>
      </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
const {dispatch} = store;

axios.interceptors.response.use(function (response) {

    if(response.headers['portal-set-authorization']) {
        localStorage.setItem('token', response.headers['portal-set-authorization']);
        dispatch(appContextActions.setTokenIssueTime(Date.now()));
    }

    return response;
}, function (error) {
    if (!error.response) {
        dispatch(appContextActions.networkIssue(getLocalizedString('server.unresponsive', 'Unable to communicate with server. Please try again later.')));
    }
    else if (error.response.status) {
        if(200 !== error.response.status) {
            if (401 === error.response.status) {
                localStorage.removeItem('token');
                localStorage.removeItem('emergencyToken');
                dispatch(appContextActions.unauth());
            }
            else if (400 === error.response.status) {
                dispatch(appContextActions.networkIssue(getLocalizedString('server.invalidURL', 'The URL you are trying to access is not valid. Please verify the URL.')));
            }
            else if (403 === error.response.status) {
                dispatch(appContextActions.setUnauthorizedAccess(true));
            }
            else {
                dispatch(appContextActions.networkIssue(getLocalizedString('server.unexpectedError', 'An unexpected error has occurred. Please contact ARC support.')));
            }
        }


    }

    return Promise.reject(error);
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
