import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from 'redux-persist';
/* STORAGE  */
import localStorage from 'redux-persist/lib/storage'
/* REDUCERS */
import {appContextSlice} from "../../features/app-context/appContextSlice";
/* MIGRATIONS */
import {persistMigrate} from "./migrations";

/* CONFIG */
export const PERSIST_VERSION = 1;
export const PERSIST_NAME = 'APP_PERSISTENCE_DB';

/* WHITELISTED REDUCERS */
export const reducersToPersist = [
    appContextSlice.name
]

/* PERSISTENCE CONFIGURATION */
export const persistConfig = {
    version: PERSIST_VERSION,
    key: PERSIST_NAME,
    storage: localStorage,
    whitelist: reducersToPersist,
    migrate: persistMigrate,
};

/* Solves issue. "A non-serializable value was detected in an action" */
export const skipPersistenceSerializableChecks = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];

