import {createAsyncThunk, createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {appContextSliceName} from "../app-context/appContextTypes";
import {get, post} from "../app-context/thunks/API_Thunks";
import {RootState} from "../../store/types";
import {LocationDto} from "../../dtos/Location";
import {KioskDto} from "../../dtos/Kiosk";
import {HELP_PHONE} from "../../constants/StringConstants";
import {getLocalizedString} from "../../util/Localization";


export const emergencyPortalSliceName = 'emergencyPortalSlice';

export interface EmergencyPortalState {
    emergencyTokenIssueTime?: number,
    thunkResults: {
        [thunkName: string] : {
            status: 'loading' | 'idle' | 'error' | 'success',
            message: string | undefined
        }
    }
    locations: LocationDto[],
    kiosks: KioskDto[],
    selectedKiosk: KioskDto | undefined
}

const initialState : EmergencyPortalState = {
    thunkResults: {},
    locations: [],
    kiosks: [],
    selectedKiosk: undefined
}

export const emergencyPortalLogin = createAsyncThunk(
    `${appContextSliceName}/emergencyportal/auth`,
    async ({firstName, lastName, badgeId} : {firstName:string, lastName:string, badgeId: string}, {dispatch}) => {
        // console.log(`${appContextSliceName}/authorization`, {username, password});

        try {
            const auth = await dispatch(post({urlSuffix: `/emergency/auth/login`, params:undefined, body:{firstName: firstName, lastName: lastName, badgeId: badgeId}, useToken: false}));
            // console.log({auth});

            return auth.payload;
        }
        catch(err) {
            // console.log(err);
            throw err;
        }
    }
);

export const emergencyPortalGetKiosks = createAsyncThunk(
    `${appContextSliceName}/emergencyportal/getKiosks`,
    async ({brandId} : {brandId: number | undefined}, {dispatch}) => {

        try {
            const kiosks = await dispatch(get({urlSuffix: `/emergency/getKiosks`, params:brandId ? {brandId} : undefined}));

            return kiosks.payload;
        }
        catch(err) {
            // console.log(err);
            throw err;
        }
    }
);

export const emergencyPortalGetKiosk = createAsyncThunk(
    `${appContextSliceName}/emergencyportal/getKiosk`,
    async ({kioskId} : {kioskId: number}, {dispatch}) => {

        try {
            const kiosk = await dispatch(get({urlSuffix: `/emergency/getKiosk`, params:{kioskId}}));
            // console.log({kiosk});

            return kiosk.payload;
        }
        catch(err) {
            // console.log(err);
            throw err;
        }
    }
);

export const emergencyPortalSlice = createSlice({
    name: emergencyPortalSliceName,
    initialState,
    reducers: {
        resetSelectedKiosk: (state) => {
            state.selectedKiosk = undefined;
        },
        setEmergencyTokenIssueTime: (state, action: PayloadAction<number>) => {
            state.emergencyTokenIssueTime = action.payload;
        },
        resetKioskList: (state) => {
            state.kiosks = [];
        },
        unauth: (state) => {
            state = initialState;
            localStorage.removeItem("emergencyToken");
        }
    },
    extraReducers: (builder => {
        builder
            .addCase(emergencyPortalLogin.pending, (state) => {
                // console.log('emergencyPortalLogin.pending');
                state.thunkResults[emergencyPortalLogin.name] = {
                    status: 'loading',
                    message: undefined
                }
            })
            .addCase(emergencyPortalLogin.rejected, (state, action) => {
                // console.log('emergencyPortalLogin.rejected');
                state.thunkResults[emergencyPortalLogin.name] = {
                    status: 'error',
                    message: getLocalizedString('login.error', 'Unable to Authenticate')
                }
                localStorage.removeItem('emergencyToken');
            })
            .addCase(emergencyPortalLogin.fulfilled, (state, action) => {
                // console.log('emergencyPortalLogin.fulfilled');
                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.thunkResults[emergencyPortalLogin.name] = {
                        status: 'error',
                        message: action.payload.errorMessage
                    }
                    
                    localStorage.removeItem('emergencyToken');
                } else if(action.payload) {
                    const token = action.payload;

                    state.thunkResults[emergencyPortalLogin.name] = {
                        status: 'success',
                        message: undefined
                    }

                    state.emergencyTokenIssueTime = Date.now();

                    localStorage.setItem('emergencyToken', token);
                } else {
                    state.thunkResults[emergencyPortalLogin.name] = {
                        status: 'error',
                        message: getLocalizedString('login.emergencyPortal.unavailable', 'This portal is currently unavailable. Please call ' + HELP_PHONE + ' for assistance', [getLocalizedString('support.phone', HELP_PHONE)])
                    }
                    localStorage.removeItem('emergencyToken');
                }
            })
            .addCase(emergencyPortalGetKiosks.pending, (state) => {
                // console.log('emergencyPortalLogin.pending');
                state.thunkResults[emergencyPortalGetKiosks.name] = {
                    status: 'loading',
                    message: undefined
                }
            })
            .addCase(emergencyPortalGetKiosks.fulfilled, (state, action) => {
                // console.log('emergencyPortalLogin.fulfilled');
                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.thunkResults[emergencyPortalGetKiosks.name] = {
                        status: 'error',
                        message: action.payload.errorMessage
                    }
                } else if(action.payload) {
                    state.thunkResults[emergencyPortalGetKiosks.name] = {
                        status: 'success',
                        message: undefined
                    }
                    state.kiosks = action.payload ?? [];

                    if(action.payload?.length === 1) {
                        state.selectedKiosk = action.payload[0];
                    } else {
                        state.selectedKiosk = undefined;
                    }
                } else {
                    state.thunkResults[emergencyPortalGetKiosks.name] = {
                        status: 'error',
                        message: getLocalizedString('server.error', 'Unable to communicate with server')
                    }
                }
            })
            .addCase(emergencyPortalGetKiosk.pending, (state) => {
                // console.log('emergencyPortalLogin.pending');
                state.thunkResults[emergencyPortalGetKiosk.name] = {
                    status: 'loading',
                    message: undefined
                }
            })
            .addCase(emergencyPortalGetKiosk.fulfilled, (state, action) => {
                // console.log('emergencyPortalLogin.fulfilled');
                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.thunkResults[emergencyPortalGetKiosk.name] = {
                        status: 'error',
                        message: action.payload.errorMessage
                    }
                } else if(action.payload) {
                    state.thunkResults[emergencyPortalGetKiosk.name] = {
                        status: 'success',
                        message: undefined
                    }
                    state.selectedKiosk = action.payload
                } else {
                    state.thunkResults[emergencyPortalGetKiosk.name] = {
                        status: 'error',
                        message: getLocalizedString('server.error', 'Unable to communicate with server')
                    }
                }
            })
    })
});

export const thunkResultsSelector = (state: RootState) => state[emergencyPortalSliceName].thunkResults;
export const emergencyPortalTokenIssueTimeSelector = (state: RootState) => state[emergencyPortalSliceName].emergencyTokenIssueTime;

export const getThunkStatusSelector = (thunkName: string) => {
    return createSelector(
        [thunkResultsSelector],
        (thunkResults) => thunkResults[thunkName]
    );
}
 
export const isThunkSuccessful = (status: string) => {
    return status === 'success';
}

export const isThunkLoading = (status: string) => {
    return status === 'loading';
}

export const isThunkFailed = (status: string) => {
    return status === 'error';
}

export const emergencyPortalContextSelector = (state: RootState) => state[emergencyPortalSliceName];
export const emergencyPortalActions = emergencyPortalSlice.actions;
export default emergencyPortalSlice.reducer;
