import * as React from 'react';
import {
    Button,
    CircularProgress,
    styled,
    TextField
} from "@material-ui/core";
import {calculateResponsiveSizeWithMinMax} from "../../util/TextUtils";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {appContextActions, appContextSelector, forgotPassword} from "../app-context/appContextSlice";
import "../../css/ForgotPassword.css";
import {getLocalizedString} from "../../util/Localization";

interface LoginFormProps {
    cancelPressed: () => void
    email: string
    onEmailChange: (val: string) => void
    onSubmit: () => void
}

export const ForgotPasswordForm:React.ComponentType<LoginFormProps> = ({cancelPressed, email, onEmailChange, onSubmit}) => {
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);

    const submitForm = React.useCallback((event : React.FormEvent) => {
        event.preventDefault();
        dispatch(forgotPassword({username: '', email: email}))
        onSubmit();
    }, [dispatch, email, onSubmit]);


    return (
        <form 
            onSubmit={submitForm}
            className={'forgot-input-form'}
            >
            <div className={'forgot-input-form-control'}>
                <div className={'forgot-input-root'}>

                    <div className={'forgot-password-title'}>
                        {getLocalizedString('forgotpassword.title', 'Forgot your password?')}
                    </div>

                    <div className={'large-help-text'}>
                        {getLocalizedString('forgotpassword.enterEmail', 'It happens to the best of us. Enter your work email to receive a link to reset your password.')}
                    </div>
                    <div className={'forgot-input-div-row-form-control'}>
                        <TextField
                            autoFocus
                            InputProps={{
                                disableUnderline: true,
                                style: {
                                    marginTop: 0, 
                                    height: '100%', 
                                    width: '100%',
                                    fontSize: calculateResponsiveSizeWithMinMax('2', '18', '8'),
                                    paddingLeft: '15px',
                                    paddingRight: '10px'
                                }
                            }}
                            autoComplete={"email"}
                            id="forgot-password-email-input"
                            placeholder={getLocalizedString('forgotpassword.email', 'Email')}
                            value={email}
                            required={true}
                            onChange={(event) => {
                                onEmailChange(event.target.value)
                            }}
                            className={'login-input-text'}
                        />
                    </div>

                    <div className={'button-div'}>
                        <ForgotButtonCancel
                            id="forgot-password-cancel-button"
                            type="button"
                            onClick={() => {
                                dispatch(appContextActions.resetForgotPassword());
                                cancelPressed()
                            }}
                            variant='contained'
                            className={'buttons button-cancel'}
                        >
                            {getLocalizedString('forgotpassword.cancel', 'Cancel')}
                        </ForgotButtonCancel>
                        <ForgotButtonSubmit
                            id="forgot-password-submit-button"
                            type="submit"
                            className={context.forgotPasswordProcessing ? 'buttons loading-button' : 'buttons button-submit'}
                            disabled={context.forgotPasswordProcessing || email?.length === 0}
                            variant='contained'
                        >
                            {getLocalizedString('forgotpassword.submit', 'Send Email')}
                            {context.forgotPasswordProcessing &&
                            <CircularProgress size={20}/>}
                        </ForgotButtonSubmit>
                    </div>
                </div>
            </div>
        </form>
    )
}

const ForgotButtonCancel = styled(Button)({
    maxHeight: '60px',
    borderRadius: '30px',
    borderWidth: '0',
    maxWidth: '100% !important',
    font: 'normal normal 600 0 "Proxima Nova"',
    height: '100%',
    fontSize: calculateResponsiveSizeWithMinMax('2', '18', '8'),
    color: '#FFFFFF',
    background: '#444444',
    letterSpacing: '0',
    width: '60%',
    padding: '2% 0 2% 0 !important',
    marginRight: '5%',
    textTransform: 'none',
    minWidth: '0px !important',
    '&:hover': {
      backgroundColor: '#444444',
      opacity: '.5'
    }
});

const ForgotButtonSubmit = styled(Button)({
    maxHeight: '60px',
    borderRadius: '30px',
    borderWidth: '0',
    maxWidth: '100% !important',
    font: 'normal normal 550 0 "Proxima Nova"',
    fontSize: calculateResponsiveSizeWithMinMax('1.5', '18', '6'),
    height: '100%',
    color: '#000000',
    background: '#FBBC41',
    letterSpacing: '0',
    width: '100%',
    padding: '2% 0 2% 0 !important',
    textTransform: 'none',
    minWidth: '0px !important',
    '&:hover': {
      backgroundColor: '#FBBC41',
      opacity: '.5'
    }
});
