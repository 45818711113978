import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {DeviceTypeDto} from "../../dtos/DeviceType";
import {get} from "../app-context/thunks/API_Thunks";
import {RootState} from "../../store/types";

export const deviceTypeSliceName = 'deviceTypeSlice';

export interface DeviceTypeState {
    deviceTypes: DeviceTypeDto[],
    brandDeviceTypes: DeviceTypeDto[]
}

const initialState: DeviceTypeState = {
    deviceTypes: [],
    brandDeviceTypes: []
}

export const getDeviceTypes = createAsyncThunk(
    `${deviceTypeSliceName}/findall`,
    async ({locationId, brandId} : {locationId: number, brandId?: number}, {dispatch}) => {
        try {
            const findAll = await dispatch(get({urlSuffix: '/clientportal/devicetype/findall', params: {locationId: locationId, brandId: brandId}}));

            return findAll.payload;
        }
        catch (err) {
            throw err;
        }
    }
);

export const deviceTypeSlice = createSlice({
    name: deviceTypeSliceName,
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDeviceTypes.pending, (state) => {
                // console.log('getDeviceTypes.pending');
            })
            .addCase(getDeviceTypes.rejected, (state, action) => {
                // console.log('getDeviceTypes.rejected');
            })
            .addCase(getDeviceTypes.fulfilled, (state, action) => {
                // console.log('getDeviceTypes.fulfilled');
                if(action.payload?.hasOwnProperty('errorMessage')) {

                }
                else {
                    if(action.payload?.hasOwnProperty('deviceTypes')) {
                        state.deviceTypes = action.payload.deviceTypes?.sort((dt1 : DeviceTypeDto, dt2 : DeviceTypeDto) => dt1.deviceTypeName.localeCompare(dt2.deviceTypeName));
                        state.brandDeviceTypes = action.payload.brandDeviceTypes?.sort((dt1 : DeviceTypeDto, dt2 : DeviceTypeDto) => dt1.deviceTypeName.localeCompare(dt2.deviceTypeName));
                    }
                }
            })
    }
});

export const deviceTypeSelector = (state: RootState) => state[deviceTypeSliceName];
export const deviceTypeActions = deviceTypeSlice.actions;
export default deviceTypeSlice.reducer;

