import {compareTo} from "../util/NumberUtils";

export interface DevicePickupPermissionDeviceTypeDto {
    devicePickupPermissionDeviceTypeId: number | undefined,
    deviceTypeId: number | undefined,
    quantity: number
}

export interface DevicePickupPermissionDto {
    devicePickupPermissionId: number | undefined,
    permissionName: string,
    default: boolean,
    allowMultipleCheckouts: boolean,
    custom: boolean,
    activeEmployeeCount?: number,
    deviceTypes: DevicePickupPermissionDeviceTypeDto[]
}


export const isPermissionsEqual = (p1: DevicePickupPermissionDto | undefined, p2: DevicePickupPermissionDto | undefined) => {
    if(p1 === undefined || p2 === undefined) {
        return p1 === p2;
    }

    const types1 = p1?.deviceTypes ?? [];
    const types2 = p2?.deviceTypes ?? [];

    let dtP1 = types1.slice().sort((dt1, dt2) => compareTo(dt1?.deviceTypeId ?? 0, dt2?.deviceTypeId ?? 0));
    let dtP2 = types2.slice().sort((dt1, dt2) => compareTo(dt1?.deviceTypeId ?? 0, dt2?.deviceTypeId ?? 0));

    let deviceTypesMatch = true;
    if(dtP1.length !== dtP2.length) {
        deviceTypesMatch = false;
    } else {
        for(let i = 0; i < dtP1.length; i ++) {
            let deviceTypesEqual = (dtP1[i].deviceTypeId === dtP2[i].deviceTypeId) && (dtP1[i].quantity === dtP2[i].quantity);

            if(!deviceTypesEqual) {
               deviceTypesMatch = false;
               break;
            }
        }
    }

    return p1?.allowMultipleCheckouts === p2?.allowMultipleCheckouts && deviceTypesMatch;
}