

export interface PortalUploadStatusDto {
    portalUploadStatusId: number,
    status: string,
    statusMessage: string,
    createDateTime: string,
    fileName: string,
    uploadType: string,
    totalRows: number,
    processedRows: number,
    uploadedRows: number,
    uploadedList: string,
    updatedRows: number,
    updatedList: string,
    badFormatRows: number,
    badFormatList: string,
    duplicateRows: number,
    duplicateList: string,
    duplicateEmailRows: number,
    duplicateEmailList: string,
    duplicateCredentialRows: number,
    duplicateCredentialList: string,
    transferredRows: number
}

export enum PORTAL_UPLOAD_STATUS_STATUSES {
    CREATED = 'CREATED',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR'
}
