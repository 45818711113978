import {LocationDto} from "./Location";
import {getLocalizedString} from "../util/Localization";

export const LABEL_TYPE_DEVICE = 'DEVICE';
export const LABEL_TYPE_EMPLOYEE = 'EMPLOYEE';

export type LabelType = typeof LABEL_TYPE_DEVICE | typeof LABEL_TYPE_EMPLOYEE;
export const LABEL_TYPES = [LABEL_TYPE_DEVICE, LABEL_TYPE_EMPLOYEE];

export const LABEL_URGENCY_HIGH = 'HIGH';
export const LABEL_URGENCY_MODERATE = 'MODERATE';
export const LABEL_URGENCY_LOW = 'LOW';

export type LabelUrgency = typeof LABEL_URGENCY_LOW | typeof LABEL_URGENCY_MODERATE | typeof LABEL_URGENCY_HIGH;
export const LABEL_URGENCYS = [LABEL_URGENCY_HIGH, LABEL_URGENCY_MODERATE, LABEL_URGENCY_LOW];

export const REQUEST_STATUS_ERROR = 'ERROR';
export const REQUEST_ERROR_HELP = ', An Unexpected Error occurred while processing this request. Please try again.';

export type LabelRequestDto = {
    labelRequestId: number | undefined,
    locationId: number | undefined,
    location: LocationDto | undefined,
    labelType: LabelType | undefined,
    requestNotes: string | undefined,
    contactInfo: string | undefined,
    requestStatus?: string,
    urgency?: LabelUrgency | undefined,
    createDateTime?: string,
    createUser?: string,
}

export function getUrgencyString(urgency: string | LabelUrgency) {
    switch (urgency) {
        case LABEL_URGENCY_LOW:
            return getLocalizedString('labelRequest.urgency.low', '50 Remaining');
        case LABEL_URGENCY_MODERATE:
            return getLocalizedString('labelRequest.urgency.moderate', '25 Remaining');
        case LABEL_URGENCY_HIGH:
            return getLocalizedString('labelRequest.urgency.high', 'None Remaining');
        default:
            return '';
    }
}

export function getStatusLabel(status: string | undefined) {
    switch (status) {
        case 'SUBMITTED':
            return getLocalizedString('labelRequest.status.submitted', 'SUBMITTED');
        case 'ERROR':
            return getLocalizedString('labelRequest.status.error', 'ERROR');
        default:
            return status;
    }
}