import * as React from 'react';
import {
    calculateResponsiveSizeWithMinMax
} from "../../util/TextUtils";
import {useNavigate, useParams} from "react-router-dom";
import {
    LoginButton
} from "../ui-components/StyledComponents";
import ARCLOGO_BLACK from "../../assets/img/ARC_Logo_Black.png";
import {getLocalizedString} from "../../util/Localization";
import {HELP_EMAIL, HELP_PHONE} from "../../constants/StringConstants";

export const UpdatePassword = () => {
    const navigate = useNavigate();
    const {token} = useParams();
    
    return (
            <div className={'container'}>
                    <div className={'login-page-body'}>
                        <div className={'login-header'}>
                            <div className={'login-logo-div'}>
                                <img className={'login-logo'} src={ARCLOGO_BLACK}
                                     alt="ARC Logo"/>
                                <div className={'logo-typography'}>{getLocalizedString('title', 'Client Portal')}</div>
                            </div>
                        </div>
                        <div className={'login-form'}>
                            <div className={'login-input-root'} style={{padding: '10% 10% 10% 10%'}}>
                                <div className={'reset-title'}>{getLocalizedString('updatepassword.title', 'Before logging in, please reset your password')}</div>
                                <br/>
                                <div className={'large-help-text'}>
                                    {getLocalizedString('updatepassword.tip1', 'Don’t worry, your account isn’t at risk!')} <br/>
                                    {getLocalizedString('updatepassword.tip2', 'Passwords need to change periodically')}<br/>
                                    {getLocalizedString('updatepassword.tip3', 'to keep your account secure.')}
                                </div>
                                
                                <div className={'login-button-div'} style={{flexGrow: 1}}>
                                    <LoginButton
                                        id="update-password-submit-button"
                                        type="submit"
                                        variant='contained'
                                        style={{fontSize: calculateResponsiveSizeWithMinMax('2', '24', '8')}}
                                        onClick={() => {
                                            if (token) {
                                                navigate(`/resetpassword/${token}`)
                                            }
                                        }}
                                    >
                                        {getLocalizedString('updatepassword.submit', 'Reset my Password')}
                                    </LoginButton>
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                <div className={'tool-tip'}>
                    {getLocalizedString('support', 'ARC Support') + ' | '+ getLocalizedString('support.email', HELP_EMAIL) + ' | ' + getLocalizedString("support.phone", HELP_PHONE)}
                </div>
                <div className={'login-terms-privacy-div'}>
                    <div className={'login-privacy-text'} id={'privacy-page-link'} onClick={() => navigate('/privacy/clientportal')}>
                        {getLocalizedString('privacy.title', 'Privacy Policy')}
                    </div>
                    <div style={{marginLeft: '5px', marginRight: '5px', fontSize: calculateResponsiveSizeWithMinMax('1.4', '18', '6')}}>
                        {' | '}
                    </div>
                    <div className={'login-terms-text'} id={'terms-conditions-page-link'} onClick={() => navigate('/terms/clientportal')}>
                        {getLocalizedString('terms.title', 'Terms & Conditions')}
                    </div>
                </div>
         </div>
    )
}
