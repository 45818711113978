import * as React from 'react';
import './ARCTable.scss';

type ARCTableProps<T> = {
    columns: Array<{
        header: string;
        key: string;
        render?: (rowData: T) => React.ReactNode;
    }>;
    data: Array<T>;
    rowKey: (row: T) => string | number;
    className?: string;
}

export const ARCTable : React.ComponentType<ARCTableProps<any>> = (props) => {

    return (
        <table className={`o-table ${props.className || ''}`}>
            <thead className={'u-hide-before--m'}>
            <tr>
                {props.columns.map((col, index) => (
                    <th className={'o-table__cell o-table__cell--header'} key={index}>{col.header}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {props.data.map((row) => (
                <tr key={props.rowKey(row)}>
                    {props.columns.map((col, index) => (
                        <td className={'o-table__cell'} key={index} data-label={col.header}>
                            {col.render ? col.render(row) : (row as any)[col.key]}
                        </td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    )
}