import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import API, {locale, paramsSerializer} from "../../../dtos/API_DOMAINS";

type postInputType = {
    urlSuffix: string,
    params: any,
    body: any,
    useToken?: boolean,
    signal?: AbortSignal
}

type getInputType = {
    urlSuffix: string,
    params: any
}
const { API_DOMAIN } = API;

export const post = createAsyncThunk(
    `CLIENTPORTAL-POST`,
    async ({
               urlSuffix,
               params,
               body,
               useToken = true
    }: postInputType, {getState, dispatch, extra}) => {
        const token = localStorage.getItem('token');
        const defaultLocale = localStorage.getItem("defaultLocale");

        const auth = useToken ?  {headers: {"Authorization": `Bearer ${token}`, "User-Pref-Locale" : defaultLocale || locale}} : {};
        try {
            const response = await axios.post(`${API_DOMAIN}${urlSuffix}?`,
                body,
                {
                    params: {
                        ...params
                    },
                    ...auth,
                    paramsSerializer: paramsSerializer
                }
            );

            return response.data;
        } catch (e) {
            throw e;
        }
    }
);

export const postWithCancel = createAsyncThunk(
    `CLIENTPORTAL-POST`,
    async ({
               urlSuffix,
               params,
               body,
               useToken = true,
               signal
    }: postInputType, {getState, dispatch, extra}) => {
        const token = localStorage.getItem('token');
        const defaultLocale = localStorage.getItem("defaultLocale");

        const auth = useToken ?  {headers: {"Authorization": `Bearer ${token}`, "User-Pref-Locale" : defaultLocale || locale}} : {};
        try {
            const response = await axios.post(`${API_DOMAIN}${urlSuffix}?`,
                body,
                {
                    params: {
                        ...params
                    },
                    ...auth,
                    paramsSerializer: paramsSerializer,
                    signal: signal
                }
            );

            return response.data;
        } catch (e) {
            throw e;
        }
    }
);

export const emergencyPortalPost = createAsyncThunk(
    `EMERGENCYPORTAL-POST`,
    async ({
               urlSuffix,
               params,
               body,
               useToken = true
           }: postInputType, {getState, dispatch, extra}) => {
        const token = localStorage.getItem('emergencyToken');
        const defaultLocale = localStorage.getItem("defaultLocale");

        const auth = useToken ?  {headers: {"Emergency-Authorization": `Bearer ${token}`, "User-Pref-Locale" : defaultLocale || locale}} : {};
        try {
            const response = await axios.post(`${API_DOMAIN}${urlSuffix}?`,
                body,
                {
                    params: {
                        ...params
                    },
                    ...auth,
                    paramsSerializer: paramsSerializer,
                }
            );

            return response.data;
        } catch (e) {
            throw e;
        }
    }
);

export const emergencyPortalGet = createAsyncThunk(
    `EMERGENCYPORTAL-GET`,
    async ({
               urlSuffix,
               params,
           }: getInputType, {getState, dispatch, extra}) => {
        const token = localStorage.getItem('emergencyToken');
        const defaultLocale = localStorage.getItem("defaultLocale");

        try {
            const response = await axios.get(`${API_DOMAIN}${urlSuffix}?`, {
                params: {
                    ...params
                },
                headers: {"Emergency-Authorization": `Bearer ${token}`, "User-Pref-Locale" : defaultLocale || locale},
                paramsSerializer: paramsSerializer,
            });

            return response.data;
        } catch (e) {
            throw e;
        }
    }
);

type postFormDataInputType = {
    urlSuffix: string,
    params: any,
    body: any,
    data: FormData
}
export const postFormData = createAsyncThunk(
    `CLIENTPORTAL-POSTFORMDATA`,
    async ({
               urlSuffix,
               params,
               body,
               data
           }: postFormDataInputType, {getState, dispatch, extra}) => {
        const token = localStorage.getItem('token');
        const defaultLocale = localStorage.getItem("defaultLocale");

        try {
            const response = await axios.post(`${API_DOMAIN}${urlSuffix}?`,
                body,
                {
                    params: {
                        ...params
                    },
                    headers: {"Authorization": `Bearer ${token}`, "User-Pref-Locale" : defaultLocale || locale
                    },
                    data: data,
                    paramsSerializer: paramsSerializer,
                }
            );

            return response.data;
        } catch (e) {
            throw e;
        }
    }
);

export const get = createAsyncThunk(
    `CLIENTPORTAL-GET`,
    async ({
               urlSuffix,
               params,
           }: getInputType, {getState, dispatch, extra}) => {
        const token = localStorage.getItem('token');
        const defaultLocale = localStorage.getItem("defaultLocale");

        try {
            const response = await axios.get(`${API_DOMAIN}${urlSuffix}?`, {
                params: {
                    ...params
                },
                headers: {"Authorization": `Bearer ${token}`, "User-Pref-Locale" : defaultLocale || locale},
                paramsSerializer: paramsSerializer,
            });

            return response.data;
        } catch (e) {
            throw e;
        }
    }
);

export const getFile = createAsyncThunk(
    `CLIENTPORTAL-GET`,
    async ({
               urlSuffix,
               params,
           }: getInputType, {getState, dispatch, extra}) => {
        const token = localStorage.getItem('token');
        const defaultLocale = localStorage.getItem("defaultLocale");

        try {
            const response = await axios.get(`${API_DOMAIN}${urlSuffix}?`, {
                params: {
                    ...params
                },
                headers: {"Authorization": `Bearer ${token}`, "User-Pref-Locale" : defaultLocale || locale},
                paramsSerializer: paramsSerializer,
            })
            return response.data;
        } catch (e) {
            throw e;
        }
    }
);
